import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Captcha from '../../Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import Texteditor from "./Texteditor"

export const Edit = ({ rowID, getData, closeEdit, getPesan, setMsg }) => {

    const [token, setToken] = useState("")
    const [expired, setExpired] = useState("")
    const [id, setID] = useState("")
    const [potensialCategory, setPotensialCategory] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [address, setAddress] = useState('')
    const [kecamatan, setKecamatan] = useState('')
    const [kelurahan, setKelurahan] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [npwpd, setNPWPD] = useState('')
    const [file, setFile] = useState("")
    const [preview, setPreview] = useState("")
    const [captcha, setCaptcha] = useState("")
    const [recaptcha, setReCaptcha] = useState("")
    const [editorLoaded, setEditorLoaded] = useState(false)
    const [kategori, setKategori] = useState([])
    const [optKec, setOptKec] = useState([])
    const [optKel, setOptKel] = useState([])
    const navigate = useNavigate()
    const alert = {
        position: "relative",
        top: "7px",
        backgroundColor: "#e5df7c",
        color: "#333",
    }

    useEffect(() => {
        updateToken()
        getKegiatan()
        getKategori()
        getKecamatan()
        setEditorLoaded(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateToken = async () => {
        try {
            const response = await axios.get('/api/pelaku-usaha/token')
            setToken(response.data.authToken)
            const decode = jwt_decode(response.data.authToken)
            setExpired(decode.exp)
            setID(decode.id)
        } catch (err) {
            if (err.response) {
                navigate('/')
            }
        }
    }

    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expired * 1000 < currentDate.getTime()) {
            const response = await axios.get('/api/pelaku-usaha/token')
            config.headers.Authorization = `Bearer ${response.data.authToken}`
            setToken(response.data.authToken)
            const decode = jwt_decode(response.data.authToken)
            setID(decode.id)
            setExpired(decode.exp)
        }
        return config
    }, (err) => {
        return Promise.reject(err)
    })

    const getKegiatan = async () => {
        const response = await axiosJWT.get(`/api/kegiatan-usaha/${rowID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        )
        setPotensialCategory(response.data.potensialCategoryId)
        setName(response.data.name)
        setDescription(response.data.description)
        setAddress(response.data.address)
        setKecamatan(response.data.kecamatan)
        setKelurahan(response.data.kelurahan)
        setLatitude(response.data.latitude)
        setLongitude(response.data.longitude)
        setNPWPD(response.data.npwpd)
        setPreview(response.data.imagePath)
        getKelurahan(response.data.kecamatan)
    }

    const getKategori = async () => {
        try {
            const response = await axios.post(`/api/kategori-usaha/publish`, {})
            setKategori(response.data)
        } catch {
            setKategori([])
        }
    }

    const getKecamatan = async () => {
        try {
            const response = await axios.post(`/api/wilayah/kecamatan`, {
                no_prop: '32',
                no_kab: '75'
            })
            setOptKec(response.data)
        } catch {
            setOptKec([])
        }
    }

    const getKelurahan = async (key) => {
        try {
            const kecamatan = await axios.post(`/api/wilayah/kecamatan`, {
                no_prop: '32',
                no_kab: '75',
                nama_kec: key
            })
            const response = await axios.post(`/api/wilayah/kelurahan`, {
                no_prop: '32',
                no_kab: '75',
                no_kec: kecamatan.data[0].no_kec
            })
            setOptKel(response.data)
        } catch {
            setOptKel([])
        }
    }

    const noKecamatan = async (key) => {
        try {
            const response = await axios.post(`/api/wilayah/kecamatan`, {
                no_prop: '32',
                no_kab: '75',
                nama_kec: key
            })
            setOptKel([])
            optKelurahan(response.data[0].no_kec)
            setKecamatan(key)
        } catch {
            setKecamatan(key)
        }
    }

    const optKelurahan = async (key) => {
        try {
            const response = await axios.post(`/api/wilayah/kelurahan`, {
                no_prop: '32',
                no_kab: '75',
                no_kec: key
            })
            setOptKel(response.data)
            setKelurahan('')
        } catch {
            setOptKel([])
            setKelurahan('')
        }
    }

    const loadFile = (e) => {
        const file = e.target.files[0]
        setFile(file)
        setPreview(URL.createObjectURL(file))
    }

    const proses = async (e) => {
        e.preventDefault()
        if (validateCaptcha(captcha) === false) {
            setReCaptcha("Captcha tidak sesuai!")
            setTimeout(() => {
                setReCaptcha("")
            }, 2000)
            setCaptcha("")
        } else {
            try {
                const formData = new FormData()
                formData.append("name", name)
                formData.append("description", description)
                formData.append("address", address)
                formData.append("kecamatan", kecamatan)
                formData.append("kelurahan", kelurahan)
                formData.append("latitude", latitude)
                formData.append("longitude", longitude)
                formData.append("npwpd", npwpd)
                formData.append("file", file)
                formData.append("user", id)
                const response = await axiosJWT.put(`/api/kegiatan-usaha/${rowID}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                getPesan()
                setMsg(response.data.msg)
                loadCaptchaEnginge(6)
                setCaptcha("")
                setTimeout(() => {
                    getPesan()
                    setMsg('')
                    getData('')
                    closeEdit()
                }, 2000)
            } catch (err) {
                getPesan()
                setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
                setTimeout(() => {
                    getPesan()
                    setMsg('')
                }, 2000)
            }
        }
    }

    return (<>
        <form onSubmit={proses} className="form-horizontal">
            <h5 className="right-line color-success">Kegiatan Usaha</h5>
            <div className="row">
                <div className="form-group col-md-6">
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label className="control-label">Nama</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-building"></i></span>
                                <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} value={name} />
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <label className="control-label">Kategori</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-building"></i></span>
                                <select className="form-control" value={potensialCategory} onChange={(e) => setPotensialCategory(e.target.value)} required>
                                    <option value="">==</option>
                                    {kategori.map((val, i) => (
                                        <option key={i} value={val._id}>{i + 1 + '. ' + val.categoryName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <label className="control-label">NPWPD</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-building"></i></span>
                                <input type="text" className="form-control" onChange={(e) => setNPWPD(e.target.value)} value={npwpd} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label">File</label>
                    {preview ? (<iframe width="100%" height="200" src={preview} title="Preview File" alt="Preview File" />) : (<img width="100%" height="200" src="/assets/img/no-image.png" title="Preview File" alt="Preview File" />)}
                    <input className="form-control" type="file" onChange={loadFile} />
                    <span className="badge badge-danger">File Ekstensi .JPG | .JPEG | .PNG</span>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label" htmlFor="description">Deskripsi</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-building"></i></span>
                        <Texteditor onChange={(description) => { setDescription(description) }} editorLoaded={editorLoaded} value={description || ''} required />
                    </div>
                </div>
            </div>
            <h5 className="right-line color-success">Alamat Kegiatan Usaha</h5>
            <div className="row">
                <div className="form-group col-md-12">
                    <label className="control-label" htmlFor="tmpt_lahir">Alamat Lengkap (Tanpa Kecamatan dan Kelurahan)</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-map"></i></span>
                        <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label">Kecamatan</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-map"></i></span>
                        <select className="form-control" value={kecamatan} onChange={(e) => noKecamatan(e.target.value)} required>
                            <option value="">==</option>
                            {optKec.map((val, i) => (
                                <option key={i} value={val.nama_kec}>{i + 1 + '. ' + val.nama_kec}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label">Kelurahan</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-map"></i></span>
                        <select className="form-control" value={kelurahan} onChange={(e) => setKelurahan(e.target.value)} required>
                            <option value="">==</option>
                            {optKel.map((val, i) => (
                                <option key={i} value={val.nama_kel}>{i + 1 + '. ' + val.nama_kel}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label">Latitude</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-map-pin"></i></span>
                        <input type="text" className="form-control" value={latitude} onChange={(e) => setLatitude(e.target.value)} required />
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label">Longitude</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-map-pin"></i></span>
                        <input type="text" className="form-control" value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                    </div>
                </div>
            </div>
            <h5 className="right-line color-success">Captcha</h5>
            <div className="row">
                <div className="text-center col-md-12">
                    <Captcha />
                    <p style={alert} className="text-center">
                        {recaptcha}
                    </p>
                </div>
                <div className="col-sm-12">
                    <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-raised btn-success br-2 no-m" type="Submit"><i className="fa fa-edit"></i> Update</button>
                <button onClick={closeEdit} className="btn btn btn-raised btn-danger br-2 no-m">
                    <i className="fa fa-sign-out"></i> Tutup
                </button>
            </div>
        </form>
    </>)
}