import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Captcha from '../../Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import { Kode } from '../../Utils'
import Texteditor from "./Texteditor"

export const Add = ({ getData, closeAdd, getPesan, setMsg }) => {

  const [token, setToken] = useState("")
  const [expired, setExpired] = useState("")
  const [id, setID] = useState("")
  const [kode] = useState(Kode)
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const [location, setLocation] = useState('')
  const [meeting_id, setMeetingID] = useState('')
  const [meeting_password, setMeetingPassword] = useState('')
  const [meeting_access, setMeetingAccess] = useState('')
  const [datetime, setDatetime] = useState('')
  const [destination_agency, setDestinationAgency] = useState('')
  const [destination_address, setDestinationAddress] = useState('')
  const [destination_contact, setDestinationContact] = useState('')
  const [destination_phone, setDestinationPhone] = useState('')
  const [destination_email, setDestinationEmail] = useState('')
  const [contactName, setContactName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [file, setFile] = useState("")
  const [preview, setPreview] = useState("")
  const [captcha, setCaptcha] = useState("")
  const [recaptcha, setReCaptcha] = useState("")
  const [editorLoaded, setEditorLoaded] = useState(false)
  const navigate = useNavigate()
  const alert = {
    position: "relative",
    top: "7px",
    backgroundColor: "#e5df7c",
    color: "#333",
  }

  useEffect(() => {
    updateToken()
    setEditorLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateToken = async () => {
    try {
      const response = await axios.get('/api/pelaku-usaha/token')
      setToken(response.data.authToken)
      const decode = jwt_decode(response.data.authToken)
      setExpired(decode.exp)
      setID(decode.id)
    } catch (err) {
      if (err.response) {
        navigate('/')
      }
    }
  }

  const axiosJWT = axios.create()

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expired * 1000 < currentDate.getTime()) {
      const response = await axios.get('/api/pelaku-usaha/token')
      config.headers.Authorization = `Bearer ${response.data.authToken}`
      setToken(response.data.authToken)
      const decode = jwt_decode(response.data.authToken)
      setID(decode.id)
      setExpired(decode.exp)
    }
    return config
  }, (err) => {
    return Promise.reject(err)
  })

  const loadFile = (e) => {
    const file = e.target.files[0]
    setFile(file)
    setPreview(URL.createObjectURL(file))
  }

  const proses = async (e) => {
    e.preventDefault()
    if (validateCaptcha(captcha) === false) {
      setReCaptcha("Captcha tidak sesuai!")
      setTimeout(() => {
        setReCaptcha("")
      }, 2000)
      setCaptcha("")
    } else {
      try {
        const formData = new FormData()
        formData.append("code", kode)
        formData.append("description", description)
        formData.append("type", type)
        formData.append("location", location)
        formData.append("meeting_id", meeting_id)
        formData.append("meeting_password", meeting_password)
        formData.append("meeting_access", meeting_access)
        formData.append("datetime", datetime)
        formData.append("destination_agency", destination_agency)
        formData.append("destination_address", destination_address)
        formData.append("destination_contact", destination_contact)
        formData.append("destination_phone", destination_phone)
        formData.append("destination_email", destination_email)
        formData.append("contactName", contactName)
        formData.append("phoneNumber", phoneNumber)
        formData.append("email", email)
        formData.append("status", '0')
        formData.append("file", file)
        formData.append("user", id)
        const response = await axiosJWT.post('/api/meeting', formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        getPesan()
        setMsg(response.data.msg)
        loadCaptchaEnginge(6)
        setCaptcha("")
        setTimeout(() => {
          getPesan()
          setMsg('')
          getData('')
          closeAdd()
        }, 2000)
      } catch (err) {
        getPesan()
        setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
        setTimeout(() => {
          getPesan()
          setMsg('')
        }, 2000)
      }
    }
  }

  return (<>
    <form onSubmit={proses} className="form-horizontal">
      <h5 className="right-line color-success">Informasi Pemohon</h5>
      <div className="row">
        <div className="form-group col-md-6">
          <label className="control-label">Nama Kontak</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-user"></i></span>
            <input type="text" className="form-control" onChange={(e) => setContactName(e.target.value)} value={contactName} />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">No. Kontak</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-phone"></i></span>
            <input type="number" className="form-control" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">Email</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-mail"></i></span>
            <input type="text" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} />
          </div>
        </div>
      </div>
      <h5 className="right-line color-success">Informasi Janjian</h5>
      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label">Maksud dan Tujuan</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-info"></i></span>
            <Texteditor onChange={(description) => { setDescription(description) }} editorLoaded={editorLoaded} required />
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="row">
            <div className="form-group col-md-12">
              <label className="control-label">Jenis</label>
              <div className="input-group">
                <span className="input-group-addon"><i className="fa fa-filter"></i></span>
                <select className="form-control" value={type} onChange={(e) => setType(e.target.value)} required>
                  <option value="">==</option>
                  <option value="1">1. Offline</option>
                  <option value="2">2. Online</option>
                </select>
              </div>
            </div>
            <div className="form-group col-md-12">
              {type === '1' ? (
                <label className="control-label">Tempat</label>
              ) : (
                <label className="control-label">Meeting Media</label>
              )}
              <div className="input-group">
                {type === '1' ? (<>
                  <span className="input-group-addon"><i className="fa fa-card-address"></i></span>
                  <input type="text" className="form-control" onChange={(e) => setLocation(e.target.value)} value={location} required />
                </>) : (<>
                  <span className="input-group-addon"><i className="fa fa-card-users"></i></span>
                  <select className="form-control" value={location} onChange={(e) => setLocation(e.target.value)} required>
                    <option value="">==</option>
                    <option value="Zoom">1. Zoom</option>
                    <option value="Google Meet">2. Google Meet</option>
                  </select>
                </>)}
              </div>
            </div>
            <div className="form-group col-md-12">
              <label className="control-label">Tanggal dan Waktu</label>
              <div className="input-group">
                <span className="input-group-addon"><i className="fa fa-calendar-o"></i></span>
                <input type="datetime-local" className="form-control" onChange={(e) => setDatetime(e.target.value)} value={datetime} required />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">File</label>
          {preview ? (<iframe width="100%" height="200" src={preview} title="Preview File" alt="Preview File" />) : (<img width="100%" height="200" src="/assets/img/no-image.png" title="Preview File" alt="Preview File" />)}
          <input className="form-control" type="file" onChange={loadFile} />
          <span className="badge badge-danger">File Ekstensi .JPG | .JPEG | .PNG | .PDF</span>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">Meeting ID</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-video"></i></span>
            {type === '1' ? (
              <input type="text" className="form-control" onChange={(e) => setMeetingID(e.target.value)} value={meeting_id} readOnly />
            ) : (
              <input type="text" className="form-control" onChange={(e) => setMeetingID(e.target.value)} value={meeting_id} required />
            )}
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">Meeting Password</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-key"></i></span>
            {type === '1' ? (
              <input type="text" className="form-control" onChange={(e) => setMeetingPassword(e.target.value)} value={meeting_password} readOnly />
            ) : (
              <input type="datetime" className="form-control" onChange={(e) => setMeetingPassword(e.target.value)} value={meeting_password} required />
            )}
          </div>
        </div>
        <div className="form-group col-md-12">
          <label className="control-label">Meeting Url</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-link"></i></span>
            {type === '1' ? (
              <input type="text" className="form-control" onChange={(e) => setMeetingAccess(e.target.value)} value={meeting_access} readOnly />
            ) : (
              <input type="text" className="form-control" onChange={(e) => setMeetingAccess(e.target.value)} value={meeting_access} required />
            )}
          </div>
        </div>
      </div>
      <h5 className="right-line color-success">Informasi Tujuan</h5>
      <div className="row">
        <div className="form-group col-md-12">
          <label className="control-label">Nama Instansi/Lembaga</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-building"></i></span>
            <input type="text" className="form-control" onChange={(e) => setDestinationAgency(e.target.value)} value={destination_agency} required />
          </div>
        </div>
        <div className="form-group col-md-12">
          <label className="control-label">Alamat</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-map"></i></span>
            <textarea type="text" className="form-control" onChange={(e) => setDestinationAddress(e.target.value)} value={destination_address} required />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">Nama Kontak</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-user"></i></span>
            <input type="text" className="form-control" onChange={(e) => setDestinationContact(e.target.value)} value={destination_contact} />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">No. Kontak</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-phone"></i></span>
            <input type="number" className="form-control" onChange={(e) => setDestinationPhone(e.target.value)} value={destination_phone} />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">Email</label>
          <div className="input-group">
            <span className="input-group-addon"><i className="fa fa-mail"></i></span>
            <input type="text" className="form-control" onChange={(e) => setDestinationEmail(e.target.value)} value={destination_email} />
          </div>
        </div>
      </div>
      <h5 className="right-line color-success">Captcha</h5>
      <div className="row">
        <div className="text-center col-md-12">
          <Captcha />
          <p style={alert} className="text-center">
            {recaptcha}
          </p>
        </div>
        <div className="col-sm-12">
          <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-raised btn-primary br-2 no-m" type="Submit"><i className="fa fa-save"></i> Simpan</button>
        <button onClick={closeAdd} className="btn btn btn-raised btn-danger br-2 no-m">
          <i className="fa fa-sign-out"></i> Tutup
        </button>
      </div>
    </form>
  </>)
}