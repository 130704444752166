import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import axios from 'axios'
import parse from 'html-react-parser'
import Pagination from '../pages/paginations'

export const Promosi = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(4)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post("/api/promotion/publish", {
            jml: 120
        })
        setData(response.data)
        setCurrentPage(1)
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage])

    return (<>
        {
            tableData.length > 0 ? (<>
                <section className="wrap bg-success no-mt pb-1">
                    <div className="container">
                        <div className="row no-shadow">
                            <div className="col-lg-12">
                                <h3 className="no-m">Telusuri Informasi di Kota Bekasi</h3>
                                <h1 className="color-primary font-bold no-mt">Promosi</h1>
                                <div className="row fail">
                                    {tableData.map((val, index) =>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-6" key={index}>
                                            <div className="card animated zoomInUp animation-delay-3 br-2">
                                                <div className="card-body text-dark">
                                                    <h4 className="font-bold lh-125 no-m">{val.title.toUpperCase()}</h4>
                                                    {parse(val.description)}
                                                </div>
                                                <div className="card-footer text-right border-bottom-20">
                                                    <Link className="text-primary" to={`/promosi/${val.code}/${val.title.replaceAll(' ', '-').replaceAll('/', '_').toLowerCase()}`}>Selengkapnya <i className="ml-1 no-mr zmdi zmdi-long-arrow-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="text-center p-4">
                                    <Pagination className="pagination-bar" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>) : null
        }
    </>)
}
