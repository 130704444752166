import React from 'react'
import { Link } from "react-router-dom"

const Nav = () => {

    return (<>
        <ul className="dropdown-menu bg-primary w-300 pb-15">
            <li className="dropdown-header">
                <h4 className='color-warning'>AKUN</h4>
            </li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/akun/manajemen'}>Akun Manajemen</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/akun/profil-usaha'}>Profil Usaha</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/akun/kegiatan-usaha'}>Kegiatan Usaha</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/akun/promosi'}>Promosi</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/akun/kebutuhan-pengadaan'}>Kebutuhan Pengadaan</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/akun/permohonan-pengadaan'}>Permohonan Pengadaan</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/akun/janjian'}>Janjian</Link></li>
        </ul>
    </>)
}

export default Nav;