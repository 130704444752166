import React, { useState, useEffect, useMemo } from "react"
import axios from 'axios'
import Pagination from '../pages/paginations'

export const Terhubung = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(4)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post("/api/tautan/publish", {
            kategori: ['INSTANSI', 'APLIKASI'],
            jml: 20
        })
        setData(response.data)
        setCurrentPage(1)
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage])

    return (<>
        <header className="ms-hero-page-override ms-bg-fixed no-mt pb-4">
            <div className="container">
                <div className="row no-shadow">
                    <div className="col-lg-12">
                        <h1 className="color-success font-bold no-mt">Tetap Terhubung</h1>
                        <div className="row fail">
                            {tableData.map((val, index) =>
                                <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-6">
                                    <div className="card card-default animated zoomInUp animation-delay-3">
                                        <div className="bg-default text-center">
                                            <a href={val.tautan} target="_blank" rel="noopener noreferrer">
                                                <img src={val.url} alt={val.judul} className="icon-tautan" />
                                            </a>
                                        </div>
                                        <div className="card-body p-1 mt-0 text-center">
                                            <div className="lh-125">
                                                <a href={val.tautan} target="_blank" rel="noopener noreferrer" className="color-primary">
                                                    <h4 className="text-dark font-bold lh-125 no-m">{val.judul}</h4>
                                                    <p className='text-dark lh-125'>{val.deskripsi}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="text-center p-4">
                            <Pagination className="pagination-bar" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>)
}
