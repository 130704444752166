import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Captcha from '../Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
// import {internalIpV6, internalIpV4} from 'internal-ip'

function View() {
    const [pesan, setPesan] = useState(false)
    const [typeText, setType] = useState(false)
    const [usernameDisplay, setusernameDisplay] = useState('form-control d-block')
    const [usernameProfil, setusernameProfil] = useState('form-control d-none')
    const [emailDisplay, setemailDisplay] = useState('form-control d-block')
    const [emailProfil, setemailProfil] = useState('form-control d-none')
    const [passwordDisplay, setpasswordDisplay] = useState('form-control d-block')
    const [passwordProfil, setpasswordProfil] = useState('form-control d-none')
    const [msg, setMsg] = useState("")

    const [token, setToken] = useState("")
    const [expired, setExpired] = useState("")
    const [id, setID] = useState("")
    const [akun, setAkun] = useState([])
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [no_hp, setNoHp] = useState("")
    const [captcha, setCaptcha] = useState("")
    const [recaptcha, setReCaptcha] = useState("")
    const [checkedUsername, setcheckedUsername] = useState(false)
    const [checkedEmail, setcheckedEmail] = useState(false)
    const [checkedPassword, setcheckedPassword] = useState(false)
    const navigate = useNavigate()
    const alert = {
        position: "relative",
        top: "7px",
        backgroundColor: "#e5df7c",
        color: "#333",
    }

    const typePassword = () => {
        setType(prevState => !prevState)
    }

    const editUsername = () => {
        if (usernameDisplay === "form-control d-block") {
            setusernameProfil('form-control d-block')
            setusernameDisplay('form-control d-none')
            setcheckedUsername(true)
        } else {
            setusernameProfil('form-control d-none')
            setusernameDisplay('form-control d-block')
            setcheckedUsername(false)
        }
    }

    const editEmail = () => {
        if (emailDisplay === "form-control d-block") {
            setemailProfil('form-control d-block')
            setemailDisplay('form-control d-none')
            setcheckedEmail(true)
        } else {
            setemailProfil('form-control d-none')
            setemailDisplay('form-control d-block')
            setcheckedEmail(false)
        }
    }

    const editPassword = () => {
        if (passwordDisplay === "form-control d-block") {
            setpasswordProfil('form-control d-block')
            setpasswordDisplay('form-control d-none')
            setcheckedPassword(true)
        } else {
            setpasswordProfil('form-control d-none')
            setpasswordDisplay('form-control d-block')
            setcheckedPassword(false)
        }
    }

    useEffect(() => {
        updateToken()
        getAkun()
        loadCaptchaEnginge(6)
        // console.log(getMAC('eth0'))
    }, [])

    const updateToken = async () => {
        try {
            const response = await axios.get('/api/pelaku-usaha/token')
            setToken(response.data.authToken)
            const decode = jwt_decode(response.data.authToken)
            setExpired(decode.exp)
        } catch (err) {
            if (err.response) {
                navigate('/login')
            }
        }
    }

    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expired * 1000 < currentDate.getTime()) {
            const response = await axios.get('/api/pelaku-usaha/token')
            config.headers.Authorization = `Bearer ${response.data.authToken}`
            setToken(response.data.authToken)
            const decode = jwt_decode(response.data.authToken)
            setID(decode.id)
            setExpired(decode.exp)
        }
        return config
    }, (err) => {
        return Promise.reject(err)
    })

    const getAkun = async () => {
        const params = await axiosJWT.get('/api/pelaku-usaha/token')
        const decode = jwt_decode(params.data.authToken)
        const id = decode.id
        const response = await axiosJWT.get(`/api/pelaku-usaha/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        setAkun(response.data)
        setNoHp(response.data.no_hp)
    }

    const editAkun = async (e) => {
        e.preventDefault()
        if (validateCaptcha(captcha) === false) {
            setReCaptcha("Captcha tidak sesuai!")
            setTimeout(() => {
                setReCaptcha("")
            }, 2000)
            setCaptcha("")
        } else {
            try {
                const update = await axiosJWT.put(`/api/pelaku-usaha/akun/${id}`, {
                    email,
                    username,
                    password,
                    no_hp,
                    u_user: id,
                    u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br')
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                loadCaptchaEnginge(6)
                setCaptcha("")
                setReCaptcha("")
                setusernameProfil('form-control d-none')
                setusernameDisplay('form-control d-block')
                setemailProfil('form-control d-none')
                setemailDisplay('form-control d-block')
                setpasswordProfil('form-control d-none')
                setpasswordDisplay('form-control d-block')
                setcheckedUsername(false)
                setcheckedEmail(false)
                setcheckedPassword(false)
                setUsername('')
                setEmail('')
                setUsername('')
                setPassword('')
                getPesan()
                setMsg(update.data.msg)
                setTimeout(() => {
                    getPesan()
                    setMsg('')
                    getAkun()
                }, 2000)
            } catch (err) {
                loadCaptchaEnginge(6)
                setCaptcha("")
                setReCaptcha("")
                getPesan()
                setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
                setTimeout(() => {
                    getPesan()
                    setMsg('')
                }, 2000)
            }
        }
    }

    const getPesan = () => {
        setPesan((prevState) => !prevState)
    }

    const display = {
        display: `${pesan ? "block" : "none"}`,
    }

    return (
        <>
            <div className="container">
                <div className="card animated fadeInUp animation-delay-7 br-2 mb-6">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <form onSubmit={editAkun} className="form-horizontal">
                                            <fieldset className="">
                                                <div className="form-group row is-empty mt-1">
                                                    <label className="col-lg-3 control-label">Nama Pengguna</label>
                                                    <div className="col-lg-8">
                                                        <div className="input-group">
                                                            <input className={usernameDisplay} defaultValue={akun.username} />
                                                            <input type="text" className={usernameProfil} placeholder="Masukkan Username" onChange={(e) => setUsername(e.target.value)} />
                                                            <input type="checkbox" checked={checkedUsername} onChange={editUsername} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row is-empty mt-1">
                                                    <label className="col-lg-3 control-label">Email</label>
                                                    <div className="col-lg-8">
                                                        <div className="input-group">
                                                            <input className={emailDisplay} defaultValue={akun.email} />
                                                            <input type="email" className={emailProfil} placeholder="Masukkan Email" onChange={(e) => setEmail(e.target.value)} />
                                                            <input type="checkbox" checked={checkedEmail} onChange={editEmail} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row is-empty mt-1">
                                                    <label className="col-lg-3 control-label">Password</label>
                                                    <div className="col-lg-8">
                                                        <div className="input-group">
                                                            <span className="input-group-addon">
                                                                <div className="checkbox">
                                                                    <span onClick={typePassword}> <i className="fa fa-eye"></i></span>
                                                                </div>
                                                            </span>
                                                            <input type="password" className={passwordDisplay} defaultValue={akun.password} />
                                                            <input type={typeText ? 'text' : 'password'} className={passwordProfil} placeholder="Masukkan Password" onChange={(e) => setPassword(e.target.value)} />
                                                            <input type="checkbox" checked={checkedPassword} onChange={editPassword} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row is-empty mt-1">
                                                    <label className="col-lg-3 control-label">No. HP</label>
                                                    <div className="col-lg-8">
                                                        <input type="tel" className="form-control" placeholder="Masukkan No. HP" defaultValue={no_hp || akun.no_hp} onChange={(e) => setNoHp(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group row is-empty mt-1">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-8">
                                                        <Captcha />
                                                        <p style={alert} className="text-center">
                                                            {recaptcha}
                                                        </p>
                                                    </div>
                                                    <label className="col-lg-3 control-label">Captcha</label>
                                                    <div className="col-lg-8">
                                                        <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
                                                    </div>
                                                </div>
                                                <div className="form-group row justify-content-end no-mt mb-2">
                                                    <div className="col-lg-9">
                                                        <button className="btn btn-raised btn-success br-2 no-m" type="Submit"><i className="fa fa-edit"></i> Update</button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={pesan ? "modal-open" : ""}>
                <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog center">
                        <div className="modal-content modal-sm">
                            <div className="modal-header header-modal-primary p-1">
                                <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                                    PESAN
                                </h4>
                            </div>
                            <div className="modal-body text-center">
                                <h4 className="font-bold">{msg.toUpperCase()}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {pesan ? <div className="modal-backdrop fade in"></div> : ""}
        </>
    )
}

export default View