import React, { useEffect } from 'react'
import { useParams, Link } from "react-router-dom"
import Artikel from './Artikel'
import Galeri from './Galeri'
import Pengumuman from './Pengumuman'
import Agenda from './Agenda'
import Siaran from './Siaran'
import Nav from './Nav'

const View = () => {
    const { kategori } = useParams()

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">INFO</li>
                        <li className="breadcrumb-item active" aria-current="page">{kategori.replaceAll('-', ' ').toUpperCase()}</li>
                    </ol>
                    <div className="btn-group w-300">
                        <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {kategori.replaceAll('-', ' ').toUpperCase()} <i className="zmdi zmdi-chevron-down right only"></i>
                        </button>
                        <Nav />
                    </div>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <h1 className='color-dark font-bold lh-125'>{kategori.replaceAll('-', ' ').toUpperCase()}</h1>
                        <h3 className="color-dark lh-125">Telusuri {kategori.replaceAll('-', ' ').toLowerCase()} terbaru dari Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi</h3>
                        <hr></hr>
                        {kategori === 'siaran-pers' ? <Siaran kategori={kategori} /> : null}
                        {kategori === 'agenda-kegiatan' ? <Agenda kategori={kategori} /> : null}
                        {kategori === 'artikel-publikasi' ? <Artikel kategori={kategori} /> : null}
                        {kategori === 'pengumuman' ? <Pengumuman kategori={kategori} /> : null}
                        {kategori === 'galeri' ? <Galeri kategori={kategori} /> : null}
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;