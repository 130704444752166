import React from 'react'

import Navbar from "./components/Navbar";
import Login from "./components/Login"
import Reset from "./components/Reset"
import Registrasi from "./components/registrasi/View"
import RegistrasiPerorangan from "./components/registrasi/Perorangan"
import RegistrasiBadan from "./components/registrasi/Badan"
import Aktivasi from "./components/Aktivasi"
import Akun from "./components/akun/Main"
import Beranda from "./components/beranda/Main"
import Investasi from "./components/investasi/Main"
import KegiatanUsaha from "./components/kegiatan-usaha/Main"
import KegiatanUsahaDetail from "./components/kegiatan-usaha/Detail"
import Promosi from "./components/promosi/Detail"
import Pengadaan from "./components/pengadaan/Detail"
import Laporan from "./components/laporan/Main"
import Dukungan from "./components/dukungan/Main"
import Produk from "./components/dukungan/peraturan/Produk"
import Pelayanan from "./components/dukungan/pelayanan/Title"
import Pengaduan from "./components/dukungan/pengaduan/Title"
import Info from "./components/info/Main"
import InfoDetail from "./components/info/Detail"
import Tentang from "./components/tentang/Main"
import Pejabat from "./components/tentang/Detail"
import Ketentuan from "./components/Ketentuan"
import Privasi from "./components/Privasi"
import Sitemap from "./components/Sitemap"
import Pencarian from "./components/pencarian/Main"
import Skm from "./components/skm/Main"
import Footbar from "./components/Footbar"
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <div className="ms-site-container">
        <Routes>
          <Route path="/" element={<><Navbar /><Beranda /><Footbar /><Footer /> </>}></Route>
          <Route path="/login" element={<><Login /> </>}></Route>
          <Route path="/reset" element={<><Reset /> </>}></Route>
          <Route path="register" element={<Registrasi />}></Route>
          <Route path="registrasi-perorangan" element={<RegistrasiPerorangan />}></Route>
          <Route path="registrasi-badan-usaha" element={<RegistrasiBadan />}></Route>
          <Route path="aktivasi-akun/:key/:code" element={<><Aktivasi /></>}></Route>
          <Route path="/akun/:kategori" element={<><Navbar /><Akun /><Footbar /><Footer /> </>}></Route>
          <Route path="/mengapa-kota-bekasi/:kategori" element={<><Navbar /><Investasi /><Footbar /><Footer /> </>}></Route>
          <Route path="/kegiatan-usaha/:kategori" element={<><Navbar /><KegiatanUsaha /><Footbar /><Footer /> </>}></Route>
          <Route path="/kegiatan-usaha/:kategori/:code" element={<><Navbar /><KegiatanUsahaDetail /><Footbar /><Footer /> </>}></Route>
          <Route path="/laporan/:kategori" element={<><Navbar /><Laporan /><Footbar /><Footer /> </>}></Route>
          <Route path="/promosi/:key/:judul" element={<><Navbar /><Promosi /><Footbar /><Footer /> </>}></Route>
          <Route path="/kebutuhan-pengadaan/:key/:judul" element={<><Navbar /><Pengadaan /><Footbar /><Footer /> </>}></Route>
          <Route path="/layanan-dan-pengaduan/:kategori/:subkategori" element={<><Navbar /><Dukungan /><Footbar /><Footer /> </>}></Route>
          <Route path="/layanan-dan-pengaduan/:kategori/:subkategori/produk-hukum/:jenis" element={<><Navbar /><Produk /><Footbar /><Footer /> </>}></Route>
          <Route path="/layanan-dan-pengaduan/:kategori/:subkategori/informasi-pelayanan/:title" element={<><Navbar /><Pelayanan /><Footbar /><Footer /> </>}></Route>
          <Route path="/layanan-dan-pengaduan/:kategori/:subkategori/informasi-pengaduan/:title" element={<><Navbar /><Pengaduan /><Footbar /><Footer /> </>}></Route>
          <Route path="/info/:kategori" element={<><Navbar /><Info /><Footbar /><Footer /> </>}></Route>
          <Route path="/info/:kategori/:title" element={<><Navbar /><InfoDetail /><Footbar /><Footer /> </>}></Route>
          <Route path="/tentang-dpmptsp/:kategori" element={<><Navbar /><Tentang /><Footbar /><Footer /> </>}></Route>
          <Route path="/tentang-dpmptsp/:kategori/:title" element={<><Navbar /><Pejabat /><Footbar /><Footer /> </>}></Route>
          <Route path="/privasi-pengguna" element={<><Navbar /><Privasi /><Footbar /><Footer /> </>}></Route>
          <Route path="/ketentuan-penggunaan" element={<><Navbar /><Ketentuan /><Footbar /><Footer /> </>}></Route>
          <Route path="/peta-situs" element={<><Navbar /><Sitemap /><Footbar /><Footer /> </>}></Route>
          <Route path="/pencarian/:keyword" element={<><Navbar /><Pencarian /><Footbar /><Footer /> </>}></Route>
          <Route path="/survey-kepuasan-masyarakat/:kategori" element={<><Navbar /><Skm /><Footbar /><Footer /> </>}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
