import React, { useState, useEffect, useMemo } from 'react'
import { useParams, Link } from "react-router-dom"
import Pagination from '../../pages/paginations'
import axios from "axios"
import Nav from './Nav'
import Table from './Table'

const View = () => {
    const { kategori, subkategori, jenis } = useParams()
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(10)

    useEffect(() => {
        getData(jenis)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (value) => {
        const category = value ? value.replaceAll('-', ' ').toUpperCase() : jenis.replaceAll('-', ' ').toUpperCase()
        const response = await axios.post("/api/regulasi/publish", {
            jenis: category,
            key: '',
            jml: 100
        })
        setData(response.data)
        setCurrentPage(1)
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage])

    return (
        <>
            <header className="ms-hero-page-override-home no-mt pb-4">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-slash">
                            <li className="breadcrumb-item">
                                <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">LAYANAN DAN PENGADUAN</li>
                            <li className="breadcrumb-item active" aria-current="page">{kategori.replaceAll('-', ' ').toUpperCase()}</li>
                            <li className="breadcrumb-item active" aria-current="page">{subkategori.replaceAll('-', ' ').toUpperCase()}</li>
                            <li className="breadcrumb-item active" aria-current="page">PRODUK HUKUM</li>
                            <li className="breadcrumb-item active" aria-current="page">{jenis.replaceAll('-', ' ').toUpperCase()}</li>
                        </ol>
                        <div className="btn-group w-300">
                            <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {jenis.replaceAll('-', ' ').toUpperCase()} <i className="zmdi zmdi-chevron-down right only"></i>
                            </button>
                            <Nav getJenis={getData} />
                        </div>
                    </nav>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <h1 className='color-dark font-bold lh-125 no-m'>PRODUK HUKUM ({jenis.replaceAll('-', ' ').toUpperCase()})</h1>
                            <h3 className="color-dark lh-125">Telusuri produk hukum pada Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi</h3>
                            <hr></hr>
                            <Table tableData={tableData}/>
                            <div className="text-center p-4">
                                <Pagination className="pagination-bar" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default View;