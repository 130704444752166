import React, { useState, useEffect } from 'react'
import axios from "axios"
import parse from 'html-react-parser'

const View = () => {
    const [deskripsi, setDeskripsi] = useState('')
    const [visi, setVisi] = useState('')

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.get("/api/profil/publish", {})
        setDeskripsi(response.data.tentang)
        setVisi(response.data.visi_misi)
    }

    return (<>
        <div className="col-md-2"></div>
        <div className="col-md-8">
            <h1 className='color-dark font-bold lh-125'>Tentang Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi</h1>
            {parse(deskripsi)}
            {parse(visi)}
        </div>
    </>)
}

export default View;