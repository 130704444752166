import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios'

const View = () => {
    const [optLaporan, setOptLaporan] = useState([])

    useEffect(() => {
        getLaporan()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLaporan = async (filter) => {
        try {
            const response = await axios.post("/api/laporan/kategori/load", {
                key: filter,
                jml: 1000
            })
            setOptLaporan(response.data)
        } catch (err) {
            setOptLaporan([])
        }
    }

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">PETA SITUS</li>
                    </ol>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <h1 className='color-success font-bold lh-125 no-m flex-card row mb-75'>Peta Situs</h1>
                    </div>
                </div>
                <div className="row mb-75">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                        <h3 className='text-dark font-bold'>Mengapa Kota Bekasi?</h3>
                        <hr></hr>
                        <h4 className='text-dark font-bold mb-2'>Prospek Investasi</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/mengapa-kota-bekasi/demografi"} className="text-dark">
                                    Demografi
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/mengapa-kota-bekasi/geografis"} className="text-dark">
                                    Geografis
                                </Link>
                            </div>
                        </div>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Potensi Investasi</h4>
                        <div className="row">
                            <div className="col-12">
                                <a href={'https://siibeken.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className="text-dark">
                                    Potensi Investasi Regional
                                </a>
                            </div>
                        </div>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Perizinan</h4>
                        <div className="row">
                            <div className="col-12">
                                <a href={'https://oss.go.id'} target="_blank" rel="noopener noreferrer" className="text-dark">
                                    OSS
                                </a>
                            </div>
                        </div>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Pengalaman Investor</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/survey-kepuasan-masyarakat/pengalaman-investor"} className="text-dark">
                                    Pengalaman Investor
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                        <h3 className='text-dark font-bold'>Layanan dan Pegaduan</h3>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Prosedur</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/layanan-dan-pengaduan/prosedur/memulai-bisnis"} className="text-dark">
                                    Memulai Bisnis
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/layanan-dan-pengaduan/prosedur/peraturan"} className="text-dark">
                                    Peraturan Perundang-Undangan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/layanan-dan-pengaduan/prosedur/pelayanan"} className="text-dark">
                                    Pelayanan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/layanan-dan-pengaduan/prosedur/pengaduan"} className="text-dark">
                                    Pengaduan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/layanan-dan-pengaduan/prosedur/panduan-aplikasi"} className="text-dark">
                                    Panduan Aplikasi
                                </Link>
                            </div>
                        </div>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Open Data</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/layanan-dan-pengaduan/open-data/layanan-terbuka"} className="text-dark">
                                    Open-Data
                                </Link>
                            </div>
                        </div>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Hubungi Kami</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/layanan-dan-pengaduan/hubungi-kami/kontak"} className="text-dark">
                                    Kontak dan Lokasi
                                </Link>
                            </div>
                        </div>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Booking Antrian</h4>
                        <div className="row">
                            <div className="col-12">
                                <a href={'https://mpp.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className="text-dark">
                                    Booking Antrian
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                        <h3 className='text-dark font-bold'>Info</h3>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Info</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/info/siaran-pers"} className="text-dark">
                                    Siaran Pers
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/info/galeri"} className="text-dark">
                                    Galeri
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/info/artikel-publikasi"} className="text-dark">
                                    Artikel dan Publikasi
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/info/agenda-kegiatan"} className="text-dark">
                                    Agenda Kegiatan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/info/pengumuman"} className="text-dark">
                                    Pengumuman
                                </Link>
                            </div>
                        </div>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Informasi Publik</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/"} className="text-dark">
                                    Informasi Publik
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                        <h3 className='text-dark font-bold'>Laporan</h3>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Laporan</h4>
                        <div className="row">
                            <div className="col-12">
                                {optLaporan.map((val, i) => (<div key={i}>
                                    <Link to={`/laporan/${val.namaKategori.toLowerCase().replaceAll(' ', '-')}`} className='text-dark'>{val.namaKategori.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</Link>
                                    <div className="mb-2"></div>
                                </div>))}
                                {/* <Link to={"/laporan/perizinan"} className="text-dark">
                                    Perizinan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/perencanaan"} className="text-dark">
                                    Perencanaan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/pengaduan"} className="text-dark">
                                    Pengaduan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/umum"} className="text-dark">
                                    Umum
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/keuangan"} className="text-dark">
                                    Keuangan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/mpp-dan-gpp"} className="text-dark">
                                    MPP dan GPP
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/kegiatan-harian"} className="text-dark">
                                    Kegiatan Harian
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/surat-tanggapan-informasi-publik"} className="text-dark">
                                    Surat Tanggapan Informasi Publik
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/oss"} className="text-dark">
                                    Online Single Submission (OSS)
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/laporan/lkpm"} className="text-dark">
                                    Kegiatan Penanaman Modal (LKPM)
                                </Link> */}
                            </div>
                        </div>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Laporan Investasi</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/laporan/investasi"} className="text-dark">
                                    Laporan Investasi
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                        <h3 className='text-dark font-bold'>Profil</h3>
                        <hr></hr>
                        <h4 className='text-dark font-bold'>Profil</h4>
                        <div className="row">
                            <div className="col-12">
                                <Link to={"/tentang-dpmptsp/profil"} className="text-dark">
                                    Profil
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/tentang-dpmptsp/struktur-organisasi"} className="text-dark">
                                    Struktur Organisasi
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/tentang-dpmptsp/profil-pejabat"} className="text-dark">
                                    Profil Pejabat
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/tentang-dpmptsp/struktur-ppid"} className="text-dark">
                                    Struktur PPID
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/tentang-dpmptsp/janji-layanan"} className="text-dark">
                                    Janji Layanan
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/tentang-dpmptsp/maklumat-dpmptsp"} className="text-dark">
                                    Maklumat DPMPTSP
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/tentang-dpmptsp/visi-misi-kota-bekasi"} className="text-dark">
                                    Visi Misi Kota Bekasi
                                </Link>
                                <div className="mb-2"></div>
                                <Link to={"/tentang-dpmptsp/penghargaan"} className="text-dark">
                                    Penghargaan Kota Bekasi
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;