import React, { useEffect } from 'react'
import { useParams, Link } from "react-router-dom"
import Bisnis from './Bisnis'
import Peraturan from './peraturan/Main'
import Pelayanan from './pelayanan/Main'
import Pengaduan from './pengaduan/Main'
import Panduan from './Panduan'
import Open from './Open'
import Kontak from './Kontak'
import Nav from './Nav'

const View = () => {
    const { kategori, subkategori } = useParams()

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <header className={`ms-hero-page-override ${subkategori === 'kontak' ? '' : 'ms-hero-img-bekasi'} no-mt pb-4`}>
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className={`breadcrumb-item ${subkategori === 'kontak' ? 'active' : ''}`} aria-current="page">LAYANAN DAN PENGADUAN</li>
                        <li className={`breadcrumb-item ${subkategori === 'kontak' ? 'active' : ''}`}aria-current="page">{kategori.replaceAll('-', ' ').toUpperCase()}</li>
                        <li className={`breadcrumb-item ${subkategori === 'kontak' ? 'active' : ''}`} aria-current="page">{subkategori.replaceAll('-', ' ').toUpperCase()}</li>
                    </ol>
                    {subkategori === 'kontak' || subkategori === 'layanan-terbuka' ? null : (<>
                        <div className="btn-group w-300">
                            <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {subkategori.replaceAll('-', ' ').toUpperCase()} <i className="zmdi zmdi-chevron-down right only"></i>
                            </button>
                            <Nav/>
                        </div>
                    </>)}
                </nav>
                {subkategori === 'memulai-bisnis' ? <Bisnis /> : null}
                {subkategori === 'peraturan' ? <Peraturan /> : null}
                {subkategori === 'pelayanan' ? <Pelayanan /> : null}
                {subkategori === 'pengaduan' ? <Pengaduan /> : null}
                {subkategori === 'panduan-aplikasi' ? <Panduan /> : null}
                {subkategori === 'layanan-terbuka' ? <Open /> : null}
                {subkategori === 'kontak' ? <Kontak /> : null}
            </div>
        </header>
    </>)
}

export default View;