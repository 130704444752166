import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'
import Captcha from '../../Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import Texteditor from "./Texteditor"

export const Edit = ({ rowID, getData, closeEdit, getPesan, setMsg }) => {

    const [token, setToken] = useState("")
    const [expired, setExpired] = useState("")
    const [id, setID] = useState("")
    const [potensialCode, setPotensialCode] = useState('')
    const [potensialName, setPotensialName] = useState('')
    const [contactName, setContactName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [information, setInformation] = useState('')
    const [file, setFile] = useState("")
    const [preview, setPreview] = useState("")
    const [captcha, setCaptcha] = useState("")
    const [recaptcha, setReCaptcha] = useState("")
    const [data, setData] = useState([])
    const [editorLoaded, setEditorLoaded] = useState(false)
    const navigate = useNavigate()
    const alert = {
        position: "relative",
        top: "7px",
        backgroundColor: "#e5df7c",
        color: "#333",
    }

    useEffect(() => {
        updateToken()
        getKegiatan()
        getPotencial()
        setEditorLoaded(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateToken = async () => {
        try {
            const response = await axios.get('/api/pelaku-usaha/token')
            setToken(response.data.authToken)
            const decode = jwt_decode(response.data.authToken)
            setExpired(decode.exp)
            setID(decode.id)
        } catch (err) {
            if (err.response) {
                navigate('/')
            }
        }
    }

    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expired * 1000 < currentDate.getTime()) {
            const response = await axios.get('/api/pelaku-usaha/token')
            config.headers.Authorization = `Bearer ${response.data.authToken}`
            setToken(response.data.authToken)
            const decode = jwt_decode(response.data.authToken)
            setID(decode.id)
            setExpired(decode.exp)
        }
        return config
    }, (err) => {
        return Promise.reject(err)
    })

    const getPotencial = async (key) => {
        try {
            const access = await axios.get('/api/pelaku-usaha/token')
            const decode = jwt_decode(access.data.authToken)
            const response = await axiosJWT.post("/api/kegiatan-usaha/publish", {
                user: decode.id,
                key: key
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setData(response.data)
        } catch (err) {
            getPesan()
            setMsg(err.response.data.msg ? err.response.data.msg : 'Data tidak terkirim, mohon coba lagi!')
            setTimeout(() => {
                getPesan()
                setMsg("")
            }, 2000)
        }
    }

    const getPotencialName = async (code) => {
        const response = await axiosJWT.post('/api/kegiatan-usaha/filter', {
            code,
            jml: 1
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        setPotensialCode(code)
        setPotensialName(response.data[0].name)
    }

    const getKegiatan = async () => {
        const response = await axiosJWT.get(`/api/procurement/${rowID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        setPotensialCode(response.data.potensialCode)
        setContactName(response.data.contactName)
        setPhoneNumber(response.data.phoneNumber)
        setEmail(response.data.email)
        setTitle(response.data.title)
        setDescription(response.data.description)
        setInformation(response.data.information)
        setPreview(response.data.filePath)
    }

    const loadFile = (e) => {
        const file = e.target.files[0]
        setFile(file)
        setPreview(URL.createObjectURL(file))
    }

    const proses = async (e) => {
        e.preventDefault()
        if (validateCaptcha(captcha) === false) {
            setReCaptcha("Captcha tidak sesuai!")
            setTimeout(() => {
                setReCaptcha("")
            }, 2000)
            setCaptcha("")
        } else {
            try {
                const formData = new FormData()
                formData.append("potensialCode", potensialCode)
                formData.append("potensialName", potensialName)
                formData.append("contactName", contactName)
                formData.append("phoneNumber", phoneNumber)
                formData.append("email", email)
                formData.append("title", title)
                formData.append("description", description)
                formData.append("file", file)
                formData.append("user", id)
                const response = await axiosJWT.put(`/api/procurement/${rowID}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                getPesan()
                setMsg(response.data.msg)
                loadCaptchaEnginge(6)
                setCaptcha("")
                setTimeout(() => {
                    getPesan()
                    setMsg('')
                    getData('')
                    closeEdit()
                }, 2000)
            } catch (err) {
                getPesan()
                setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
                setTimeout(() => {
                    getPesan()
                    setMsg('')
                }, 2000)
            }
        }
    }

    return (<>
        <form onSubmit={proses} className="form-horizontal">
            <h5 className="right-line color-success">Informasi Pemohon</h5>
            <div className="row">
                <div className="form-group col-md-6">
                    <label className="control-label">Nama Kontak</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-user"></i></span>
                        <input type="text" className="form-control" onChange={(e) => setContactName(e.target.value)} value={contactName} />
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label">No. Kontak</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-phone"></i></span>
                        <input type="number" className="form-control" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} />
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label">Email</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-mail"></i></span>
                        <input type="text" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} />
                    </div>
                </div>
            </div>
            <h5 className="right-line color-success">Informasi Kebutuhan</h5>
            <div className="row">
                <div className="form-group col-md-6">
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label className="control-label">Kegiatan Usaha</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-filter"></i></span>
                                <select className="form-control" value={potensialCode} onChange={(e) => getPotencialName(e.target.value)} required>
                                    <option value="">==</option>
                                    {data.map((row, i) => (
                                        <option key={i} value={row.potensialCode}>{i + 1}. {row.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <label className="control-label">Judul</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-newspaper-o"></i></span>
                                <input type="text" className="form-control" onChange={(e) => setTitle(e.target.value)} value={title} required />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className="control-label">File</label>
                    {preview ? (<iframe width="100%" height="200" src={preview} title="Preview File" alt="Preview File" />) : (<img width="100%" height="200" src="/assets/img/no-image.png" title="Preview File" alt="Preview File" />)}
                    <input className="form-control" type="file" onChange={loadFile} />
                    <span className="badge badge-danger">File Ekstensi .JPG | .JPEG | .PNG | .PDF | .MP4</span>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label">Deskripsi</label>
                    <div className="input-group">
                        <span className="input-group-addon"><i className="fa fa-info"></i></span>
                        <Texteditor onChange={(description) => { setDescription(description) }} editorLoaded={editorLoaded} value={description || ''} required />
                    </div>
                </div>
            </div>
            {information ? (<>
                <h5 className="right-line color-success">Keterangan Status</h5>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label className="control-label">Keterangan</label>
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fa fa-info"></i></span>
                            {parse(information)}
                        </div>
                    </div>
                </div>
            </>) : null}
            <h5 className="right-line color-success">Captcha</h5>
            <div className="row">
                <div className="text-center col-md-12">
                    <Captcha />
                    <p style={alert} className="text-center">
                        {recaptcha}
                    </p>
                </div>
                <div className="col-sm-12">
                    <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-raised btn-success br-2 no-m" type="Submit"><i className="fa fa-edit"></i> Update</button>
                <button onClick={closeEdit} className="btn btn btn-raised btn-danger br-2 no-m">
                    <i className="fa fa-sign-out"></i> Tutup
                </button>
            </div>
        </form>
    </>)
}