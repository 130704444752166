import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import Captcha from './Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'

function Login() {
    const [pesan, setPesan] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [captcha, setCaptcha] = useState("")
    const [recaptcha, setReCaptcha] = useState("")
    const [type, setType] = useState('password')
    const [msg, setMsg] = useState('')
    const navigate = useNavigate()
    const alert = {
        position: "relative",
        top: "7px",
        backgroundColor: "#e5df7c",
        color: "#333",
    }

    useEffect(() => {
        loadCaptchaEnginge(6)
    }, [])

    const typePassword = () => {
        if (type === "password") {
            setType('text')
        } else {
            setType('password')
        }
    }

    const Auth = async (e) => {
        e.preventDefault()
        if (validateCaptcha(captcha) === false) {
            setReCaptcha("Captcha tidak sesuai!")
            setTimeout(() => {
                setReCaptcha("")
            }, 2000)
            setCaptcha("")
        } else {
            try {
                await axios.post('/api/pelaku-usaha/auth', {
                    username, password
                })
                navigate('/')
            } catch (err) {
                getPesan()
                loadCaptchaEnginge(6)
                setCaptcha("")
                setReCaptcha("")
                setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
                setTimeout(() => {
                    getPesan()
                    setMsg('')
                }, 2000)
            }
        }
    }

    const getPesan = () => {
        setPesan((prevState) => !prevState)
    }

    const display = {
        display: `${pesan ? "block" : "none"}`,
    }

    return (
        <>
            <div className="bg-full-page bg-primary back-fixed">
                <div className="mw-500 absolute-center">
                    <div className="card color-dark animated zoomInDown animation-delay-5 border-radius-20">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h3 className="color-success">Masuk</h3>
                                </div>
                                <div className="col-md-6 col-6">
                                    <img width="100%" src="assets/img/logo-head.png" alt="" />
                                </div>
                            </div>
                            <form onSubmit={Auth}>
                                {/* <p style= {alert} className="text-center">
                            {msg}
                            </p> */}
                                <fieldset>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="email-login">Nama Pengguna (Username)</label>
                                        <div className="input-group">
                                            <span className="input-group-addon"><i className="zmdi zmdi-account"></i></span>
                                            <input type="text" id="email-login" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="password-login">Kata Kunci (Password)</label>
                                        <div className="input-group">
                                            <span className="input-group-addon"><i className="fa fa-key"></i></span>
                                            <input type={type} id="password-login" className="form-control" value={password} autoComplete="no" onChange={(e) => setPassword(e.target.value)} />
                                            <span className="input-group-addon">
                                                <span onClick={typePassword}><i className="control-label zmdi zmdi-eye"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="text-center">
                                            <Captcha />
                                        </div>
                                        <label className="control-label" htmlFor="capthcha">Captcha</label>
                                        <p style={alert} className="text-center">
                                            {recaptcha}
                                        </p>
                                        <div className="input-group">
                                            <span className="input-group-addon"><i className="fa fa-lock"></i></span>
                                            <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6 col-6">
                                            <button className="btn btn-raised btn-success btn-block p-1 br-2">MASUK <i className="zmdi zmdi-long-arrow-right no-mr ml-1"></i></button>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <Link to={'/reset'} className="btn btn-raised btn-warning btn-block p-1 br-2"><i className="zmdi zmdi-key mr-1"></i> RESET PASWORD</Link>
                                        </div>
                                        <div className="col-md-12 text-center mt-2">
                                            Belum punya akun? <Link className="color-success" to={'/register'}>Daftar</Link> atau kembali <Link className="color-success" to={'/'}>Beranda</Link>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={pesan ? "modal-open" : ""}>
                <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog center">
                        <div className="modal-content modal-sm">
                            <div className="modal-header header-modal-primary p-1">
                                <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                                    PESAN
                                </h4>
                            </div>
                            <div className="modal-body text-center">
                                <h4 className="font-bold">{msg.toUpperCase()}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {pesan ? <div className="modal-backdrop fade in"></div> : ""}
        </>
    )
}

export default Login