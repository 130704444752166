import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import axios from "axios"

const View = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post('/api/profil/pejabat', {
            key: '',
            jml: 100
        })
        setData(response.data)
    }

    return (<>
        <div className="col-md-12">
            <h1 className='color-dark font-bold lh-125'>Profil Pejabat</h1>
            <h4 className="color-dark font-bold lh-125">Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi adalah Instansi Pemerintah yang bertugas melaksanakan kebijakan dan koordinasi pelayanan di bidang penanaman modal sesuai dengan ketentuan peraturan perundang-undangan.</h4>
            <div className="row">
                {data.map((val, index) => (
                    <div className="col-md-3" key={index}>
                        <div to={'/pejabat/' + val.nama} className="card mb-4 wow materialUp animation-delay-5">
                            <figure className="ms-thumbnail ms-thumbnail-diagonal">
                                {val.url ? (<iframe width="100%" height="200" src={val.url} title="Preview File" alt="Preview File" />) : ("")}
                            </figure>
                            <div className="card-body">
                                <h4 className='panel-title font-bold'>{val.nama}</h4>
                                {val.jabatan}
                            </div>
                            <div className="card-footer text-center">
                                <Link to={'/tentang-dpmptsp/profil-pejabat/' + val.title} className="btn btn-raised btn-primary br-2 no-m">Lihat</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>)
}

export default View;