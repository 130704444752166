import React, { useState, useEffect } from 'react'
import axios from "axios"
import parse from 'html-react-parser'
import { useParams, Link } from "react-router-dom"
import { TanggalHari } from '../Utils'
import Nav from './Nav'

const View = () => {
    const { kategori, title } = useParams()
    const [list, setList] = useState([])
    const [judul, setJudul] = useState('')
    const [tanggal, setTanggal] = useState('')
    const [deskripsi, setDeskripsi] = useState('')
    const [ext, setExt] = useState('')
    const [url, setUrl] = useState('')

    useEffect(() => {
        getData(title)
        getList()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (title) => {
        const category = kategori.replaceAll('-', ' ').toUpperCase()
        const response = await axios.post("/api/publikasi/publish", {
            kategori: category,
            key: title,
            jml: 1
        })
        const file_name_array = response.data[0].file.split(".");
        const file_extension = file_name_array[file_name_array.length - 1];
        setJudul(response.data[0].judul)
        setTanggal(response.data[0].tanggal)
        setDeskripsi(response.data[0].deskripsi)
        setExt(file_extension)
        setUrl(response.data[0].url)
    }

    const getList = async () => {
        const category = kategori.replaceAll('-', ' ').toUpperCase()
        const response = await axios.post("/api/publikasi/publish", {
            kategori: category,
            jml: 5
        })
        setList(response.data)
    }

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">INFO</li>
                        <li className="breadcrumb-item active">
                            {kategori.replaceAll('-', ' ').toUpperCase()}
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{judul.replaceAll('-', ' ').toUpperCase()}</li>
                    </ol>
                    <div className="btn-group w-300">
                        <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            INFO <i className="zmdi zmdi-chevron-down right only"></i>
                        </button>
                        <Nav />
                    </div>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-3">
                        {url && ext === 'pdf' && (kategori === 'pengumuman' || kategori === 'artikel-publikasi') ? (<iframe className='mb-4' width="100%" src={url} title="Preview File" alt="Preview File" />) : ("")}
                        {url && ext !== 'pdf' && (kategori === 'pengumuman' || kategori === 'artikel-publikasi') ? (<img className='mb-4' width="100%" src={url} title="Preview File" alt="Preview File" />) : ("")}
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className='panel-title'>{kategori.replaceAll('-', ' ').toUpperCase()}</h3>
                            </div>
                            <div className="card-body">
                                <div className="ms-media-list">
                                    {list.map((val, index) => (
                                        <div onClick={() => getData(val.title)} key={index} className="media mb-2">
                                            <div className="media-left media-middle">
                                                {val.url && val.url.substr(-3) === 'pdf' && (kategori === 'pengumuman' || kategori === 'artikel-publikasi') ? (<iframe className='d-flex mr-3 media-object' width="100%" height="500" src={val.url} title="Preview File" alt="Preview File" />) : ("")}
                                                {val.url && val.url.substr(-3) !== 'pdf' && (kategori === 'pengumuman' || kategori === 'artikel-publikasi') ? (<img className='d-flex mr-3 media-object' width="100%" src={val.url} title="Preview File" alt="Preview File" />) : ("")}
                                            </div>
                                            <div className="media-body">
                                                <span className="media-heading color-dark font-bold">{val.judul}</span>
                                                <div className="media-footer text-small color-dark">
                                                    <span className="mr-1"><i className="zmdi zmdi-time color-dark mr-05"></i>
                                                        <TanggalHari value={val.tanggal} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <h1 className='color-dark font-bold lh-125'>{judul}</h1>
                        <h5 className="justify-desc"><TanggalHari value={tanggal} /></h5>
                        {url && ext === 'pdf' && (kategori === 'siaran-pers' || kategori === 'agenda-kegiatan' || kategori === 'galeri') ? (<iframe className='mb-4' width="100%" height="500" src={url} title="Preview File" alt="Preview File" />) : ("")}
                        {url && ext !== 'pdf' && (kategori === 'siaran-pers' || kategori === 'agenda-kegiatan' || kategori === 'galeri') ? (<img className='mb-4' width="100%" src={url} title="Preview File" alt="Preview File" />) : ("")}
                        {parse(deskripsi)}
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;