import React, { useEffect } from 'react'
import { useParams, Link } from "react-router-dom"
import Result from './Result'

const View = () => {
    const { keyword } = useParams()

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">HASIL PENCARIAN</li>
                        <li className="breadcrumb-item active" aria-current="page">{decodeURIComponent(keyword)}</li>
                    </ol>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <h1 className='color-dark font-bold lh-125'>Hasil Pencarian</h1>
                        <h3 className="color-dark lh-125">Hasil Pencarian Untuk "{decodeURIComponent(keyword)}"</h3>
                        <hr></hr>
                        <Result keyword={keyword}/>
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;