import React, { useState, useEffect, useMemo } from 'react'
import { useParams, Link } from "react-router-dom"
import axios from "axios"
import Nav from './Nav'
import Pagination from '../pages/paginations'

const View = () => {
    const { kategori } = useParams()
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(10)
    const [data, setData] = useState([])

    useEffect(() => {
        getData(kategori, '')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (cat, value) => {
        const category = cat ? cat.replaceAll('-', ' ').replaceAll('_', '/') : kategori.replaceAll('-', ' ').replaceAll('_', '/')
        const response = await axios.post("/api/kegiatan-usaha/filter", {
            key: value ? value : '',
            kategori: category,
            jml: 3000
        })
        setData(response.data)
        setCurrentPage(1)
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage])

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">KEGIATAN USAHA</li>
                        <li className="breadcrumb-item active" aria-current="page">{kategori.replaceAll('-', ' ').replaceAll('_', '/').toUpperCase()}</li>
                    </ol>
                    <div className="btn-group w-300">
                        <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {kategori.replaceAll('-', ' ').replaceAll('_', '/').toUpperCase()} <i className="zmdi zmdi-chevron-down right only"></i>
                        </button>
                        <Nav getKategori={getData}/>
                    </div>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <h1 className='color-dark font-bold lh-125'>{kategori.replaceAll('-', ' ').replaceAll('_', '/').toUpperCase()}</h1>
                        <h3 className="color-dark lh-125">Telusuri {kategori.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase()} di Kota Bekasi</h3>
                        <hr></hr>
                        <div className="row">
                            <div className="col-md-12">
                                <input type="text" className="form-control" placeholder="Masukkan Kata Kunci" onChange={(e) => getData('', e.target.value)} />
                            </div>
                        </div>
                        <div className="row">
                            {tableData.map((val, index) => (
                                <div className="col-md-3 mb-3" key={index}>
                                    <Link to={'/kegiatan-usaha/' + kategori.replaceAll('-', ' ').replaceAll('/', '_').toLowerCase() + '/' + val.potensialCode} className="panel panel-dark panel-primary">
                                        <div className="panel-heading">
                                            <h3 className='panel-title'>{val.name}</h3>
                                        </div>
                                        <div className="panel-body no-p">
                                            <img className='d-flex mr-3 media-object' width="100%" height="200" src={val.imagePath !== '-' ? val.imagePath : val.categoryPath} title="Preview File" alt="Preview File" />
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div className="text-center p-4">
                            <Pagination className="pagination-bar" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;