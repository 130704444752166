import React, { useState, useEffect } from 'react'
import axios from "axios"
import { Link } from "react-router-dom"

const View = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post("/api/publikasi/prosedur-publish", {
            kategori: 'PELAYANAN',
            jml: 100
        })
        setData(response.data)
    }

    return (<>
        <div className="row mt-4 no-shadow">
            <div className="col-lg-12">
                <div className="row fail">
                    <div className="col-lg-3 col-md-3 col-sm-2"></div>
                    <div className="col-lg-6 col-md-6 col-sm-10 ">
                        <div className="card card-banner animated zoomInUp animation-delay-3">
                            <div className="card-body mt-1 text-center">
                                <h1 className='color-success font-bold lh-125 no-m'>Pelayanan</h1>
                                <hr></hr>
                                <h4 className='text-dark lh-125'>Kami siap melayani anda sepenuh hati, sebagai bentuk tanggung jawab kami terhadap setiap kegiatan investasi di Kota Bekasi.</h4>
                                <hr></hr>
                                <div className="mb-20">
                                    <a href={'https://silat.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-primary br-2">Cek Status Perizinan</a>
                                    {data.map((val, index) => (
                                        <Link key={index} to={'/layanan-dan-pengaduan/prosedur/pelayanan/informasi-pelayanan/' + val.title} className="btn btn-raised btn-primary br-2">{val.judul}</Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default View;