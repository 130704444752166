import React from 'react'
import { Link } from "react-router-dom"

const Nav = () => {

    return (<>
        <ul className="dropdown-menu bg-primary w-300 pb-15">
            <li className="dropdown-header">
                <h4 className='color-warning'>Survey Kepuasan Masyarakat</h4>
            </li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/survey-kepuasan-masyarakat/kuesioner'}>Kuesioner</Link></li>
            <li><Link className="dropdown-item" to={'/survey-kepuasan-masyarakat/testimoni'}>Testimoni</Link></li>
        </ul>
    </>)
}

export default Nav;