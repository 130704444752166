import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { Perorangan } from "./Perorangan"
import { BadanUsaha } from "./BadanUsaha"

function View() {
    const [jenis, setJenis] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        updateToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateToken = async () => {
        try {
            const response = await axios.get('/api/pelaku-usaha/token')
            const decode = jwt_decode(response.data.authToken)
            setJenis(decode.jenis)
        } catch (err) {
            if (err.response) {
                navigate('/login')
            }
        }
    }

    return (
        <>
            {jenis === '1' ? (<Perorangan/>) : (<BadanUsaha/>)}
        </>
    )
}

export default View