import React from 'react'
import { Link } from "react-router-dom"

const Nav = () => {

    return (<>
        <ul className="dropdown-menu bg-primary w-300 pb-15">
            <li className="dropdown-header">
                <h4 className='color-warning'>Tentang DPMPTSP</h4>
            </li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/tentang-dpmptsp/profil'}>Profil</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/tentang-dpmptsp/struktur-organisasi'}>Struktur Organisasi</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/tentang-dpmptsp/profil-pejabat'}>Profil Pejabat</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/tentang-dpmptsp/struktur-ppid'}>Struktur PPID</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/tentang-dpmptsp/janji-layanan'}>Janji Layanan</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/tentang-dpmptsp/maklumat-dpmptsp'}>Maklumat DPMPTSP</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/tentang-dpmptsp/visi-misi-kota-bekasi'}>Visi Misi Kota Bekasi</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/tentang-dpmptsp/penghargaan'}>Penghargaan</Link></li>
        </ul>
    </>)
}

export default Nav;