import React from 'react'
import { Link } from "react-router-dom"

const Nav = ({ getJenis }) => {

    return (<>
        <ul className="dropdown-menu bg-primary w-300 pb-15">
            <li className="dropdown-header">
                <h4 className='color-warning'>Produk Hukum</h4>
            </li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/peraturan/produk-hukum/undang-undang'} onClick={() => getJenis('undang-undang')}>Undang-Undang</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/peraturan/produk-hukum/peraturan-pemerintah'} onClick={() => getJenis('peraturan-pemerintah')}>Peraturan Pemerintah</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/peraturan/produk-hukum/peraturan-dan-keputusan-menteri'} onClick={() => getJenis('peraturan-dan-keputusan-menteri')}>Peraturan dan Keputusan Menteri</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/peraturan/produk-hukum/peraturan-daerah-kota-bekasi'} onClick={() => getJenis('peraturan-daerah-kota-bekasi')}>Peraturan Daerah Kota Bekasi</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/peraturan/produk-hukum/peraturan-walikota'} onClick={() => getJenis('peraturan-walikota')}>Peraturan Walikota</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/peraturan/produk-hukum/keputusan-walikota'} onClick={() => getJenis('keputusan-walikota')}>Keputusan Walikota</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/peraturan/produk-hukum/keputusan-kepala-dinas'} onClick={() => getJenis('keputusan-kepala-dinas')}>Keputusan Kepala Dinas</Link></li>
        </ul>
    </>)
}

export default Nav;