import React, { useEffect } from 'react'
import axios from "axios"
import parse from 'html-react-parser'
import { Tanggal } from '../../Utils'

const View = ({ tableData }) => {

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const download = async (key) => {
        const response = await axios.get(`/api/regulasi/publish/download/${key}`, { responseType: 'blob' });
        if (response.data.error) {
            console.error(response.data.error)
        }

        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition.substring(
            contentDisposition.indexOf('filename=') + 9,
            contentDisposition.length
        );
        fileLink.setAttribute('download', fileName);
        fileLink.setAttribute('target', '_blank');
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
    }

    return (<>
        <table className="table">
            <thead>
                <tr className="purple">
                    <th>No.</th>
                    <th>Nomor</th>
                    <th>Tahun</th>
                    <th>Perihal</th>
                    <th>Penetapan</th>
                    <th>Download</th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((val, index) => (
                    <tr key={index}>
                        <td width="5%">{index + 1}</td>
                        <td width="15%">{val.nomor}</td>
                        <td width="10%">{val.tahun}</td>
                        <td width="40%">{parse(val.perihal)}</td>
                        <td width="15%"><Tanggal value={val.tanggal} /></td>
                        <td width="15%">
                            <button onClick={() => download(val.file)} className="btn btn-raised btn-primary br-2 no-m"><i className="fa fa-download"></i>Download</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </>)
}

export default View;