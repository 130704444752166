import React from 'react'

const View = () => {

    return (<>
        <div className="row mt-4 no-shadow">
            <div className="col-lg-12">
                <div className="row fail">
                    <div className="col-lg-3 col-md-3 col-sm-2"></div>
                    <div className="col-lg-6 col-md-6 col-sm-10 ">
                        <div className="card card-banner animated zoomInUp animation-delay-3">
                            <div className="card-body mt-1 text-center">
                                <h1 className='color-success font-bold lh-125 no-m'>Layanan Terbuka</h1>
                                <hr></hr>
                                <h4 className='text-dark font-bold lh-125'>Pintu utama untuk mengakses informasi dan aplikasi perizinan dan nonperizinan.</h4>
                                <hr></hr>
                                <div className="flex-card">
                                    <div className="lh-125 margin-auto">
                                        <a href='https://nswi.bkpm.go.id/data_statistik?_gl=1*1n68d9s*_ga*NDc4MzkxNTQ2LjE3MDY0ODAxMTI.*_ga_BT2NVHY1TH*MTcwNjk4NjUxMC4yNC4xLjE3MDY5ODY4MDQuNjAuMC4w' target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-primary br-2">Kunjungi Open Data</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>)
}

export default View;