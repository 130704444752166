import React, { useState, useEffect } from 'react'
import axios from "axios"

const View = ({ tableData }) => {
    const [view, setView] = useState(false)
    const [file, setFile] = useState('')
    const [ext, setExt] = useState('')

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reviewFile = async (file) => {
        const file_name_array = file.split(".");
        const file_extension = file_name_array[file_name_array.length - 1];
        setExt(file_extension)
        setFile(file)
        getView()
    }

    const getView = () => {
        setView((prevState) => !prevState)
    }

    const displayView = {
        display: `${view ? "block" : "none"}`
    }

    const download = async (key) => {
        const response = await axios.get(`/api/laporan/publish/download/${key}`, { responseType: 'blob' });
        if (response.data.error) {
            console.error(response.data.error)
        }

        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition.substring(
            contentDisposition.indexOf('filename=') + 9,
            contentDisposition.length
        );
        fileLink.setAttribute('download', fileName);
        fileLink.setAttribute('target', '_blank');
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
    }

    return (<>
        <table className="table">
            <thead>
                <tr className="purple">
                    <th>No.</th>
                    <th>Jenis</th>
                    <th>Subjenis</th>
                    <th>Periode</th>
                    <th>Waktu</th>
                    <th>Lihat</th>
                    <th>Download</th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((val, index) => (
                    <tr key={index}>
                        <td width="5%">{index + 1}</td>
                        <td width="25%">{val.jenis}</td>
                        <td width="25%">{val.subjenis}</td>
                        <td width="15%">{val.periode}</td>
                        <td width="15%">{val.waktu}</td>
                        <td width="7%">
                            <button onClick={() => reviewFile(val.url)} className="btn btn-raised btn-warning br-2 no-m"><i className="fa fa-eye"></i>Lihat</button>
                        </td>
                        <td width="8%">
                            <button onClick={() => download(val.file)} className="btn btn-raised btn-primary br-2 no-m"><i className="fa fa-download"></i>Download</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        {view ? (<>
            <div className={view ? ("modal-open") : ("")}>
                <div style={displayView} className={view ? 'modal modal-primary show' : 'modal modal-primary'} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog review">
                        <div className="modal-content modal-xl">
                            <div className="modal-header header-modal-primary p-1">
                                <h4 className="modal-title text-center" id="myModalLabel">Lihat File</h4>
                            </div>
                            <div className="modal-body">
                                {file && ext === 'pdf' ? (<iframe className='mb-2' width="100%" height="500" src={file} title="Preview File" alt="Preview File" />) : ("")}
                                {file && ext !== 'pdf' ? (<img className='mb-2' width="100%" src={file} title="Preview File" alt="Preview File" />) : ("")}
                                <button onClick={getView} className="btn btn-raised btn-primary br-2 no-m"><i className="fa fa-close"></i> Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>) : ''}
    </>)
}

export default View;