import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import axios from "axios"
import Marquee from "react-fast-marquee"

export const Pengumuman = () => {
    const [pengumuman, setPengumuman] = useState([])

    useEffect(() => {
        getPengumuman()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPengumuman = async () => {
        const response = await axios.post("/api/publikasi/publish", {
            kategori: 'PENGUMUMAN',
            jml: 5
        })
        setPengumuman(response.data)
    }

    return (
        <>
            <div className="pengumuman">
                <div className="container">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-1 col-3">
                                <h4 className="font-bold">PENGUMUMAN:</h4>
                            </div>
                            <div className="col-md-11 col-9">
                                <Marquee speed={70} gradientColor={"none"}>
                                    {pengumuman.map((val, index) => (
                                        <Link to={'/info/pengumuman/' + val.title} className="mr-4 text-white" key={index}>
                                            <i className="fa fa-info-circle"></i> {val.judul}
                                        </Link>
                                    ))}
                                </Marquee>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
