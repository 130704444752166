import React from 'react'
import { Link } from "react-router-dom"

const Nav = () => {

    return (<>
        <ul className="dropdown-menu bg-primary w-300 pb-15">
            <li className="dropdown-header">
                <h4 className='color-warning'>Prosedur</h4>
            </li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/memulai-bisnis'}>Memulai Bisnis</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/peraturan'}>Peraturan</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/pelayanan'}>Pelayanan</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/pengaduan'}>Pengaduan</Link></li>
            <li role="separator" className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to={'/layanan-dan-pengaduan/prosedur/panduan-aplikasi'}>Panduan Aplikasi</Link></li>
        </ul>
    </>)
}

export default Nav;