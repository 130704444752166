import React, { useMemo } from 'react'
import JoditEditor from 'jodit-react'

export default function Texteditor({ onChange, editorLoaded, name, value }) {
  // const [content, setContent] = useState('')
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: '',
      defaultActionOnPaste: 'insert_as_html',
    }),
    []
  )
  return (
    <div>
      {editorLoaded ? (
        <JoditEditor
          ref={name}
          value={value}
          tabIndex={1}
          config={config}
          // onBlur={newContent => setContent(newContent)}
          onChange={newContent => {
            onChange(newContent)
          }}
        />
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  )
}
