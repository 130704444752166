import React from "react"
import { Link } from "react-router-dom"

export const Skm = () => {
    return (<>
        <div className="wrap bg-white color-dark">
            <div className="container">
                <h1 className="color-success font-bold no-mt">Survei Kepuasan Masyarakat</h1>
                <div className="row">
                    <div className="col-md-6">
                        <img src="/assets/img/skm.png" alt="survey-skm" className='img-responsive' />
                    </div>
                    <div className="col-md-6">
                        <h3 className="font-bold color-dark">
                            Berikan saran dan masukan kepada kami, karena saran dan masukan anda akan menjadikan kami lebih baik lagi dalam melayani anda sepenuh hati.
                        </h3>
                        <div className="lh-125">
                            <Link to={'survey-kepuasan-masyarakat/kuesioner'} rel="noopener noreferrer" className="btn btn-raised btn-success br-2">Survei Kepuasan Masyarakat</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}