import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Captcha from '../../Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'

export const BadanUsaha = () => {
  const [pesan, setPesan] = useState(false)
  const [msg, setMsg] = useState('')
  const [token, setToken] = useState("")
  const [expired, setExpired] = useState("")
  const [id, setID] = useState("")
  const [penanggung, setPenanggung] = useState('')
  const [jabatan, setJabatan] = useState('')
  const [nm_badan_usaha, setNmBadanUsaha] = useState('')
  const [jns_badan, setJnsBadan] = useState('')
  const [no_ahu, setNoAhu] = useState('')
  const [nm_notaris, setNmNotaris] = useState('')
  const [no_akta, setNoAkta] = useState('')
  const [no_npwp, setNoNpwp] = useState('')
  const [no_prop, setNoProp] = useState('')
  const [no_kab, setNoKab] = useState('')
  const [no_kec, setNoKec] = useState('')
  const [no_kel, setNoKel] = useState('')
  const [no_rw, setNoRw] = useState('')
  const [no_rt, setNoRt] = useState('')
  const [alamat, setAlamat] = useState('')
  const [kd_pos, setKdPos] = useState('')
  const [email, setEmail] = useState('')
  const [no_telp, setNoTelp] = useState('')
  const [captcha, setCaptcha] = useState("")
  const [recaptcha, setReCaptcha] = useState("")
  const [jenisUsaha, setJenisUsaha] = useState([])
  const [propinsi, setPropinsi] = useState([])
  const [kabupaten, setKabupaten] = useState([])
  const [kecamatan, setKecamatan] = useState([])
  const [kelurahan, setKelurahan] = useState([])
  const navigate = useNavigate()
  const alert = {
    position: "relative",
    top: "7px",
    backgroundColor: "#e5df7c",
    color: "#333",
  }

  useEffect(() => {
    updateToken()
    getPropinsi()
    getAkun()
    getJenisUsaha()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateToken = async () => {
    try {
      const response = await axios.get('/api/pelaku-usaha/token')
      setToken(response.data.authToken)
      const decode = jwt_decode(response.data.authToken)
      setExpired(decode.exp)
      setID(decode.id)
    } catch (err) {
      if (err.response) {
        navigate('/')
      }
    }
  }

  const axiosJWT = axios.create()

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expired * 1000 < currentDate.getTime()) {
      const response = await axios.get('/api/pelaku-usaha/token')
      config.headers.Authorization = `Bearer ${response.data.authToken}`
      setToken(response.data.authToken)
      const decode = jwt_decode(response.data.authToken)
      setID(decode.id)
      setExpired(decode.exp)
    }
    return config
  }, (err) => {
    return Promise.reject(err)
  })

  const getPropinsi = async () => {
    const response = await axios.post('/api/wilayah/propinsi')
    setPropinsi(response.data)
  }

  const getJenisUsaha = async () => {
    const response = await axios.get('/api/jenis-usaha')
    setJenisUsaha(response.data)
  }

  const getKabupaten = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kabupaten`, {
        no_prop: key
      })
      setKabupaten(response.data)
      setNoKab('')
      setNoProp(key)
    } catch {
      setKabupaten([])
      setNoKab("")
      setNoProp(key)
    }
  }

  const getKecamatan = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kecamatan`, {
        no_prop,
        no_kab: key
      })
      setKecamatan(response.data)
      setNoKec('')
      setNoKab(key)
    } catch {
      setKecamatan([])
      setNoKec("")
      setNoKab(key)
    }
  }

  const getKelurahan = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kelurahan`, {
        no_prop,
        no_kab,
        no_kec: key
      })
      setKelurahan(response.data)
      setNoKel('')
      setNoKec(key)
    } catch {
      setKelurahan([])
      setNoKel("")
      setNoKec(key)
    }
  }

  const getAkun = async () => {
    const params = await axios.get('/api/pelaku-usaha/token')
    const decode = jwt_decode(params.data.authToken)
    const response = await axios.get(`/api/badan-usaha/publish/${decode.id}`, {
      headers: {
        Authorization: `Bearer ${params.data.authToken}`
      }
    })
    // KTP
    const kabupaten = await axios.post(`/api/wilayah/kabupaten`, {
      no_prop: response.data.no_prop,
      no_kab: response.data.no_kab
    })
    setKabupaten(kabupaten.data)
    const kecamatan = await axios.post(`/api/wilayah/kecamatan`, {
      no_prop: response.data.no_prop,
      no_kab: response.data.no_kab,
      no_kec: response.data.no_kec
    })
    setKecamatan(kecamatan.data)
    const kelurahan = await axios.post(`/api/wilayah/kelurahan`, {
      no_prop: response.data.no_prop,
      no_kab: response.data.no_kab,
      no_kec: response.data.no_kec,
      no_kel: response.data.no_kel
    })
    setKelurahan(kelurahan.data)
    // End KTP
    setPenanggung(response.data.penanggung)
    setJabatan(response.data.jabatan)
    setNmBadanUsaha(response.data.nm_badan_usaha)
    setJnsBadan(response.data.jns_badan)
    setNoAhu(response.data.no_ahu)
    setNmNotaris(response.data.nm_notaris)
    setNoAkta(response.data.no_akta)
    setNoNpwp(response.data.no_npwp)
    setEmail(response.data.email)
    setNoTelp(response.data.no_telp)
    setNoProp(response.data.no_prop)
    setNoKab(response.data.no_kab)
    setNoKec(response.data.no_kec)
    setNoKel(response.data.no_kel)
    setNoRw(response.data.no_rw)
    setNoRt(response.data.no_rt)
    setAlamat(response.data.alamat)
    setKdPos(response.data.kd_pos)
  }

  const prosesAkun = async (e) => {
    e.preventDefault()
    if (validateCaptcha(captcha) === false) {
      setReCaptcha("Captcha tidak sesuai!")
      setTimeout(() => {
        setReCaptcha("")
      }, 2000)
      setCaptcha("")
    } else {
      const params = await axios.get('/api/pelaku-usaha/token')
      const decode = jwt_decode(params.data.authToken)
      const response = await axios.get(`/api/badan-usaha/publish/${decode.id}`, {
        headers: {
          Authorization: `Bearer ${params.data.authToken}`
        }
      })
      if (response.data) {
        editAkun()
      } else {
        simpanAkun()
      }
    }
  }

  const simpanAkun = async () => {
    try {
      const response = await axiosJWT.post('/api/badan-usaha/', {
        token: id, nm_badan_usaha, jns_badan, no_ahu, nm_notaris, no_akta, no_npwp, email, no_telp,
        no_prop, no_kab, no_kec, no_kel, no_rw, no_rt, alamat, kd_pos,
        penanggung, jabatan,
        i_user: id,
        i_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
        u_user: id,
        u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      await axiosJWT.put(`/api/pelaku-usaha/akun/${id}`, {
        nm_lengkap: penanggung,
        u_user: id,
        u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br')
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      getPesan()
      setMsg(response.data.msg)
      loadCaptchaEnginge(6)
      setCaptcha("")
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } catch (err) {
      getPesan()
      setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    }
  }

  const editAkun = async () => {
    try {
      const response = await axiosJWT.put(`/api/badan-usaha/${id}`, {
        nm_badan_usaha, jns_badan, no_ahu, nm_notaris, no_akta, no_npwp, email, no_telp,
        no_prop, no_kab, no_kec, no_kel, no_rw, no_rt, alamat, kd_pos,
        penanggung, jabatan,
        u_user: id,
        u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      await axiosJWT.put(`/api/pelaku-usaha/akun/${id}`, {
        nm_lengkap: penanggung,
        u_user: id,
        u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br')
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      getPesan()
      setMsg(response.data.msg)
      loadCaptchaEnginge(6)
      setCaptcha("")
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } catch (err) {
      getPesan()
      setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    }
  }

  const getPesan = () => {
    setPesan((prevState) => !prevState)
  }

  const display = {
    display: `${pesan ? "block" : "none"}`,
  }

  return (<>
    <div className="container">
      <div className="card animated fadeInUp animation-delay-7 br-2 mb-6">
        <div className="card-body p-5">
          <form onSubmit={prosesAkun} className="form-horizontal">
            <h5 className="right-line color-success">Data Perusahaan</h5>
            <div className="row">
              <div className="form-group col-md-12">
                <label className="control-label" htmlFor="nm_badan_usaha">Bertindak Atas Nama / Kuasa / Kerja Sama dengan Perusahaan (Nama Badan Usaha) </label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-building"></i></span>
                  <input type="text" id="nm_badan_usaha" className="form-control" value={nm_badan_usaha} onChange={(e) => setNmBadanUsaha(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label">Jenis Badan Usaha</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                  <select className="form-control" value={jns_badan} onChange={(e) => setJnsBadan(e.target.value)} required>
                    <option value="">==</option>
                    {jenisUsaha.map((val, i) => (
                      <option key={i} value={val._id}>{i + 1 + '. ' + val.nm_jenis}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label" htmlFor="no_ahu">No. AHU</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                  <input type="text" id="no_ahu" className="form-control" value={no_ahu} onChange={(e) => setNoAhu(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label" htmlFor="nm_notaris">Nama Notaris</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                  <input type="text" id="nm_notaris" className="form-control" value={nm_notaris} onChange={(e) => setNmNotaris(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label" htmlFor="no_akta">No. Akta Pendirian/Perubahan</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                  <input type="text" id="no_akta" className="form-control" value={no_akta} onChange={(e) => setNoAkta(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label" htmlFor="no_npwp">No. NPWP Perusahaan</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                  <input type="text" id="no_npwp" className="form-control" value={no_npwp} onChange={(e) => setNoNpwp(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label" htmlFor="email">Email Perusahaan</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                  <input type="text" id="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label" htmlFor="no_telp">No. Telpon Perusahaan</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                  <input type="text" id="no_telp" className="form-control" value={no_telp} onChange={(e) => setNoTelp(e.target.value)} required />
                </div>
              </div>
            </div>
            <h5 className="right-line color-success">Alamat Perusahaan</h5>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="control-label">Propinsi</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-map"></i></span>
                  <select className="form-control" value={no_prop} onChange={(e) => getKabupaten(e.target.value)} required>
                    <option value="">==</option>
                    {propinsi.map((val, i) => (
                      <option key={i} value={val.no_prop}>{i + 1 + '. ' + val.nama_prop}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label">Kabupaten</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-map"></i></span>
                  <select className="form-control" value={no_kab} onChange={(e) => getKecamatan(e.target.value)} required>
                    <option value="">==</option>
                    {kabupaten.map((val, i) => (
                      <option key={i} value={val.no_kab}>{i + 1 + '. ' + val.nama_kab}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label">Kecamatan</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-map"></i></span>
                  <select className="form-control" value={no_kec} onChange={(e) => getKelurahan(e.target.value)} required>
                    <option value="">==</option>
                    {kecamatan.map((val, i) => (
                      <option key={i} value={val.no_kec}>{i + 1 + '. ' + val.nama_kec}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label">Kelurahan</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-map"></i></span>
                  <select className="form-control" value={no_kel} onChange={(e) => setNoKel(e.target.value)} required>
                    <option value="">==</option>
                    {kelurahan.map((val, i) => (
                      <option key={i} value={val.no_kel}>{i + 1 + '. ' + val.nama_kel}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label" htmlFor="alamat">Alamat</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-map"></i></span>
                  <input type="text" id="alamat" className="form-control" value={alamat} onChange={(e) => setAlamat(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-2">
                <label className="control-label" htmlFor="no_rw">No. Rw</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-map"></i></span>
                  <input type="text" id="no_rw" className="form-control" value={no_rw} onChange={(e) => setNoRw(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-2">
                <label className="control-label" htmlFor="no_rt">No. Rt</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-map"></i></span>
                  <input type="text" id="no_rt" className="form-control" value={no_rt} onChange={(e) => setNoRt(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-2">
                <label className="control-label" htmlFor="kd_pos">Kode Pos</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-map"></i></span>
                  <input type="text" id="kd_pos" className="form-control" value={kd_pos} onChange={(e) => setKdPos(e.target.value)} required />
                </div>
              </div>
            </div>
            <h5 className="right-line color-success">Data Penanggung Jawab</h5>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="control-label">Nama Penanggung Jawab</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                  <input type="text" id="penanggung" className="form-control" value={penanggung} onChange={(e) => setPenanggung(e.target.value)} required />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="control-label" htmlFor="jabatan">Jabatan Penanggung Jawab</label>
                <div className="input-group">
                  <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                  <input type="text" id="jabatan" className="form-control" value={jabatan} onChange={(e) => setJabatan(e.target.value)} required />
                </div>
              </div>
            </div>
            <h5 className="right-line color-success">Captcha</h5>
            <div className="row">
              <div className="text-center col-md-12">
                <Captcha />
                <p style={alert} className="text-center">
                  {recaptcha}
                </p>
              </div>
              <div className="col-sm-12">
                <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
              </div>
            </div>
            <div className="text-center">
              <button className="btn btn-raised btn-success br-2 no-m" type="Submit"><i className="fa fa-edit"></i> Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className={pesan ? "modal-open" : ""}>
      <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog center">
          <div className="modal-content modal-sm">
            <div className="modal-header header-modal-primary p-1">
              <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                PESAN
              </h4>
            </div>
            <div className="modal-body text-center">
              <h4 className="font-bold">{msg.toUpperCase()}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    {pesan ? <div className="modal-backdrop fade in"></div> : ""}
  </>)
}