import React, { useState, useEffect } from 'react'
import axios from "axios"
import parse from 'html-react-parser'
import { useParams, Link } from "react-router-dom"
import Nav from './Nav'

const View = () => {
    const { kategori, subkategori, title } = useParams()
    const [judul, setJudul] = useState('')
    const [deskripsi, setDeskripsi] = useState('')
    const [ext, setExt] = useState('')
    const [url, setUrl] = useState('')

    useEffect(() => {
        getData(title)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (title) => {
        const category = subkategori.replaceAll('-', ' ').toUpperCase()
        const response = await axios.post("/api/publikasi/prosedur-publish", {
            kategori: category,
            key: title,
            jml: 1
        })
        const file_name_array = response.data[0].file.split(".");
        const file_extension = file_name_array[file_name_array.length - 1];
        setJudul(response.data[0].judul)
        setDeskripsi(response.data[0].deskripsi)
        setExt(file_extension)
        setUrl(response.data[0].url)
    }

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">LAYANAN DAN PENGADUAN</li>
                        <li className="breadcrumb-item active" aria-current="page">{kategori.replaceAll('-', ' ').toUpperCase()}</li>
                        <li className="breadcrumb-item active" aria-current="page">{subkategori.replaceAll('-', ' ').toUpperCase()}</li>
                        {/* <li className="breadcrumb-item active" aria-current="page">PELAYANAN</li> */}
                        <li className="breadcrumb-item active" aria-current="page">{title.replaceAll('-', ' ').toUpperCase()}</li>
                        <li className="breadcrumb-item active" aria-current="page">{title.replaceAll('-', ' ').toUpperCase()}</li>
                    </ol>
                    <div className="btn-group w-300">
                        <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {title.replaceAll('-', ' ').toUpperCase()} <i className="zmdi zmdi-chevron-down right only"></i>
                        </button>
                        <Nav getTitle={getData} />
                    </div>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <h1 className='color-dark font-bold lh-125'>{judul}</h1>
                        {parse(deskripsi)}
                        {url && ext === 'pdf' ? (<iframe className='mb-4' width="100%" height="500" src={url} title="Preview File" alt="Preview File" />) : ("")}
                        {url && ext !== 'pdf' ? (<img className='mb-4' width="100%" src={url} title="Preview File" alt="Preview File" />) : ("")}
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;