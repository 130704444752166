import React, { useEffect } from 'react'
import { useParams, Link } from "react-router-dom"
import Nav from './Nav'
import Kuesioner from './Kuesioner'
import Testimoni from './Testimoni'

const View = () => {
    const { kategori } = useParams()

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <header className="ms-hero-page-override ms-hero-img-bekasi no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">SKM</li>
                        <li className="breadcrumb-item" aria-current="page">{kategori.replaceAll('-', ' ').toUpperCase()}</li>
                    </ol>
                    <div className="btn-group w-300">
                        <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {kategori.replaceAll('-', ' ').toUpperCase()} <i className="zmdi zmdi-chevron-down right only"></i>
                        </button>
                        <Nav />
                    </div>
                </nav>
                <div className="row mt-4">
                    {kategori === 'kuesioner' ? <Kuesioner /> : null}
                    {kategori === 'testimoni' ? <Testimoni /> : null}
                </div>
            </div>
        </header>
    </>)
}

export default View;