import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import axios from "axios"
import parse from 'html-react-parser'

const View = () => {
    const [data, setData] = useState('')

    useEffect(() => {
        getData()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.get("/api/profil/publish", {})
        setData(response.data.privasi)
    }

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">KEBIJAKAN PRIVASI</li>
                    </ol>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <h1 className='color-dark font-bold lh-125'>Kebijakan Privasi</h1>
                        {parse(data)}
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;