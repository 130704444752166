import React, { useEffect } from 'react'
import { Banner } from '../Banner'
import { Pengumuman } from '../Pengumuman'
// import { Oss } from './Oss'
import { Investasi } from './Investasi'
// import { Silat } from './Silat'
import { Bighug } from './Bighug'
import { Promosi } from './Promosi'
import { Skm } from './Skm'
import { Terhubung } from './Terhubung'

function Beranda() {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="container">
                <Banner />
                <Pengumuman />
            </div>
            {/* <Oss /> */}
            <Investasi />
            {/* <Silat /> */}
            <Bighug />
            <Skm />
            <Promosi />
            <Terhubung />

        </>
    )
}

export default Beranda