import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom"
import axios from 'axios'
import parse from 'html-react-parser'
import Nav from './Nav'
import Demo from './Demografi'
import Geo from './Geografis'

const View = () => {
    const { kategori } = useParams()
    const [demografi, setDemografi] = useState('')
    const [geografis, setGeografis] = useState('')

    useEffect(() => {
        getProfil()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProfil = async () => {
        const response = await axios.get('/api/profil/publish')
        setDemografi(response.data.demografi)
        setGeografis(response.data.geografis)
    }

    const handleClick = () => {
        const element = document.getElementById('section-1');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (<>
        <header className="ms-hero-page-override ms-hero-img-bekasi no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">MENGAPA KOTA BEKASI</li>
                        <li className="breadcrumb-item" aria-current="page">{kategori.replaceAll('-', ' ').toUpperCase()}</li>
                    </ol>
                    <div className="btn-group w-300">
                        <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {kategori.replaceAll('-', ' ').toUpperCase()} <i className="zmdi zmdi-chevron-down right only"></i>
                        </button>
                        <Nav />
                    </div>
                </nav>
                <div className="row">
                    {kategori === 'demografi' ? <Demo handleClick={handleClick} /> : null}
                    {kategori === 'geografis' ? <Geo handleClick={handleClick} /> : null}
                </div>
            </div>
        </header>
        <div id="section-1" className="col-lg-12">
            <div className="row fail">
                <div className="col-lg-2 col-md-2 col-sm-2"></div>
                <div className="col-lg-8 col-md-8 col-sm-10 ">
                    <div className="card-demo animated zoomInUp animation-delay-3">
                        <div className="card-body mt-1 text-center">
                            <h1 className='color-dark font-bold lh-125'>{kategori.replaceAll('-', ' ').toUpperCase()}</h1>
                            {/* <h3 className='text-dark lh-125 mb-75 '>On the consumer side, Indonesia has 273 million of population (2021) and is a home for 64 million of middle affluent class, 1.7x total population of Canada (2018). It is also the gateway to 650 million of population in Southeast Asia, the world’s 3rd largest market. The country is also a production base for export with FTAs with 22 countries, including the largest trading bloc, RCEP.</h3> */}
                            {kategori === 'demografi' ? parse(demografi) : null}
                            {kategori === 'geografis' ? parse(geografis) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default View;