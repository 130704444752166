import React, { useState, useEffect } from "react"
import axios from 'axios'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const Banner = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post("/api/tautan/publish", {
            kategori: ['SLIDER'],
            jml: 5
        })
        setData(response.data)
    }

    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <>
            <Slider {...settings}>
                {data.map((val, index) => (
                    <div key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={val.tautan}>
                            <img className="d-block img-fluid" src={val.url} alt={val.judul} />
                        </a>
                    </div>
                ))}
            </Slider>
        </>
    )
}
