import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios'

const Nav = ({getKategori}) => {
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post("/api/kategori-usaha/publish", {})
        setData(response.data)
    }

    return (<>
        <ul className="dropdown-menu bg-primary w-300 pb-15">
            <li className="dropdown-header">
                <h4 className='color-warning'>Kategori Usaha</h4>
            </li>
            {data.map((val, i) => (
                <React.Fragment key={i}>
                    <li key={val.categoryName} role="separator" className="dropdown-divider"></li>
                    <li key={i}>
                        <Link to={`/kegiatan-usaha/${val.categoryName.replaceAll(' ', '-').replaceAll('/', '_')}`} className='dropdown-item' onClick={() => getKategori(val.categoryName, '')}>
                            {val.categoryName.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                        </Link>
                    </li>
                </React.Fragment>
            ))}
        </ul>
    </>)
}

export default Nav;