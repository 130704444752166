import React from 'react'

const View = ({handleClick}) => {

    return (<>
        <div className="row no-shadow">
            <div className="col-lg-12">
                <div className="row fail">
                    <div className="col-lg-3 col-md-3 col-sm-2"></div>
                    <div className="col-lg-6 col-md-6 col-sm-10 ">
                        <div className="card-banner animated zoomInUp animation-delay-3">
                            <div className="card-body mt-1 text-center">
                                <h1 className='color-white font-bold lh-125 no-m'>Demografi</h1>
                                <h3 className='text-white lh-125 mb-75 '>Dalam Sensus Penduduk (SP) 2020 tercatat jumlah penduduk di Kota Bekasi sebanyak 2,54 juta jiwa, bertambah 208 ribu jiwa dari hasil SP 2010. Rinciannya, sebanyak 1,28 juta jiwa penduduk berjenis kelamin laki-laki dan 1,26 juta jiwa berjenis kelamin perempuan.</h3>
                                <div className="lh-125">
                                    <button onClick={handleClick} className="btn btn-raised btn-primary br-2">Selanjutnya</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default View;