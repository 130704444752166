import React, { useState, useEffect } from 'react'
import axios from 'axios'

function Panduan() {
    const alert = {
        position: "relative",
        top: "7px",
        backgroundColor: "#e5df7c",
        color: "#333",
    }
    const [panduan, setPanduan] = useState([])
    const [msg, setMsg] = useState("")
    const [title, setTitle] = useState('')
    const [preview, setPreview] = useState('')
    const [closePreview, setClosePreview] = useState('')

    useEffect(() => {
        getPanduan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPanduan = async (filter) => {
        try {
            const response = await axios.post('/api/panduan/publish', {
                key: filter,
                jml: 50 || 10,
            })
            setPanduan(response.data)
            setPreview('')
            setClosePreview('')
        } catch (err) {
            setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
            setPreview('')
            setClosePreview('')
            setTimeout(() => {
                setMsg("")
            }, 2000)
        }
    }

    const getPreview = async (key) => {
        const response = await axios.get(`/api/panduan/${key}`)
        setPreview(response.data.url)
        setTitle(response.data.judul)
        setClosePreview('<a onClick={getClosePreview} className="btn btn btn-raised btn-danger br-2 btn-close-card pl-2 pr-2 no-m"><i className="fa fa-refresh"></i> Tutup</a>')
    }

    const getClosePreview = () => {
        setPreview('')
        setClosePreview('')
    }

    return (<>
        <div className="row mt-4">
            <div className="container">
                <div className="card animated fadeInUp animation-delay-7 br-2 mb-6">
                    <div className="card-body p-5">
                        <div className="text-justify">
                            <p style={alert} className="text-center">{msg}</p>
                            <div className="row">
                                <div className="col-md-5">
                                    <input className="form-control" placeholder="Pencarian" onChange={(e) => getPanduan(e.target.value)} />
                                    {panduan.map((val) => (<>
                                        <div onClick={() => getPreview(val._id)} className="card card-primary border-radius-10">
                                            <div className="card-header border-radius-10">
                                                <i className="zmdi zmdi-attachment mr-1"></i>{val.judul}
                                            </div>
                                        </div>
                                    </>))}
                                </div>
                                <div className="col-md-7">
                                    {preview ? (<div className="card card-success border-top-10"><div className="card-header border-top-10"><h3 className="card-title"><i className="zmdi zmdi-widgets"></i> {title}</h3></div><div className="card-body border-bottom-10 animated slideInUp animation-delay-2 no-p"><iframe width="100%" height="400" src={preview} title="Preview File" alt="Preview File" /></div></div>) : ("")}
                                    {closePreview ? (<p onClick={getClosePreview} className="btn btn btn-raised btn-danger br-2 btn-close-card pl-2 pr-2 no-m"><i className="fa fa-refresh"></i> Tutup</p>) : ("")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Panduan