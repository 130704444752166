import React, { useState, useEffect, useMemo } from "react"
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useNavigate } from "react-router-dom"
import parse from 'html-react-parser'
import Pagination from '../../pages/paginations'
import { Add } from './Add'
import { Edit } from './Edit'

function View() {
    const [msg, setMsg] = useState("")
    const [pesan, setPesan] = useState(false)
    const [token, setToken] = useState("")
    const [expired, setExpired] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(10)
    const [data, setData] = useState([])
    const [add, setAdd] = useState(false)
    const [rowID, setRowID] = useState(false)
    const [edit, setEdit] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        updateToken()
        getData('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateToken = async () => {
        try {
            const response = await axios.get('/api/pelaku-usaha/token')
            const decode = jwt_decode(response.data.authToken)
            setToken(response.data.authToken)
            setExpired(decode.exp)
        } catch (err) {
            if (err.response) {
                navigate('/login')
            }
        }
    }

    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(
        async (config) => {
            const currentDate = new Date()
            if (expired * 1000 < currentDate.getTime()) {
                const response = await axios.get("/api/pelaku-usaha/token")
                config.headers.Authorization = `Bearer ${response.data.authToken}`
                setToken(response.data.authToken)
                const decode = jwt_decode(response.data.authToken)
                setExpired(decode.exp)
            }
            return config
        },
        (err) => {
            return Promise.reject(err)
        }
    )

    const getData = async (key) => {
        try {
            const access = await axios.get('/api/pelaku-usaha/token')
            const decode = jwt_decode(access.data.authToken)
            const response = await axiosJWT.post("/api/promotion/user", {
                user: decode.id,
                key: key
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setData(response.data)
            setCurrentPage(1)
        } catch (err) {
            getPesan()
            setMsg(err.response.data.msg ? err.response.data.msg : 'Data tidak terkirim, mohon coba lagi!')
            setTimeout(() => {
                getPesan()
                setMsg("")
            }, 2000)
        }
    }

    const proses = async (id) => {
        try {
            const hapus = await axiosJWT.delete(`/api/promotion/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            getPesan()
            setMsg(hapus.data.msg)
            setTimeout(() => {
                getData()
            }, 1000)
            setTimeout(() => {
                getPesan()
                setMsg("")
            }, 2000)
        } catch (err) {
            getPesan()
            setMsg(err.response.data.msg ? err.response.data.msg : 'Data tidak terkirim, mohon coba lagi!')
            setTimeout(() => {
                getPesan()
                setMsg("")
            }, 2000)
        }
    }

    const nonaktif = async (id) => {
        try {
            const response = await axiosJWT.put(`/api/promotion/${id}`, {
                status: '3'
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            getPesan()
            setMsg(response.data.msg)
            setTimeout(() => {
                getData()
            }, 1000)
            setTimeout(() => {
                getPesan()
                setMsg("")
            }, 2000)
        } catch (err) {
            getPesan()
            setMsg(err.response.data.msg ? err.response.data.msg : 'Data tidak terkirim, mohon coba lagi!')
            setTimeout(() => {
                getPesan()
                setMsg("")
            }, 2000)
        }
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage, PageSize])

    const getPesan = () => {
        setPesan((prevState) => !prevState)
    }

    const display = {
        display: `${pesan ? "block" : "none"}`,
    }

    const openAdd = async () => {
        getAdd()
    }

    const closeAdd = () => {
        getAdd()
    }

    const getAdd = () => {
        setAdd((prevState) => !prevState)
    }

    const displayAdd = {
        display: `${add ? "block" : "none"}`
    }

    const openEdit = async (id) => {
        setRowID(id)
        getEdit()
    }

    const closeEdit = () => {
        setRowID(null)
        getEdit()
    }

    const getEdit = () => {
        setEdit((prevState) => !prevState)
    }

    const displayEdit = {
        display: `${edit ? "block" : "none"}`
    }

    return (<>
        <div className="container">
            <div className="card animated fadeInUp animation-delay-7 br-2 mb-6">
                <div className="card-body p-5">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-2 col-xs-4">
                                <button onClick={openAdd} className="btn btn btn-raised btn-primary br-2 no-m">
                                    <i className="fa fa-plus"></i> Tambah
                                </button>
                            </div>
                            <div className="col-md-10 col-xs-8">
                                <input type="text" className="form-control" placeholder="Masukkan Kata Kunci" onChange={(e) => getData(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-nomargin dataTable table-bordered data">
                            <thead>
                                <tr className="purple">
                                    <th>No</th>
                                    <th>Kd. Permohonan</th>
                                    <th>Judul</th>
                                    <th>Deskripsi</th>
                                    <th>Status</th>
                                    <th>Proses</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((row, i) => (
                                    <tr key={row._id}>
                                        <td width={"5%"}>{i + 1}</td>
                                        <td width={"12%"}>{row.code}</td>
                                        <td width={"20%"} className="text-left">{row.title}</td>
                                        <td width={"38%"} className="text-left"><span className="badge badge-primary">{row.potensialName}</span><br></br>{parse(row.description)}</td>
                                        <td width={"20%"} className="text-left">
                                            Status:&nbsp;
                                            {row.status === '0' ? <span className="badge badge-success">Verifikasi</span> : ''}
                                            {row.status === '1' ? <span className="badge badge-warning">Pending (Lihat Keterangan)</span> : ''}
                                            {row.status === '2' ? <span className="badge badge-primary">Disetujui</span> : ''}
                                            {row.information && row.information !== 'undefined' ? parse(row.information) : null}
                                        </td>
                                        <td width={"5%"} className="text-center">
                                            {row.status === '0' || row.status === '1' ? (<>
                                                <button onClick={() => openEdit(row._id)} className="btn btn-raised btn-success br-2 no-m">
                                                    <i className="fa fa-pencil no-m"></i>
                                                </button>
                                            </>) : null}
                                            {row.status === '0' ? (<>
                                                <button onClick={() => proses(row._id)} className="btn btn-raised btn-danger br-2 mt-1">
                                                    <i className="fa fa-remove no-m"></i>
                                                </button>
                                            </>) : (<>
                                                <button onClick={() => nonaktif(row._id)} className="btn btn-raised btn-warning br-2 mt-1">
                                                    <i className="fa fa-refresh no-m"></i>
                                                </button>
                                            </>)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-2 col-xs-6">
                                Ditemukan <b>{data.length}</b> Data
                            </div>
                            <div className="col-md-10 col-xs-6">
                                <Pagination className="pagination-bar text-right" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <b>Catatan:</b><br></br>
                            <span className="badge badge-danger ml-1">Pastikan kembali data yang dikirim, Status Promosi yang telah AKTIF tidak dapat di EDIT dan di HAPUS.</span><br></br>
                            <span className="badge badge-danger ml-1">Untuk menonaktifkan data Promosi silahkan klik Tombol berwarna kuning (Nonaktif).</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {add ? (<>
            <div className={add ? ("modal-open") : ("")}>
                <div style={displayAdd} className={add ? 'modal modal-primary show' : 'modal modal-primary'} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog review">
                        <div className="modal-content modal-md">
                            <div className="modal-header header-modal-primary p-1">
                                <h4 className="modal-title text-center" id="myModalLabel">Tambah Promosi</h4>
                            </div>
                            <div className="modal-body">
                                <Add getData={getData} closeAdd={closeAdd} getPesan={getPesan} setMsg={setMsg} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>) : ("")}
        {edit ? (<>
            <div className={edit ? ("modal-open") : ("")}>
                <div style={displayEdit} className={edit ? 'modal modal-primary show' : 'modal modal-primary'} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog review">
                        <div className="modal-content modal-xl">
                            <div className="modal-header header-modal-primary p-1">
                                <h4 className="modal-title text-center" id="myModalLabel">Edit Promosi</h4>
                            </div>
                            <div className="modal-body">
                                <Edit getData={getData} rowID={rowID} closeEdit={closeEdit} getPesan={getPesan} setMsg={setMsg} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>) : ("")}
        <div className={pesan ? "modal-open" : ""}>
            <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog center">
                    <div className="modal-content modal-sm">
                        <div className="modal-header header-modal-primary p-1">
                            <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                                PESAN
                            </h4>
                        </div>
                        <div className="modal-body text-center">
                            <h4 className="font-bold">{msg.toUpperCase()}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {pesan ? <div className="modal-backdrop fade in"></div> : ""}
    </>)
}

export default View