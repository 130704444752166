import React from 'react'
import { Link } from "react-router-dom"

const View = () => {

    return (<>
        <div className="row mt-4 no-shadow">
            <div className="col-lg-12">
                <div className="row fail">
                    <div className="col-lg-3 col-md-3 col-sm-2"></div>
                    <div className="col-lg-6 col-md-6 col-sm-10 ">
                        <div className="card card-banner animated zoomInUp animation-delay-3">
                            <div className="card-body mt-1 text-center">
                                <h1 className='color-success font-bold lh-125 no-m'>Peraturan dan Perundang-Undangan</h1>
                                <hr></hr>
                                <h4 className='text-dark lh-125'>Silakan kunjungi situs web di bawah ini untuk mempelajari lebih lanjut tentang semua hukum dan peraturan yang berlaku terkait dengan investasi Anda di Kota Bekasi.</h4>
                                <hr></hr>
                                <div className="flex-card">
                                    <div className="lh-125 margin-auto">
                                        <Link to={'/layanan-dan-pengaduan/prosedur/peraturan/produk-hukum/undang-undang'} className="btn btn-raised btn-primary br-2">Undang-Undang dan Produk Hukum</Link>
                                    </div>
                                    <div className="lh-125 margin-auto">
                                        <a href="https://oss.go.id/en/regulasi" target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-primary br-2">Regulasi Kunjungi OSS</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default View;