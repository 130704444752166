import React from 'react'
import { Link } from 'react-router-dom'

function Register() {

  return (
    <>
      <div className="bg-full-page bg-primary back-fixed">
        <div className="mw-800 absolute-center">
          <div className="card color-dark animated zoomInDown animation-delay-5 border-radius-20">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-8 col-6">
                  <h3 className="color-success">Pendaftaran Akun</h3>
                </div>
                <div className="col-md-4 col-6">
                  <img width="100%" src="assets/img/logo-head.png" alt="" />
                </div>
                <div className="col-md-12">
                  <img width="100%" className="line-process" src="assets/img/line.png" alt="" />
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className="btn-circle btn-circle-raised btn-circle-success">1</button>
                  <br></br>Jenis Usaha
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className="btn-circle btn-circle-raised btn-circle-default">2</button>
                  <br></br>Data Pengguna Aplikasi
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className="btn-circle btn-circle-raised btn-circle-default">3</button>
                  <br></br>Profil Pelaku Usaha
                </div>
                <div className="col-md-12 col-12 text-center">
                  <span className="badge badge-danger">Pastikan menggunakan email aktif ketika mendaftarkan akun (<b>Link Aktivasi Akun kami kirim melalui email</b>)</span><br></br>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6 col-6">
                  <Link to={'/registrasi-perorangan'} className="btn btn-raised btn-primary btn-block br-2"><i className="fa fa-user mr-1"></i> PERORANGAN</Link>
                </div>
                <div className="col-md-6 col-6">
                  <Link to={'/registrasi-badan-usaha'} className="btn btn-raised btn-primary btn-block br-2"><i className="fa fa-building mr-1"></i> BADAN USAHA</Link>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-6 d-block">
                  <Link to={'/'} className="btn btn-raised btn-default btn-block p-1 br-2"><i className="zmdi zmdi-home no-ml mr-1"></i>BERANDA</Link>
                </div>
                <div className="col-md-6 col-6 d-block">
                  <Link to={'/reset'} className="btn btn-raised btn-warning btn-block p-1 br-2"><i className="zmdi zmdi-key mr-1"></i> RESET PASWORD</Link>
                </div>
                <div className="col-md-12 text-center mt-2">
                  Sudah punya akun? <Link className="color-success" to={'/login'}>Masuk</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register