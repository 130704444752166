import React from 'react'

const View = () => {

    return (<>
        <div className="row mt-4 no-shadow">
            <div className="col-lg-12">
                <div className="row fail center">
                    <div className="col-lg-3 col-md-3 col-sm-2"></div>
                    <div className="col-lg-10 col-md-10 col-sm-10 ">
                        <div className="card card-banner animated zoomInUp animation-delay-3 mb-75">
                            <div className="card-body mt-1 text-center">
                                <h1 className='color-success font-bold lh-125 no-m'>Memulai Bisnis</h1>
                                <hr></hr>
                                <div className="flex-card">
                                    <div className="card-body mt-1 text-center">
                                        <h3 className='text-dark font-bold lh-125'>OSS</h3>
                                        <h4 className='text-dark lh-125'>Pemerintah Indonesia menyederhanakan cara memulai bisnis Anda di Indonesia menggunakan satu sistem tunggal. Silakan kunjungi website Online Single Submission untuk memulai bisnis Anda di Indonesia.</h4>
                                        <div className="lh-125">
                                            <a href={'https://oss.go.id'} target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-primary br-2">Kunjungi OSS</a>
                                        </div>
                                    </div>
                                    <div className="border-line"></div>
                                    <div className="card-body mt-1 text-center">
                                        <h3 className='text-dark font-bold lh-125'>Silat V.2</h3>
                                        <h4 className='text-dark lh-125'>Silat V.2 (Sistem Informasi Layanan Terpadu) adalah platform aplikasi DPMPTSP Kota Bekasi yang merupakan media layanan dari kami untuk Masyarakat Kota Bekasi dalam mengajukan permohonan perizinan berusaha dan Non-berusaha.</h4>
                                        <div className="lh-125">
                                            <a href={'https://silat.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-primary br-2">Kunjungi Silat V2</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>)
}

export default View;