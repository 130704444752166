import React, { useState, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Captcha from '../../Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'

export const Perorangan = () => {
    const [pesan, setPesan] = useState(false)
    const [msg, setMsg] = useState("")

    const [token, setToken] = useState("")
    const [expired, setExpired] = useState("")
    const [id, setID] = useState("")
    const [nik, setNIK] = useState('')
    const [no_npwp, setNPWP] = useState('')
    const [tmpt_lahir, setTmptLahir] = useState('')
    const [tgl_lahir, setTglLahir] = useState('')
    const [nm_lengkap, setNmLengkap] = useState('')
    const [no_prop, setNoProp] = useState('')
    const [no_kab, setNoKab] = useState('')
    const [no_kec, setNoKec] = useState('')
    const [no_kel, setNoKel] = useState('')
    const [no_rw, setNoRw] = useState('')
    const [no_rt, setNoRt] = useState('')
    const [alamat, setAlamat] = useState('')
    const [kd_pos, setKdPos] = useState('')
    const [no_prop_domisili, setNoPropDomisili] = useState('')
    const [no_kab_domisili, setNoKabDomisili] = useState('')
    const [no_kec_domisili, setNoKecDomisili] = useState('')
    const [no_kel_domisili, setNoKelDomisili] = useState('')
    const [no_rw_domisili, setNoRwDomisili] = useState('')
    const [no_rt_domisili, setNoRtDomisili] = useState('')
    const [alamat_domisili, setAlamatDomisili] = useState('')
    const [kd_pos_domisili, setKdPosDomisili] = useState('')
    const [captcha, setCaptcha] = useState("")
    const [recaptcha, setReCaptcha] = useState("")
    const [propinsi, setPropinsi] = useState([])
    const [kabupaten, setKabupaten] = useState([])
    const [kecamatan, setKecamatan] = useState([])
    const [kelurahan, setKelurahan] = useState([])
    const [kabupaten_domisili, setKabupatenDomisili] = useState([])
    const [kecamatan_domisili, setKecamatanDomisili] = useState([])
    const [kelurahan_domisili, setKelurahanDomisili] = useState([])
    const navigate = useNavigate()
    const alert = {
        position: "relative",
        top: "7px",
        backgroundColor: "#e5df7c",
        color: "#333",
    }

    useEffect(() => {
        updateToken()
        getAkun()
        getPropinsi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateToken = async () => {
        try {
            const response = await axios.get('/api/pelaku-usaha/token')
            setToken(response.data.authToken)
            const decode = jwt_decode(response.data.authToken)
            setExpired(decode.exp)
            setID(decode.id)
            setNIK(decode.nik)
        } catch (err) {
            if (err.response) {
                navigate('/')
            }
        }
    }

    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expired * 1000 < currentDate.getTime()) {
            const response = await axios.get('/api/pelaku-usaha/token')
            config.headers.Authorization = `Bearer ${response.data.authToken}`
            setToken(response.data.authToken)
            const decode = jwt_decode(response.data.authToken)
            setID(decode.id)
            setNIK(decode.nik)
            setExpired(decode.exp)
        }
        return config
    }, (err) => {
        return Promise.reject(err)
    })

    const getPropinsi = async () => {
        const response = await axios.post('/api/wilayah/propinsi')
        setPropinsi(response.data)
    }

    const getKabupaten = async (key) => {
        try {
            const response = await axios.post(`/api/wilayah/kabupaten`, {
                no_prop: key
            })
            setKabupaten(response.data)
            setNoKab('')
            setNoProp(key)
        } catch {
            setKabupaten([])
            setNoKab("")
            setNoProp(key)
        }
    }

    const getKecamatan = async (key) => {
        try {
            const response = await axios.post(`/api/wilayah/kecamatan`, {
                no_prop,
                no_kab: key
            })
            setKecamatan(response.data)
            setNoKec('')
            setNoKab(key)
        } catch {
            setKecamatan([])
            setNoKec("")
            setNoKab(key)
        }
    }

    const getKelurahan = async (key) => {
        try {
            const response = await axios.post(`/api/wilayah/kelurahan`, {
                no_prop,
                no_kab,
                no_kec: key
            })
            setKelurahan(response.data)
            setNoKel('')
            setNoKec(key)
        } catch {
            setKelurahan([])
            setNoKel("")
            setNoKec(key)
        }
    }

    const getKabupatenDomisili = async (key) => {
        try {
            const response = await axios.post(`/api/wilayah/kabupaten`, {
                no_prop: key
            })
            setKabupatenDomisili(response.data)
            setNoKabDomisili('')
            setNoPropDomisili(key)
        } catch {
            setKabupatenDomisili([])
            setNoKabDomisili("")
            setNoPropDomisili(key)
        }
    }

    const getKecamatanDomisili = async (key) => {
        try {
            const response = await axios.post(`/api/wilayah/kecamatan`, {
                no_prop: no_prop_domisili,
                no_kab: key
            })
            setKecamatanDomisili(response.data)
            setNoKecDomisili('')
            setNoKabDomisili(key)
        } catch {
            setKecamatanDomisili([])
            setNoKecDomisili("")
            setNoKabDomisili(key)
        }
    }

    const getKelurahanDomisili = async (key) => {
        try {
            const response = await axios.post(`/api/wilayah/kelurahan`, {
                no_prop: no_prop_domisili,
                no_kab: no_kab_domisili,
                no_kec: key
            })
            setKelurahanDomisili(response.data)
            setNoKelDomisili('')
            setNoKecDomisili(key)
        } catch {
            setKelurahanDomisili([])
            setNoKelDomisili("")
            setNoKecDomisili(key)
        }
    }

    const getAkun = async () => {
        const params = await axios.get('/api/pelaku-usaha/token')
        const decode = jwt_decode(params.data.authToken)
        const response = await axios.get(`/api/perorangan/publish/${decode.id}`, {
            headers: {
                Authorization: `Bearer ${params.data.authToken}`
            }
        })
        // KTP
        const kabupaten = await axios.post(`/api/wilayah/kabupaten`, {
            no_prop: response.data.no_prop,
            no_kab: response.data.no_kab
        })
        setKabupaten(kabupaten.data)
        const kecamatan = await axios.post(`/api/wilayah/kecamatan`, {
            no_prop: response.data.no_prop,
            no_kab: response.data.no_kab,
            no_kec: response.data.no_kec
        })
        setKecamatan(kecamatan.data)
        const kelurahan = await axios.post(`/api/wilayah/kelurahan`, {
            no_prop: response.data.no_prop,
            no_kab: response.data.no_kab,
            no_kec: response.data.no_kec,
            no_kel: response.data.no_kel
        })
        setKelurahan(kelurahan.data)
        // End KTP
        // Domisili
        const kabupatenDom = await axios.post(`/api/wilayah/kabupaten`, {
            no_prop: response.data.no_prop_domisili,
            no_kab: response.data.no_kab_domisili
        })
        setKabupatenDomisili(kabupatenDom.data)
        const kecamatanDom = await axios.post(`/api/wilayah/kecamatan`, {
            no_prop: response.data.no_prop_domisili,
            no_kab: response.data.no_kab_domisili,
            no_kec: response.data.no_kec_domisili
        })
        setKecamatanDomisili(kecamatanDom.data)
        const kelurahanDom = await axios.post(`/api/wilayah/kelurahan`, {
            no_prop: response.data.no_prop_domisili,
            no_kab: response.data.no_kab_domisili,
            no_kec: response.data.no_kec_domisili,
            no_kel: response.data.no_kel_domisili
        })
        setKelurahanDomisili(kelurahanDom.data)
        // End Domisili
        setNPWP(response.data.no_npwp)
        setNmLengkap(response.data.nm_lengkap)
        setTmptLahir(response.data.tmpt_lahir)
        setTglLahir(response.data.tgl_lahir)
        setNoProp(response.data.no_prop)
        setNoKab(response.data.no_kab)
        setNoKec(response.data.no_kec)
        setNoKel(response.data.no_kel)
        setNoRw(response.data.no_rw)
        setNoRt(response.data.no_rt)
        setAlamat(response.data.alamat)
        setKdPos(response.data.kd_pos)
        setNoPropDomisili(response.data.no_prop_domisili)
        setNoKabDomisili(response.data.no_kab_domisili)
        setNoKecDomisili(response.data.no_kec_domisili)
        setNoKelDomisili(response.data.no_kel_domisili)
        setNoRwDomisili(response.data.no_rw_domisili)
        setNoRtDomisili(response.data.no_rt_domisili)
        setAlamatDomisili(response.data.alamat_domisili)
        setKdPosDomisili(response.data.kd_pos_domisili)
    }

    const prosesAkun = async (e) => {
        e.preventDefault()
        if (validateCaptcha(captcha) === false) {
            setReCaptcha("Captcha tidak sesuai!")
            setTimeout(() => {
                setReCaptcha("")
            }, 2000)
            setCaptcha("")
        } else {
            const params = await axios.get('/api/pelaku-usaha/token')
            const decode = jwt_decode(params.data.authToken)
            const response = await axios.get(`/api/perorangan/publish/${decode.id}`, {
                headers: {
                    Authorization: `Bearer ${params.data.authToken}`
                }
            })
            if (response.data) {
                editAkun()
            } else {
                simpanAkun()
            }
        }
    }

    const simpanAkun = async () => {
        try {
            const response = await axiosJWT.post('/api/perorangan', {
                token: id, nik, no_npwp, tmpt_lahir, tgl_lahir, nm_lengkap, no_prop, no_kab, no_kec, no_kel, no_rw, no_rt, alamat, kd_pos, no_prop_domisili, no_kab_domisili,
                no_kec_domisili, no_kel_domisili, no_rw_domisili, no_rt_domisili, alamat_domisili, kd_pos_domisili,
                i_user: id,
                i_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
                u_user: id,
                u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            await axiosJWT.put(`/api/pelaku-usaha/akun/${id}`, {
                nm_lengkap,
                u_user: id,
                u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br')
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            getPesan()
            setMsg(response.data.msg)
            loadCaptchaEnginge(6)
            setCaptcha("")
            setTimeout(() => {
                getPesan()
                setMsg('')
            }, 2000)
        } catch (err) {
            getPesan()
            setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
            setTimeout(() => {
                getPesan()
                setMsg('')
            }, 2000)
        }
    }

    const editAkun = async () => {
        try {
            const response = await axiosJWT.put(`/api/perorangan/${id}`, {
                no_npwp, tmpt_lahir, tgl_lahir, nm_lengkap, no_prop, no_kab, no_kec, no_kel, no_rw, no_rt, alamat, kd_pos, no_prop_domisili, no_kab_domisili,
                no_kec_domisili, no_kel_domisili, no_rw_domisili, no_rt_domisili, alamat_domisili, kd_pos_domisili,
                u_user: id,
                u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            await axiosJWT.put(`/api/pelaku-usaha/akun/${id}`, {
                nm_lengkap,
                u_user: id,
                u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br')
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            getPesan()
            setMsg(response.data.msg)
            loadCaptchaEnginge(6)
            setCaptcha("")
            setTimeout(() => {
                getPesan()
                setMsg('')
            }, 2000)
        } catch (err) {
            getPesan()
            setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
            setTimeout(() => {
                getPesan()
                setMsg('')
            }, 2000)
        }
    }

    const getPesan = () => {
        setPesan((prevState) => !prevState)
    }

    const display = {
        display: `${pesan ? "block" : "none"}`,
    }

    return (<>
        <div className="container">
            <div className="card animated fadeInUp animation-delay-7 br-2 mb-6">
                <div className="card-body p-5">
                    <form onSubmit={prosesAkun} className="form-horizontal">
                        <h5 className="right-line color-success">Data Pelaku Usaha</h5>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="control-label">NIK</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                                    <input type="text" className="form-control" value={nik} readOnly />
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label" htmlFor="no_npwp">No. NPWP</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                                    <input type="text" id="no_npwp" className="form-control" value={no_npwp} onChange={(e) => setNPWP(e.target.value)} required />
                                </div>
                                {/* <span className='badge badge-danger'>Untuk perubahan NPWP hubungi Admnistrator melalui fitur chatting</span> */}
                            </div>
                            <div className="form-group col-md-12">
                                <label className="control-label">Nama Lengkap</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-id-card"></i></span>
                                    <input type="text" className="form-control" value={nm_lengkap} onChange={(e) => setNmLengkap(e.target.value)} required />
                                </div>
                                {/* <span className='badge badge-danger'>Untuk perubahan Nama Lengkap hubungi Admnistrator melalui fitur chatting</span> */}
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label" htmlFor="tmpt_lahir">Tempat Lahir</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="tmpt_lahir" className="form-control" value={tmpt_lahir} onChange={(e) => setTmptLahir(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label" htmlFor="tgl_lahir">Tanggal Lahir</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-calendar"></i></span>
                                    <input type="date" id="tgl_lahir" className="form-control" value={tgl_lahir} onChange={(e) => setTglLahir(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <h5 className="right-line color-success">Alamat KTP</h5>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="control-label">Propinsi</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <select className="form-control" value={no_prop} onChange={(e) => getKabupaten(e.target.value)} required>
                                        <option value="">==</option>
                                        {propinsi.map((val, i) => (
                                            <option key={i} value={val.no_prop}>{i + 1 + '. ' + val.nama_prop}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label">Kabupaten</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <select className="form-control" value={no_kab} onChange={(e) => getKecamatan(e.target.value)} required>
                                        <option value="">==</option>
                                        {kabupaten.map((val, i) => (
                                            <option key={i} value={val.no_kab}>{i + 1 + '. ' + val.nama_kab}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label">Kecamatan</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <select className="form-control" value={no_kec} onChange={(e) => getKelurahan(e.target.value)} required>
                                        <option value="">==</option>
                                        {kecamatan.map((val, i) => (
                                            <option key={i} value={val.no_kec}>{i + 1 + '. ' + val.nama_kec}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label">Kelurahan</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <select className="form-control" value={no_kel} onChange={(e) => setNoKel(e.target.value)} required>
                                        <option value="">==</option>
                                        {kelurahan.map((val, i) => (
                                            <option key={i} value={val.no_kel}>{i + 1 + '. ' + val.nama_kel}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label" htmlFor="alamat">Alamat</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="alamat" className="form-control" value={alamat} onChange={(e) => setAlamat(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <label className="control-label" htmlFor="no_rw">No. Rw</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="no_rw" className="form-control" value={no_rw} onChange={(e) => setNoRw(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <label className="control-label" htmlFor="no_rt">No. Rt</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="no_rt" className="form-control" value={no_rt} onChange={(e) => setNoRt(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <label className="control-label" htmlFor="kd_pos">Kode Pos</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="kd_pos" className="form-control" value={kd_pos} onChange={(e) => setKdPos(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <h5 className="right-line color-success">Alamat Domisili</h5>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="control-label">Propinsi</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <select className="form-control" value={no_prop_domisili} onChange={(e) => getKabupatenDomisili(e.target.value)} required>
                                        <option value="">==</option>
                                        {propinsi.map((val, i) => (
                                            <option key={i} value={val.no_prop}>{i + 1 + '. ' + val.nama_prop}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label">Kabupaten</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <select className="form-control" value={no_kab_domisili} onChange={(e) => getKecamatanDomisili(e.target.value)} required>
                                        <option value="">==</option>
                                        {kabupaten_domisili.map((val, i) => (
                                            <option key={i} value={val.no_kab}>{i + 1 + '. ' + val.nama_kab}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label">Kecamatan</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <select className="form-control" value={no_kec_domisili} onChange={(e) => getKelurahanDomisili(e.target.value)} required>
                                        <option value="">==</option>
                                        {kecamatan_domisili.map((val, i) => (
                                            <option key={i} value={val.no_kec}>{i + 1 + '. ' + val.nama_kec}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label">Kelurahan</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <select className="form-control" value={no_kel_domisili} onChange={(e) => setNoKelDomisili(e.target.value)} required>
                                        <option value="">==</option>
                                        {kelurahan_domisili.map((val, i) => (
                                            <option key={i} value={val.no_kel}>{i + 1 + '. ' + val.nama_kel}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label" htmlFor="alamat_domisili">Alamat</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="alamat_domisili" className="form-control" value={alamat_domisili} onChange={(e) => setAlamatDomisili(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <label className="control-label" htmlFor="no_rw_domisili">No. Rw</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="no_rw_domisili" className="form-control" value={no_rw_domisili} onChange={(e) => setNoRwDomisili(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <label className="control-label" htmlFor="no_rt_domisili">No. Rt</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="no_rt_domisili" className="form-control" value={no_rt_domisili} onChange={(e) => setNoRtDomisili(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <label className="control-label" htmlFor="kd_pos_domisili">Kode Pos</label>
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="fa fa-map"></i></span>
                                    <input type="text" id="kd_pos_domisili" className="form-control" value={kd_pos_domisili} onChange={(e) => setKdPosDomisili(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <h5 className="right-line color-success">Captcha</h5>
                        <div className="row">
                            <div className="text-center col-md-12">
                                <Captcha />
                                <p style={alert} className="text-center">
                                    {recaptcha}
                                </p>
                            </div>
                            <div className="col-sm-12">
                                <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
                            </div>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-raised btn-success br-2 no-m" type="Submit"><i className="fa fa-edit"></i> Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className={pesan ? "modal-open" : ""}>
            <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog center">
                    <div className="modal-content modal-sm">
                        <div className="modal-header header-modal-primary p-1">
                            <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                                PESAN
                            </h4>
                        </div>
                        <div className="modal-body text-center">
                            <h4 className="font-bold">{msg.toUpperCase()}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {pesan ? <div className="modal-backdrop fade in"></div> : ""}
    </>)
}