import React, { useState, useEffect, useMemo } from 'react'
import axios from "axios"
import { Link } from "react-router-dom"
import { TanggalHari } from '../Utils'
import Pagination from '../pages/paginations'

const View = ({kategori}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(10)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (val) => {
        const category = kategori.replaceAll('-', ' ').toUpperCase()
        const response = await axios.post("/api/publikasi/publish", {
            keyword: val || '',
            kategori: category,
            jml: 100
        })
        setData(response.data)
        setCurrentPage(1)
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage])

    return (<>
        <div className="row">
            <div className="col-md-12">
                <input type="text" className="form-control" placeholder="Masukkan Kata Kunci" onChange={(e) => getData(e.target.value)} />
            </div>
        </div>
        <div className="row">
            {tableData.map((val, index) => (
                <div className="col-md-3" key={index}>
                    <Link to={'/info/' + kategori + '/' + val.title} className="card mb-4 wow materialUp animation-delay-5">
                        <figure className="ms-thumbnail ms-thumbnail-diagonal">
                            {val.url && val.file.substr(-3, 3) === 'pdf' ? (<iframe width="100%" height="300" src={val.url} title="Preview File" alt="Preview File" />) : ("")}
                            {val.url && val.file.substr(-3, 3) !== 'pdf' ? (<img className='d-flex mr-3 media-object' width="100%" height="300" src={val.url} title="Preview File" alt="Preview File" />) : ("")}
                        </figure>
                        <div className="card-body">
                            <h4 className='panel-title font-bold'>{val.judul}</h4>
                        </div>
                        <div className="card-footer">
                            <TanggalHari value={val.tanggal} />
                        </div>
                    </Link>
                </div>
            ))}
        </div>
        <div className="text-center p-4">
            <Pagination className="pagination-bar" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
        </div>
    </>)
}

export default View;