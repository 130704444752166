import React from "react"
import { Link } from "react-router-dom"

export const Bighug = () => {
    return (
        <>
        <header className="ms-hero-page-override ms-hero-img-sibeken ms-bg-fixed no-mt pb-4">
            <div className="container">
                <div className="row no-shadow">
                    <div className="col-lg-12">
                        <div className="row fail">
                            <div className="col-lg-3 col-md-3 col-sm-2"></div>
                            <div className="col-lg-6 col-md-6 col-sm-10 ">
                                <div className="card card-banner animated zoomInUp animation-delay-3">
                                    <div className="bg-default text-center">
                                        <Link to={'akun/kegiatan-usaha'} target="_blank" rel="noopener noreferrer">
                                            <img width={200} src="assets/img/bighub.png" alt="" className="icon-layanan" />
                                        </Link>
                                    </div>
                                    <div className="card-body mt-0 text-center">
                                        <h1 className='color-black font-bold lh-125 no-m'>BIG HUB</h1>
                                        <h3 className='color-dark font-bold lh-125 no-m'>Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi</h3>
                                        <h3 className='text-dark lh-125 no-m'>Bagi pelaku usaha yang ingin mendaftarkan <span className="color-success font-bold">Kegiatan Usaha</span>, <span className="color-success font-bold">Promosi</span> dan kebutuhan terkait <span className="color-success font-bold">Pengadaan</span>. Ataupun bagi pelaku usaha, lembaga dan instansi di Kota Bekasi yang memiliki kendala dan ingin melakukan pertemuan bisnis, kami siap untuk memfasilitasinya melalui fitur <span className="color-success font-bold">Janjian</span>.</h3>
                                        <div className="lh-125">
                                            <Link to={'akun/kegiatan-usaha'} rel="noopener noreferrer" className="btn btn-raised btn-primary br-2">Daftar</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        
        </>
    )
}