import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

function Kuesioner() {
    const table = {
        width: '100%', fontSize: '12px', marginTop: '15px'
    };
    const rb = {
        position: 'relative', top: '2px'
    };
    const btnSimpan = {
        padding: '8px 12px'
    };
    const [pesan, setPesan] = useState(false)
    const [token] = useState('')
    const [idUser] = useState('')
    const [jk, setJK] = useState([])
    const [pekerjaan, setPekerjaan] = useState([])
    const [pendidikan, setPendidikan] = useState([])
    const [kuesioner, setKuesioner] = useState([])
    const [lkspelayanan, getLokasi] = useState([])
    const [instansi_id] = useState('1')
    const [nama, setNama] = useState('')
    const [jnsKelamin, setjnsKelamin] = useState('')
    const [usia, setUsia] = useState('')
    const [nmPelayanan, setNmPelayanan] = useState('')
    const [jnsPekerjaan, setjnsPekerjaan] = useState('')
    const [jnsPendidikan, setjnsPendidikan] = useState('')
    const [alamat, setAlamat] = useState('')
    const [lokasi, setLokasi] = useState('')
    const [saran, setSaran] = useState('')
    const [skm, setSkm] = useState({
        u1: '',
        u2: '',
        u3: '',
        u4: '',
        u5: '',
        u6: '',
        u7: '',
        u8: '',
        u9: ''
    })
    const [msg, setMsg] = useState('')
    const navigate = useNavigate()

    const handelChange = (e) => {
        const { name, value } = e.target
        setSkm((prev) => {
            return { ...prev, [name]: value }
        })
    }

    useEffect(() => {
        getPelayanan()
        getJK()
        getPekerjaan()
        getPendidikan()
        getKuesioner()
    }, [])

    const getJK = async () => {
        const response = await axios.post('/api/skm/kelamin', {})
        setJK(response.data)
    }

    const getPekerjaan = async () => {
        const response = await axios.post('/api/skm/pekerjaan', {})
        setPekerjaan(response.data)
    }

    const getPendidikan = async () => {
        const response = await axios.post('/api/skm/pendidikan', {})
        setPendidikan(response.data)
    }


    const getPelayanan = async () => {
        const response = await axios.get(`/api/skm/pelayanan/1`, {})
        setNmPelayanan(response.data.nm_pelayanan)
        const result = response.data.lokasi
        if (result) {
            const str = result.replace("_", " ");
            const params = str.split(',')
            getLokasi(params)
        } else {
            const params = []
            getLokasi(params)
        }
        getKuesioner(response.data.template_id)
    }


    const getKuesioner = async (key) => {
        const response = await axios.post('/api/skm/kuesioner', {
            key: key
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        setKuesioner(response.data)
    }

    const getPesan = () => {
        setPesan((prevState) => !prevState)
    }

    const display = {
        display: `${pesan ? "block" : "none"}`,
    }

    const prosesSimpan = async (e) => {
        e.preventDefault()
        setMsg('Mohon Tunggu!!')
        getPesan()
        const tglIsi = new Date().toLocaleDateString('pt-br').split('/').reverse().join('-')
        const instansi = await axios.get(`/api/skm/instansi/${instansi_id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        const pekerjaan = jnsPekerjaan.split('-')
        const pendidikan = jnsPendidikan.split('-')
        try {
            const simpan = await axios.post('/api/skm', {
                nama,
                tgl_isi: tglIsi,
                jk_id: jnsKelamin,
                usia,
                pekerjaan_id: pekerjaan[0],
                pendidikan_id: pendidikan[0],
                nm_pekerjaan: pekerjaan[1],
                nm_pendidikan: pendidikan[1],
                instansi_id,
                pelayanan_id: 1,
                nm_instansi: instansi.data.nm_instansi,
                nm_pelayanan: nmPelayanan,
                alamat,
                lokasi,
                u1: skm.u1,
                u2: skm.u2,
                u3: skm.u3,
                u4: skm.u4,
                u5: skm.u4,
                u6: skm.u6,
                u7: skm.u7,
                u8: skm.u8,
                u9: skm.u9,
                saran,
                tahun: tglIsi.slice(0, 4),
                bulan: tglIsi.slice(5, 7),
                kategori: 1,
                aktif: 0,
                i_user: idUser,
                i_date: new Date(),
                u_user: idUser,
                u_date: new Date()
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setTimeout(() => {
                setMsg(simpan.data.msg)
            }, 1000)
            setTimeout(() => {
                setMsg('')
                getPesan()
            }, 3000)
            setTimeout(() => {
                navigate('/')
            }, 4000)
        } catch (err) {
            setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
            getPesan()
            setTimeout(() => {
                setMsg('')
                getPesan()
            }, 2000)
        }
    }

    return (
        <>
            <div className="container">
                <div className="card animated fadeInUp animation-delay-7 br-2 mb-6">
                    <div className="card-body p-5">
                        <div className="row">
                            {kuesioner.length === 0 ? (<>
                                <div className="col-md-12 text-center">
                                    <div className="alert alert-success border-20 p-1">
                                        <h3 className="color-white"><i className="fa fa-spinner fa-spin mr-1"></i>Mohon Tunggu Sebentar</h3>
                                    </div>
                                </div>
                            </>) : (<>
                                <form className="form-horizontal" onSubmit={prosesSimpan}>
                                    <div className="form-group row is-empty mt-1">
                                        <div className="col-lg-2">Nama</div>
                                        <div className="col-lg-4">
                                            <input required type="text" className="form-control" value={nama} onChange={(e) => setNama(e.target.value)} placeholder="Nama" />
                                        </div>
                                        <div className="col-lg-2">Tanggal Isi</div>
                                        <div className="col-lg-4">
                                            {new Date().toLocaleDateString('pt-br').split('/').reverse().join('-')}
                                        </div>
                                        <div className="col-lg-2">Jenis Kelamin</div>
                                        <div className="col-lg-4">
                                            <select required value={jnsKelamin} onChange={(e) => setjnsKelamin(e.target.value)} className="form-control">
                                                <option value="">==</option>
                                                {jk.map((val, index) => (
                                                    <option key={index} value={val.id_kelamin}>{index + 1} {val.nm_kelamin}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-2">Usia</div>
                                        <div className="col-lg-4">
                                            <input required type="text" className="form-control" value={usia} onChange={(e) => setUsia(e.target.value)} placeholder="Usia" />
                                        </div>
                                        <div className="col-lg-2">Pekerjaan</div>
                                        <div className="col-lg-4">
                                            <select required value={jnsPekerjaan} onChange={(e) => setjnsPekerjaan(e.target.value)} className="form-control">
                                                <option value="">==</option>
                                                {pekerjaan.map((val, index) => (
                                                    <option key={index} value={val.id_pekerjaan + '-' + val.nm_pekerjaan}>{index + 1} {val.nm_pekerjaan}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-2">Pendidikan</div>
                                        <div className="col-lg-4">
                                            <select required value={jnsPendidikan} onChange={(e) => setjnsPendidikan(e.target.value)} className="form-control">
                                                <option value="">==</option>
                                                {pendidikan.map((val, index) => (
                                                    <option key={index} value={val.id_pendidikan + '-' + val.nm_pendidikan}>{index + 1} {val.nm_pendidikan}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-2">Alamat</div>
                                        <div className="col-lg-10">
                                            <textarea required type="text" className="form-control" onChange={(e) => setAlamat(e.target.value)} value={alamat} placeholder="Alamat" />
                                        </div>
                                        <div className="col-lg-2">Lokasi Pelayanan</div>
                                        <div className="col-lg-10">
                                            <select required value={lokasi} onChange={(e) => setLokasi(e.target.value)} className="form-control">
                                                <option value="">==</option>
                                                {lkspelayanan.map((val, index) => (
                                                    <option key={index} value={val}>{index + 1} {val}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-12">
                                            <br></br>
                                            <div className="table-responsive">
                                                <table style={table} className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr className="purple">
                                                            <th className="text-center">No</th>
                                                            <th className="text-center">Deskripsi</th>
                                                            <th className="text-center" colSpan="4">Jawaban</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {kuesioner.map((val, index) => (
                                                            <tr key={index}>
                                                                <td width={'5%'} className="text-center">{index + 1}</td>
                                                                <td width={'29%'} className="text-left">{val.deskripsi}</td>
                                                                <td width={'12%'} className="text-center">
                                                                    <input required type="radio" style={rb} name={'u' + (index + 1)} value="1" onChange={handelChange} />
                                                                    &nbsp;{val.pil_a}
                                                                </td>
                                                                <td width={'17%'} className="text-center">
                                                                    <input required type="radio" style={rb} name={'u' + (index + 1)} value="2" onChange={handelChange} />
                                                                    &nbsp;{val.pil_b}
                                                                </td>
                                                                <td width={'20%'} className="text-center">
                                                                    <input required type="radio" style={rb} name={'u' + (index + 1)} value="3" onChange={handelChange} />
                                                                    &nbsp;{val.pil_c}
                                                                </td>
                                                                <td width={'17%'} className="text-center">
                                                                    <input required type="radio" style={rb} name={'u' + (index + 1)} value="4" onChange={handelChange} />
                                                                    &nbsp;{val.pil_d}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row is-empty mt-1">
                                        <div className="col-lg-2">Saran</div>
                                        <div className="col-lg-10">
                                            <textarea required type="text" className="form-control" value={saran} onChange={(e) => setSaran(e.target.value)} placeholder="Saran"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row is-empty no-mt mb-1">
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-2">
                                            <button style={btnSimpan} type="text" className="btn btn-raised btn-success br-2 no-m">
                                                <i className="fa fa-save no-mr"></i> Kirim
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={pesan ? "modal-open" : ""}>
                <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog center">
                        <div className="modal-content modal-sm">
                            <div className="modal-header header-modal-primary p-1">
                                <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                                    PESAN
                                </h4>
                            </div>
                            <div className="modal-body text-center">
                                <h4 className="font-bold">{msg.toUpperCase()}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {pesan ? <div className="modal-backdrop fade in"></div> : ""}
        </>
    )
}

export default Kuesioner