export const Tanggal = ({value}) => { 
  var date = value? new Date(value) : new Date()
  var tahun = date.getFullYear()
  var bulan = date.getMonth()
  var tanggal = String(date.getDate()).padStart(2, '0')
  let bulanIndonesia = ""
  if (bulan === 0) {
    bulanIndonesia = "Januari"
  } else if (bulan === 1) {
    bulanIndonesia = "Februari"
  } else if (bulan === 2) {
    bulanIndonesia = "Maret"
  } else if (bulan === 3) {
    bulanIndonesia = "April"
  } else if (bulan === 4) {
    bulanIndonesia = "Mei"
  } else if (bulan === 5) {
    bulanIndonesia = "Juni"
  } else if (bulan === 6) {
    bulanIndonesia = "Juli"
  } else if (bulan === 7) {
    bulanIndonesia = "Agustus"
  } else if (bulan === 8) {
    bulanIndonesia = "Setember"
  } else if (bulan === 9) {
    bulanIndonesia = "Oktober"
  } else if (bulan === 10) {
    bulanIndonesia = "November"
  } else if (bulan === 11) {
    bulanIndonesia = "Desember"
  }
  const code = tanggal + " " + bulanIndonesia + " " + tahun
  return code
}

export const TanggalHari = ({value}) => { 
  var date = value? new Date(value) : new Date()
  var tahun = date.getFullYear()
  var bulan = date.getMonth()
  var tanggal = String(date.getDate()).padStart(2, '0')
  var hari = date.getDay()
  let hariIndonesia = ""
  let bulanIndonesia = ""
  if (hari === 0) {
    hariIndonesia = "Minggu"
  } else if (hari === 1) {
    hariIndonesia = "Senin"
  } else if (hari === 2) {
    hariIndonesia = "Selasa"
  } else if (hari === 3) {
    hariIndonesia = "Rabu"
  } else if (hari === 4) {
    hariIndonesia = "Kamis"
  } else if (hari === 5) {
    hariIndonesia = "Jumat"
  } else if (hari === 6) {
    hariIndonesia = "Sabtu"
  }
  if (bulan === 0) {
    bulanIndonesia = "Januari"
  } else if (bulan === 1) {
    bulanIndonesia = "Februari"
  } else if (bulan === 2) {
    bulanIndonesia = "Maret"
  } else if (bulan === 3) {
    bulanIndonesia = "April"
  } else if (bulan === 4) {
    bulanIndonesia = "Mei"
  } else if (bulan === 5) {
    bulanIndonesia = "Juni"
  } else if (bulan === 6) {
    bulanIndonesia = "Juli"
  } else if (bulan === 7) {
    bulanIndonesia = "Agustus"
  } else if (bulan === 8) {
    bulanIndonesia = "Setember"
  } else if (bulan === 9) {
    bulanIndonesia = "Oktober"
  } else if (bulan === 10) {
    bulanIndonesia = "November"
  } else if (bulan === 11) {
    bulanIndonesia = "Desember"
  }
  const code = hariIndonesia + ", " + tanggal + " " + bulanIndonesia + " " + tahun
  return code
}

export const Kode = () => {
  var date = new Date()
  var tahun = date.getFullYear()
  var bulan = String(date.getMonth() + 1).padStart(2, '0')
  var tanggal = String(date.getDate()).padStart(2, '0')
  var jam = date.getHours()
  var menit = date.getMinutes()
  var detik = date.getSeconds()
  const code = tahun + bulan + tanggal + jam + menit + detik
  return code
}
