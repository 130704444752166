import React, { useState, useEffect, useMemo } from 'react'
import { useParams, Link } from "react-router-dom"
import Pagination from '../pages/paginations'
import axios from "axios"
import Nav from './Nav'
import Table from './Table'

const View = () => {
    const { kategori } = useParams()
    const [key, setKey] = useState("")
    const [periode, setPeriode] = useState("")
    const [tahun, setTahun] = useState("")
    const [triwulan, setTriwulan] = useState("")
    const [bulan, setBulan] = useState("")
    const [minggu, setMinggu] = useState("")
    const [tanggal, setTanggal] = useState("")
    const [optTahun, setOptTahun] = useState({
        display: "none",
    })
    const [optTriwulan, setOptTriwulan] = useState({
        display: "none",
    })
    const [optBulan, setOptBulan] = useState({
        display: "none",
    })
    const [optMinggu, setOptMinggu] = useState({
        display: "none",
    })
    const [optTanggal, setOptTanggal] = useState({
        display: "none",
    })
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(10)

    useEffect(() => {
        getData(kategori)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getWaktu = async (value) => {
        if (value === 'TANGGAL') {
            setOptTahun({
                display: "none",
            })
            setOptTriwulan({
                display: "none",
            })
            setOptBulan({
                display: "none",
            })
            setOptMinggu({
                display: "none",
            })
            setOptTanggal({
                display: "block",
            })
        } else if (value === 'MINGGU') {
            setOptTahun({
                display: "block",
            })
            setOptTriwulan({
                display: "none",
            })
            setOptBulan({
                display: "block",
            })
            setOptMinggu({
                display: "block",
            })
            setOptTanggal({
                display: "none",
            })
        } else if (value === 'TRIWULAN') {
            setOptTahun({
                display: "block",
            })
            setOptTriwulan({
                display: "block",
            })
            setOptBulan({
                display: "none",
            })
            setOptMinggu({
                display: "none",
            })
            setOptTanggal({
                display: "none",
            })
        } else if (value === 'BULAN') {
            setOptTahun({
                display: "block",
            })
            setOptTriwulan({
                display: "none",
            })
            setOptBulan({
                display: "block",
            })
            setOptMinggu({
                display: "none",
            })
            setOptTanggal({
                display: "none",
            })
        } else if (value === 'TAHUN') {
            setOptTahun({
                display: "block",
            })
            setOptTriwulan({
                display: "none",
            })
            setOptBulan({
                display: "none",
            })
            setOptMinggu({
                display: "none",
            })
            setOptTanggal({
                display: "none",
            })
        }
        setPeriode(value)
    }

    const getData = async (value) => {
        let inputWaktu
        if (periode === 'TANGGAL') {
            inputWaktu = tanggal
        } else if (periode === 'MINGGU') {
            inputWaktu = "TAHUN " + tahun + " BULAN " + bulan + " MINGGU KE-" + minggu
        } else if (periode === 'TRIWULAN') {
            inputWaktu = "TAHUN " + tahun + " TRIWULAN " + triwulan
        } else if (periode === 'BULAN') {
            inputWaktu = "TAHUN " + tahun + " BULAN " + bulan
        } else if (periode === 'TAHUN') {
            inputWaktu = "TAHUN " + tahun
        } else {
            inputWaktu = ""
        }
        // const category = value ? value.replaceAll('-', ' ').toUpperCase() : kategori.replaceAll('-', ' ').toUpperCase()
        const category = value ? value.replaceAll('-', ' ') : kategori.replaceAll('-', ' ')
        const response = await axios.post("/api/laporan/publish", {
            subjek: category,
            key: key,
            waktu: inputWaktu,
            jml: 100
        })
        setPeriode('')
        setTahun('')
        setTriwulan('')
        setBulan('')
        setMinggu('')
        setTanggal('')
        setKey('')
        setData(response.data)
        setCurrentPage(1)
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage])

    return (
        <>
            <header className="ms-hero-page-override-home no-mt pb-4">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-slash">
                            <li className="breadcrumb-item">
                                <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">LAPORAN</li>
                            <li className="breadcrumb-item active" aria-current="page">{kategori.replaceAll('-', ' ').toUpperCase()}</li>
                        </ol>
                        <div className="btn-group w-300">
                            <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {kategori.replaceAll('-', ' ').toUpperCase()} <i className="zmdi zmdi-chevron-down right only"></i>
                            </button>
                            <Nav getKategori={getData} />
                        </div>
                    </nav>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <h1 className='color-dark font-bold lh-125'>LAPORAN {kategori.replaceAll('-', ' ').toUpperCase()}</h1>
                            <h3 className="color-dark lh-125">Telusuri laporan {kategori.replaceAll('-', ' ').toLowerCase()} Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi</h3>
                            <hr></hr>
                            <div className="row">
                                <div className="col-md-3">
                                    <input className="form-control" type="text" value={key} onChange={(e) => setKey(e.target.value)} placeholder="Masukkan Kata Kunci" />
                                </div>
                                <div className="col-sm-2">
                                    <select className="form-control" value={periode} onChange={(e) => getWaktu(e.target.value)} required>
                                        <option value="">=Periode=</option>
                                        <option value="TAHUN">1. TAHUN</option>
                                        <option value="TRIWULAN">2. TRIWULAN</option>
                                        <option value="BULAN">3. BULAN</option>
                                        <option value="MINGGU">4. MINGGU</option>
                                        <option value="TANGGAL">5. TANGGAL</option>
                                    </select>
                                </div>
                                <div className="col-md-2" style={optTahun}>
                                    <input className="form-control" type="text" value={tahun} onChange={(e) => setTahun(e.target.value)} placeholder="Tahun" />
                                </div>
                                <div className="col-md-2" style={optTriwulan}>
                                    <select className="form-control" value={triwulan} onChange={(e) => setTriwulan(e.target.value)} >
                                        <option value="">=Triwulan=</option>
                                        <option value="I">1. SATU</option>
                                        <option value="II">2. DUA</option>
                                        <option value="III">3. TIGA</option>
                                        <option value="IV">4. EMPAT</option>
                                    </select>
                                </div>
                                <div className="col-md-2" style={optBulan}>
                                    <select className="form-control" value={bulan} onChange={(e) => setBulan(e.target.value)} >
                                        <option value="">=Bulan=</option>
                                        <option value="JANUARI">1. JANUARI</option>
                                        <option value="FEBRUARI">2. FEBRUARI</option>
                                        <option value="MARET">3. MARET</option>
                                        <option value="APRIL">4. APRIL</option>
                                        <option value="MEI">5. MEI</option>
                                        <option value="JUNI">6. JUNI</option>
                                        <option value="JULI">7. JULI</option>
                                        <option value="AGUSTUS">8. AGUSTUS</option>
                                        <option value="SEPTEMBER">9. SEPTEMBER</option>
                                        <option value="OKTOBER">10. OKTOBER</option>
                                        <option value="NOVEMBER">11. NOVEMBER</option>
                                        <option value="DESEMBER">12. DESEMBER</option>
                                    </select>
                                </div>
                                <div className="col-md-2" style={optMinggu}>
                                    <select className="form-control" value={minggu} onChange={(e) => setMinggu(e.target.value)} >
                                        <option value="">=Minggu Ke-=</option>
                                        <option value="1">1. SATU</option>
                                        <option value="2">2. DUA</option>
                                        <option value="3">3. TIGA</option>
                                        <option value="4">4. EMPAT</option>
                                    </select>
                                </div>
                                <div className="col-md-2" style={optTanggal}>
                                    <input className="form-control" type="date" value={tanggal} onChange={(e) => setTanggal(e.target.value)} />
                                </div>
                                <div className="col-md-1">
                                    <span className="btn btn btn-raised btn-primary br-2 no-m" onClick={() => getData()}>
                                        <i className="fa fa-search no-m"></i>
                                    </span>
                                </div>
                            </div>
                            <Table tableData={tableData}/>
                            <div className="text-center p-4">
                                <Pagination className="pagination-bar" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default View;