import React, { useState, useEffect } from 'react'
import axios from "axios"
import parse from 'html-react-parser'
import { useParams, Link } from "react-router-dom"

const View = () => {
    const { key, judul } = useParams()
    const [list, setList] = useState([])
    const [title, setTitle] = useState('')
    const [profile, setProfile] = useState('')
    const [description, setDescription] = useState('')
    // const [information, setInformation] = useState('')
    const [url, setUrl] = useState('')

    useEffect(() => {
        getData()
        getList()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (code) => {
        const response = await axios.post("/api/promotion/publish", {
            key: code || key,
            jml: 1
        })
        setProfile(response.data[0].profile)
        setTitle(response.data[0].title)
        setDescription(response.data[0].description)
        // setInformation(response.data[0].information)
        setUrl(response.data[0].filePath)
    }

    const getList = async () => {
        const response = await axios.post("/api/promotion/publish", {
            jml: 5
        })
        setList(response.data)
    }

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">PROMOSI</li>
                        <li className="breadcrumb-item active">
                            {judul.replaceAll('-', ' ').replaceAll('_', '/').toUpperCase()}
                        </li>
                    </ol>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-3">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className='panel-judul'>INFORMASI LAINNYA</h3>
                            </div>
                            <div className="card-body">
                                <div className="ms-media-list">
                                    {list.map((val, index) => (
                                        <div onClick={() => getData(val.code)} key={index} className="media mb-2">
                                            <div className="media-body">
                                                <span className="media-heading color-dark font-bold">{val.title}</span>
                                                <div className="media-footer text-small color-dark">
                                                    <span className="mr-1">
                                                        {parse(val.description)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <h1 className='color-dark font-bold lh-125'>{title}</h1>
                        <h3 className='panel-judul'>{profile}</h3>
                        <iframe width="100%" className="mb-1" src={url} alt="Preview File" title="Preview File"/>
                        {parse(description)}
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;