import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios'

function Footer() {
  const [profil, setProfil] = useState([])
  const [optLaporan, setOptLaporan] = useState([])

  useEffect(() => {
    getProfil()
    getLaporan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getProfil = async () => {
    const response = await axios.get('/api/profil/publish')
    setProfil(response.data)
  }

  const getLaporan = async (filter) => {
    try {
      const response = await axios.post("/api/laporan/kategori/load", {
        key: filter,
        jml: 1000
      })
      setOptLaporan(response.data)
    } catch (err) {
      setOptLaporan([])
    }
  }

  return (
    <>
      <footer className="ms-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <h4 className='text-footer font-bold'>Mengapa Kota Bekasi?</h4>
              <h4>
                <Link to={"/mengapa-kota-bekasi/demografi"} className='text-footer'>Demografi</Link>
              </h4>
              <h4>
                <Link to={"/mengapa-kota-bekasi/geofrafis"} className='text-footer'>Geografis</Link>
              </h4>
              <h4>
                <a href={'https://oss.go.id'} target="_blank" rel="noopener noreferrer" className='text-footer'>OSS</a>
              </h4>
              <h4>
                <a href={'https://silat.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className='text-footer'>Aplikasi Silat</a>
              </h4>
              <h4>
                <a href={'https://siibeken.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className='text-footer'>Aplikasi SiiBeken</a>
              </h4>
              <h4>
                <Link to={"/survey-kepuasan-masyarakat/pengalaman-investor"} className='text-footer'>Pengalaman Investor</Link>
              </h4>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <h4 className='text-footer font-bold'>Layanan dan Pegaduan</h4>
              <h4>
                <Link to={"/layanan-dan-pengaduan/prosedur/memulai-bisnis"} className='text-footer'>Prosedur</Link>
              </h4>
              <h4>
                <Link to={"/layanan-dan-pengaduan/prosedur/pelayanan"} className='text-footer'>Pelayanan</Link>
              </h4>
              <h4>
                <Link to={"/layanan-dan-pengaduan/prosedur/pengaduan"} className='text-footer'>Pengaduan</Link>
              </h4>
              <h4>
                <Link to={"/layanan-dan-pengaduan/prosedur/panduan"} className='text-footer'>Panduan</Link>
              </h4>
              <h4>
                <Link to={"/layanan-dan-pengaduan/prosedur/open-data"} className='text-footer'>Open Data</Link>
              </h4>
              <h4>
                <Link to={"/layanan-dan-pengaduan/prosedur/hubungi-kami"} className='text-footer'>Hubungi Kami</Link>
              </h4>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <h4 className='text-footer font-bold'>Info</h4>
              <h4>
                <Link to={"/info/siaran-pers"} className='text-footer'>Siaran Pers</Link>
              </h4>
              <h4>
                <Link to={"/info/artikel-publikasi"} className='text-footer'>Artikel dan Publikasi</Link>
              </h4>
              <h4>
                <Link to={"/info/galeri"} className='text-footer'>Galeri</Link>
              </h4>
              <h4>
                <Link to={"/info/pengumuman"} className='text-footer'>Pengumuman</Link>
              </h4>
              <h4>
                <Link to={"/info/agenda-kegiatan"} className='text-footer'>Agenda Kegiatan</Link>
              </h4>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <h4 className='text-footer font-bold'>Laporan</h4>
              {optLaporan.map((val, i) => (
                <h4 key={i}>
                  <Link to={`/laporan/${val.namaKategori.toLowerCase().replaceAll(' ', '-')}`} className='text-footer'>{val.namaKategori.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</Link>
                </h4>
              ))}
              {/* <h4>
                <Link to={"/laporan/perizinan"} className='text-footer'>Perizinan</Link>
              </h4>
              <h4>
                <Link to={"/laporan/umum"} className='text-footer'>Umum</Link>
              </h4>
              <h4>
                <Link to={"/laporan/perencanaan"} className='text-footer'>Perencanaan</Link>
              </h4>
              <h4>
                <Link to={"/laporan/keuangan"} className='text-footer'>Keuangan</Link>
              </h4>
              <h4>
                <Link to={"/laporan/pengaduan"} className='text-footer'>Pengaduan</Link>
              </h4>
              <h4>
                <Link to={"/laporan/mpp-dan-gpp"} className='text-footer'>Mpp dan GPP</Link>
              </h4>
              <h4>
                <Link to={"/laporan/oss"} className='text-footer'>Online Single Submission (OSS)</Link>
              </h4>
              <h4>
                <Link to={"/laporan/lkpm"} className='text-footer'>Kegiatan Penanaman Modal (LKPM)</Link>
              </h4> */}
            </div>
            <div className="col-lg-12 col-md-12">
              <hr></hr>
            </div>
            <div className="col-lg-4 col-md-4 ms-footer-col ms-footer-text-left">
              <div className="mt-0 mb-2">
                <address className="no-mb">
                  <ul className='list-unstyled ms-icon-list three_cols'>
                    <li>
                      <Link to={"/privasi-pengguna"} className='text-footer font-bold'>Privasi</Link>
                    </li>
                    <li>
                      <Link to={"/ketentuan-penggunaan"} className='text-footer font-bold'>Ketentuan</Link>
                    </li>
                    <li>
                      <Link to={"/peta-situs"} className='text-footer font-bold'>Peta Situs</Link>
                    </li>
                  </ul>
                </address>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 ms-footer-col ms-footer-text-right">
              <div className="mt-0 mb-2">
                <address className="no-mb">
                  <p><i className="zmdi zmdi-email mr-1"></i> Email: <a className="text-footer font-bold" href={`mailto: ${profil.email}`}>{profil.email}</a></p>
                  <p><i className="zmdi zmdi-phone mr-1"></i> Telepon: {profil.telp} </p>
                </address>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 ms-footer-col ms-footer-text-left">
              <p>&copy; {new Date().getFullYear()} Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi , Semua Hak Cipta dilindungi undang-undang</p>
              {profil.alamat}, {profil.kota}, {profil.kd_pos}
            </div>
            <div className="col-lg-3 col-md-3 ms-footer-col ms-footer-text-right">
              <div className="ms-footbar-block">
                <div className="ms-footbar-social">
                  <a href={profil.facebook} className="btn-circle btn-facebook"><i className="zmdi zmdi-facebook"></i></a>
                  <a href={profil.twitter} className="btn-circle btn-twitter"><i className="zmdi zmdi-twitter"></i></a>
                  <a href={profil.youtube} className="btn-circle btn-youtube"><i className="zmdi zmdi-youtube-play"></i></a>
                  <a href={profil.instagram} className="btn-circle btn-instagram"><i className="zmdi zmdi-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer