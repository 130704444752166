import React, { useState, useEffect } from 'react'
import { useParams, Link } from "react-router-dom"
import axios from "axios"
import parse from 'html-react-parser'
import Nav from './Nav'

const View = () => {
    const { kategori, title } = useParams()
    const [nama, setNama] = useState('')
    const [jabatan, setJabatan] = useState('')
    const [tupoksi, setTupoksi] = useState('')
    const [profil, setProfil] = useState('')
    const [ext, setExt] = useState('')
    const [url, setUrl] = useState('')

    useEffect(() => {
        getData()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post('/api/profil/pejabat', {
            key: title,
            jml: 1
        })
        const file_name_array = response.data[0].file.split(".");
        const file_extension = file_name_array[file_name_array.length - 1];
        setNama(response.data[0].nama)
        setJabatan(response.data[0].jabatan)
        setTupoksi(response.data[0].tupoksi)
        setProfil(response.data[0].profil)
        setExt(file_extension)
        setUrl(response.data[0].url)
    }

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">TENTANG DPMPTSP</li>
                        <li className="breadcrumb-item active">
                            {kategori.replaceAll('-', ' ').toUpperCase()}
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{nama.replaceAll('-', ' ').toUpperCase()}</li>
                    </ol>
                    <div className="btn-group w-300">
                        <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            PROFIL PEJABAT <i className="zmdi zmdi-chevron-down right only"></i>
                        </button>
                        <Nav />
                    </div>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-9">
                        <h1 className='color-dark font-bold lh-125'>{nama}</h1>
                        <h4 className="color-dark font-bold lh-125">{jabatan}</h4>
                        <h4 className="color-dark font-bold lh-125">Profil</h4>
                        {parse(profil)}
                        <h4 className="color-dark font-bold lh-125">Tupoksi</h4>
                        {parse(tupoksi)}
                    </div>
                    <div className="col-md-3">
                        {url && ext === 'pdf' ? (<iframe className='mb-4' width="100%" src={url} title="Preview File" alt="Preview File" />) : ("")}
                        {url && ext !== 'pdf' ? (<img className='mb-4' width="100%" src={url} title="Preview File" alt="Preview File" />) : ("")}
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;