import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'

function Reset() {
    const [pesan, setPesan] = useState(false)
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')
    const navigate = useNavigate()

    const Auth = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post('/api/pelaku-usaha/reset', {
                email
            })
            getPesan()
            setMsg(response.data.msg)
            setTimeout(() => {
                getPesan()
                setMsg('')
            }, 2000)
            setTimeout(() => {
                navigate('/login')
            }, 4000)
        } catch (err) {
            getPesan()
            setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
            setTimeout(() => {
                getPesan()
                setMsg('')
            }, 2000)
        }
    }

    const getPesan = () => {
        setPesan((prevState) => !prevState)
    }

    const display = {
        display: `${pesan ? "block" : "none"}`,
    }

    return (
        <>
            <div className="bg-full-page bg-primary back-fixed">
                <div className="mw-500 absolute-center">
                    <div className="card color-dark animated zoomInDown animation-delay-5 border-radius-20">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h3 className="color-success">Reset Password</h3>
                                </div>
                                <div className="col-md-6 col-6">
                                    <img width="100%" src="assets/img/logo-head.png" alt="" />
                                </div>
                            </div>
                            <form onSubmit={Auth}>
                                <fieldset>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="email-login">Email</label>
                                        <div className="input-group">
                                            <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                                            <input type="text" id="email-login" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-6 col-6">
                                            <button className="btn btn-raised btn-warning btn-block p-1 br-2"><i className="zmdi zmdi-key no-ml mr-1"></i>RESET PASSWORD</button>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <Link to={'/login'} className="btn btn-raised btn-success btn-block p-1 br-2"><i className="zmdi zmdi-lock mr-1"></i> MASUK</Link>
                                        </div>
                                        <div className="col-md-12 text-center mt-2">
                                            Belum punya akun? <Link className="color-success" to={'/register'}>Daftar</Link> atau kembali <Link className="color-success" to={'/'}>Beranda</Link>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={pesan ? "modal-open" : ""}>
                <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog center">
                        <div className="modal-content modal-sm">
                            <div className="modal-header header-modal-primary p-1">
                                <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                                    PESAN
                                </h4>
                            </div>
                            <div className="modal-body text-center">
                                <h4 className="font-bold">{msg.toUpperCase()}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {pesan ? <div className="modal-backdrop fade in"></div> : ""}
        </>
    )
}

export default Reset