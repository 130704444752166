import React, { useState, useEffect, useMemo } from 'react'
import axios from "axios"
import { Link } from "react-router-dom"
import { TanggalHari } from '../Utils'
import Pagination from '../pages/paginations'

const View = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(10)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (val) => {
        const response = await axios.post("/api/skm/publish", {
            key: 1,
            jml: 100
        })
        setData(response.data)
        setCurrentPage(1)
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage])

    return (<>
        <div className="container">
            <div className="card animated fadeInUp animation-delay-7 br-2 mb-6">
                <div className="card-body p-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className='color-dark font-bold lh-125'>Testimoni</h1>
                            <h3 className="color-dark lh-125">Kami melakukan survey kepuasan terkait pelayanan dari Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi</h3>
                        </div>
                    </div>
                    <div className="row">
                        {tableData.map((val, index) => (
                            <div className="col-md-6" key={index}>
                                <Link to={'/info/agenda-kegiatan/' + val.title} className="panel panel-dark panel-primary">
                                    <div className="panel-heading btr-10">
                                        <TanggalHari value={val.tgl_isi} />
                                    </div>
                                    <div className="panel-body bbr-10">
                                        <h3 className='panel-title'>{val.saran}</h3>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className="text-center p-4">
                        <Pagination className="pagination-bar" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default View;