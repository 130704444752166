import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'

function Navbar() {
    const [token, setToken] = useState("")
    // const [open, setOpen] = useState(false)
    const [keyword, setKeyword] = useState("")
    const [optLaporan, setOptLaporan] = useState([])
    const navigate = useNavigate()

    // const openSidebar = () => {
    //     setOpen(prevState => !prevState)
    //     setTimeout(() => {
    //         setOpen(false)
    //     }, 2000)
    // }

    useEffect(() => {
        updateToken()
        getLaporan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateToken = async () => {
        const response = await axios.get('/api/pelaku-usaha/token')
        if (response) {
            setToken(response.data.authToken)
        } else {
            setToken('')
        }
    }

    const logout = async () => {
        try {
            await axios.delete('/api/pelaku-usaha/logout')
            navigate('/')
            setToken('')
        } catch (err) {
            if (err.response) {
                console.log(err)
            }
        }
    }

    const search = (e) => {
        e.preventDefault()
        const key = encodeURIComponent(keyword)
        window.location.replace(`/pencarian/${key}`)
    }

    const getLaporan = async (filter) => {
        try {
            const response = await axios.post("/api/laporan/kategori/load", {
                key: filter,
                jml: 1000
            })
            setOptLaporan(response.data)
        } catch (err) {
            setOptLaporan([])
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-md  navbar-static ms-navbar ms-navbar-primary no-mb">
                <div className="container-full">
                    <div className="navbar-header">
                        <Link className="navbar-brand" to={"/"}>
                            <img height="70" src="/assets/img/logo-header.png" alt="logo-header" className="img-responsive" />
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="ms-navbar">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown dropdown-megamenu-container">
                                <Link to={''} className="nav-link dropdown-toggle animated fadeIn animation-delay-7" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="component">MENGAPA KOTA BEKASI? <i className="zmdi zmdi-chevron-down"></i></Link>
                                <ul className="dropdown-menu dropdown-megamenu animated fadeIn animated-2x">
                                    <li className="container-full-megamenu">
                                        <div className="row">
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Prospek Investasi</h3>
                                                    <ul className="megamenu-block-list">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link to={"/mengapa-kota-bekasi/demografi"} className="withripple">
                                                                Demografi <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link to={"/mengapa-kota-bekasi/geografis"} className="withripple">
                                                                Geografis <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Potensi Investasi</h3>
                                                    <ul className="megamenu-block-list">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <div className="p-15">
                                                                Temukan lebih lanjut mengenai beragam Potensi Investasi Regional di Kota Bekasi
                                                            </div>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <a href={'https://siibeken.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className="btn btn-navbar btn-raised btn-primary br-2 mt-1 ml-1">SiiBeken <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Perizinan</h3>
                                                    <ul className="megamenu-block-list">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <a href={'https://oss.go.id'} target="_blank" rel="noopener noreferrer" className="withripple">
                                                                OSS <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </a>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <a href={'https://silat.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className="btn btn-navbar btn-raised btn-primary br-2 mt-1 ml-1">Silat V2 <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 megamenu-col mt-2">
                                                <div className="card bg-success no-mb pb-1">
                                                    <div className="megamenu-block animated fadeInRight animated-2x pb-0 pt-0">
                                                        <h3 className="megamenu-block-title font-bold pb-1">Pengalaman Investor</h3>
                                                        <p className="pl-2 pt-0 p-15">Selanjutnya adalah pengalaman investor yang berinvestasi di Kota Bekasi</p>
                                                        <Link to={"/survey-kepuasan-masyarakat/testimoni"} className="btn btn-raised btn-primary br-2 mt-1 ml-1 btn-right">Selanjutnya <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown dropdown-megamenu-container">
                                <Link to={''} className="nav-link dropdown-toggle animated fadeIn animation-delay-7" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="component">LAYANAN DAN PENGADUAN <i className="zmdi zmdi-chevron-down"></i></Link>
                                <ul className="dropdown-menu dropdown-megamenu animated fadeIn animated-2x">
                                    <li className="container-full-megamenu">
                                        <div className="row">
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Prosedur</h3>
                                                    <ul className="megamenu-block-list">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link to={"/layanan-dan-pengaduan/prosedur/memulai-bisnis"} className="withripple">
                                                                Memulai Bisnis <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link to={"/layanan-dan-pengaduan/prosedur/peraturan"} className="withripple">
                                                                Peraturan Perundang Undangan <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link to={"/layanan-dan-pengaduan/prosedur/pelayanan"} className="withripple">
                                                                Pelayanan <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link to={"/layanan-dan-pengaduan/prosedur/pengaduan"} className="withripple">
                                                                Pengaduan <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link to={"/layanan-dan-pengaduan/prosedur/panduan-aplikasi"} className="withripple">
                                                                Panduan Aplikasi <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Open Data</h3>
                                                    <ul className="megamenu-block-list">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <div className="p-15">
                                                                Temukan lebih lanjut mengenai informasi dan aplikasi kami
                                                            </div>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link to={'/layanan-dan-pengaduan/open-data/layanan-terbuka'} className="btn btn-navbar btn-raised btn-primary br-2 mt-1 ml-1">Selengkapnya <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Hubungi Kami</h3>
                                                    <ul className="megamenu-block-list">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/layanan-dan-pengaduan/hubungi-kami/kontak"}>
                                                                Kontak dan Lokasi <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 megamenu-col mt-2">
                                                <div className="card bg-success no-mb pb-1">
                                                    <div className="megamenu-block animated fadeInRight animated-2x pb-0 pt-0">
                                                        <h3 className="megamenu-block-title font-bold pb-1">Booking Antrian</h3>
                                                        <p className="pl-2 pt-0 p-15">Anda dapat melakukan booking antrian secara online</p>
                                                        <a href={'https://mpp.bekasikota.go.id'} target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-primary br-2 mt-1 ml-1 btn-right">Simple Antri <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown dropdown-megamenu-container">
                                <Link to={''} className="nav-link dropdown-toggle animated fadeIn animation-delay-7" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="component">INFO <i className="zmdi zmdi-chevron-down"></i></Link>
                                <ul className="dropdown-menu dropdown-megamenu animated fadeIn animated-2x">
                                    <li className="container-full-megamenu">
                                        <div className="row">
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Informasi Publik</h3>
                                                    <ul className="megamenu-block-list">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/info/siaran-pers"}>
                                                                <i className='fa fa-bullhorn'></i> Siaran Pers <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/info/galeri"}>
                                                                <i className='fa fa-picture-o'></i> Galeri <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <ul className="megamenu-block-list mt-75">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/info/artikel-publikasi"}>
                                                                <i className='fa fa-newspaper-o'></i> Artikel dan Publikasi <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/info/agenda-kegiatan"}>
                                                                <i className='fa fa-calendar-check-o'></i> Agenda Kegiatan <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <ul className="megamenu-block-list mt-75">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/info/pengumuman"}>
                                                                <i className='fa fa-info'></i> Pengumuman <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 megamenu-col mt-2">
                                                <div className="card bg-success no-mb pb-1">
                                                    <div className="megamenu-block animated fadeInRight animated-2x pb-0 pt-0">
                                                        <h3 className="megamenu-block-title font-bold pb-1">Informasi Publik</h3>
                                                        <p className="pl-2 pt-0 p-15">Telusuri semua informasi publik pada Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu Kota Bekasi</p>
                                                        <Link to={''} className="btn btn-raised btn-primary br-2 mt-1 ml-1 btn-right">Selanjutnya <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown dropdown-megamenu-container">
                                <Link to={''} className="nav-link dropdown-toggle animated fadeIn animation-delay-7" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="component">LAPORAN <i className="zmdi zmdi-chevron-down"></i></Link>
                                <ul className="dropdown-menu dropdown-megamenu animated fadeIn animated-2x">
                                    <li className="container-full-megamenu">
                                        <div className="row">
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Laporan</h3>
                                                    <ul className="megamenu-block-list">
                                                        {optLaporan.slice(0, 5).map((val, i) => (<div key={i}>
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link to={`/laporan/${val.namaKategori.toLowerCase().replaceAll(' ', '-')}`} className='withripple'>
                                                                    {/* {val.namaKategori.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} <span className='float-right'><i className='fa fa-long-arrow-right'></i></span> */}
                                                                    {val.namaKategori} <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                        </div>))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <ul className="megamenu-block-list mt-75">
                                                        {optLaporan.slice(5, 10).map((val, i) => (<div key={i}>
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link to={`/laporan/${val.namaKategori.toLowerCase().replaceAll(' ', '-')}`} className='withripple'>
                                                                    {val.namaKategori.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                        </div>))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <ul className="megamenu-block-list mt-75">
                                                        {optLaporan.slice(10, 15).map((val, i) => (<div key={i}>
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link to={`/laporan/${val.namaKategori.toLowerCase().replaceAll(' ', '-')}`} className='withripple'>
                                                                    {val.namaKategori.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                        </div>))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 megamenu-col mt-2">
                                                <div className="card bg-success no-mb pb-1">
                                                    <div className="megamenu-block animated fadeInRight animated-2x pb-0 pt-0">
                                                        <h3 className="megamenu-block-title font-bold pb-1">Laporan Investasi</h3>
                                                        <p className="pl-2 pt-0 p-15">Anda dapat melihat bagaimana kami begitu transparan</p>
                                                        <Link to={"/laporan/investasi"} className="btn btn-raised btn-primary br-2 mt-1 ml-1 btn-right">Selanjutnya <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown dropdown-megamenu-container">
                                <Link to={''} className="nav-link dropdown-toggle animated fadeIn animation-delay-7" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="component">PROFIL <i className="zmdi zmdi-chevron-down"></i></Link>
                                <ul className="dropdown-menu dropdown-megamenu animated fadeIn animated-2x">
                                    <li className="container-full-megamenu">
                                        <div className="row">
                                            <div className="col-sm-4 megamenu-col mb-2">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Tentang DPMPTSP</h3>
                                                    <ul className="megamenu-block-list">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/tentang-dpmptsp/profil"}>
                                                                <i className='fa fa-building-o'></i> Profil <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/tentang-dpmptsp/struktur-organisasi"}>
                                                                <i className='fa fa-sitemap'></i> Struktur Organisasi <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/tentang-dpmptsp/profil-pejabat"}>
                                                                <i className='fa fa-users'></i> Profil Pejabat <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <ul className="megamenu-block-list mt-75">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/tentang-dpmptsp/struktur-ppid"}>
                                                                <i className='fa fa-sitemap'></i> Struktur PPID <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/tentang-dpmptsp/janji-layanan"}>
                                                                <i className='fa fa-handshake-o'></i> Janji Layanan <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 megamenu-col">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <ul className="megamenu-block-list mt-75">
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/tentang-dpmptsp/maklumat-dpmptsp"}>
                                                                <i className='fa fa-hand-peace-o'></i> Maklumat DPMPTSP <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                        <li className="dropdown-divider"></li>
                                                        <li>
                                                            <Link className="withripple" to={"/tentang-dpmptsp/visi-misi-kota-bekasi"}>
                                                                <i className='fa fa-hand-rock-o'></i> Visi Misi Kota Bekasi <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 megamenu-col mt-2">
                                                <div className="card bg-success no-mb pb-1">
                                                    <div className="megamenu-block animated fadeInRight animated-2x pb-0 pt-0">
                                                        <h3 className="megamenu-block-title font-bold pb-1">Penghargaan Kota Bekasi</h3>
                                                        <p className="pl-2 pt-0 p-15">Selanjutnya adalah penghargaan yang diraih Kota Bekasi</p>
                                                        <Link to={"/tentang-dpmptsp/penghargaan"} className="btn btn-raised btn-primary br-2 mt-1 ml-1 btn-right">Selanjutnya <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            {token ? (<>
                                <li className="nav-item dropdown dropdown-megamenu-container">
                                    <Link to={''} className="nav-link dropdown-toggle animated fadeIn animation-delay-7" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="component">AKUN <i className="zmdi zmdi-chevron-down"></i></Link>
                                    <ul className="dropdown-menu dropdown-megamenu animated fadeIn animated-2x">
                                        <li className="container-full-megamenu">
                                            <div className="row">
                                                <div className="col-sm-4 megamenu-col mb-2">
                                                    <div className="megamenu-block animated fadeInLeft animated-2x">
                                                        <h3 className="megamenu-block-title font-bold">MANAJEMEN AKUN</h3>
                                                        <ul className="megamenu-block-list">
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link className="withripple" to={"/akun/manajemen"}>
                                                                    <i className='fa fa-user'></i> Akun <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link className="withripple" to={"/akun/promosi"}>
                                                                    <i className='fa fa-gift'></i> Promosi <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link className="withripple" to={"/akun/janjian"}>
                                                                    <i className='fa fa-handshake-o'></i> Janjian <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 megamenu-col">
                                                    <div className="megamenu-block animated fadeInLeft animated-2x">
                                                        <ul className="megamenu-block-list mt-75">
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link className="withripple" to={"/akun/profil-usaha"}>
                                                                    <i className='fa fa-building'></i> Profil Usaha <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link className="withripple" to={"/akun/kebutuhan-pengadaan"}>
                                                                    <i className='fa fa-cubes'></i> Kebutuhan Pengadaan <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 megamenu-col">
                                                    <div className="megamenu-block animated fadeInLeft animated-2x">
                                                        <ul className="megamenu-block-list mt-75">
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link className="withripple" to={"/akun/kegiatan-usaha"}>
                                                                    <i className='fa fa-briefcase'></i> Kegiatan Usaha <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                            <li className="dropdown-divider"></li>
                                                            <li>
                                                                <Link className="withripple" to={"/akun/permohonan-pengadaan"}>
                                                                    <i className='fa fa-cart-plus'></i> Permohonan Pengadaan <span className='float-right'><i className='fa fa-long-arrow-right'></i></span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 megamenu-col mt-2">
                                                    <div className="card bg-success no-mb pb-1">
                                                        <div className="megamenu-block animated fadeInRight animated-2x pb-0 pt-0">
                                                            <h3 className="megamenu-block-title font-bold pb-1">Logout</h3>
                                                            <p className="pl-2 pt-0 p-15">Tekan tombol disamping untuk logout</p>
                                                            <button onClick={logout} className="btn btn-raised btn-primary br-2 mt-1 ml-1 btn-right">Logout <span className='float-right ml-1'><i className='fa fa-long-arrow-right'></i></span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </>) : (<>
                                <li className="nav-item">
                                    <Link className="link" to={"/login"}><i className="fa fa-key"></i></Link>
                                </li>
                            </>)}
                            <li className="nav-item dropdown dropdown-megamenu-container">
                                <Link to={''} className="nav-link dropdown-toggle animated fadeIn animation-delay-7" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false" data-name="component"><i className="fa fa-search"></i></Link>
                                <ul className="dropdown-menu dropdown-megamenu animated fadeIn animated-2x">
                                    <li className="container-full-megamenu">
                                        <div className="row">
                                            <div className="col-sm-12 megamenu-col mb-2">
                                                <div className="megamenu-block animated fadeInLeft animated-2x">
                                                    <h3 className="megamenu-block-title font-bold">Pencarian</h3>
                                                    <form onSubmit={search} className="search-form animated zoomInDown pl-2 pr-2">
                                                        <div className="input-group">
                                                            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" className="form-control form-search" placeholder="Pencarian..." />
                                                            <span className="input-group-btn">
                                                                <button type="submit" className="btn btn-fab btn-fab-mini">
                                                                    <i className="fa fa-search"></i>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 megamenu-col mt-2">
                                                <div className="card bg-success no-mb pb-1">
                                                    <div className="megamenu-block animated fadeInRight animated-2x pb-0 pt-0">
                                                        <h3 className="megamenu-block-title font-bold pb-1">Pencarian Data</h3>
                                                        <p className="pl-2 pt-0 p-15">Masukkan Kata Kunci pada kolom pencarian diatas</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {/* <span onClick={openSidebar} className="ms-toggle-left btn-navbar-menu"><i className="zmdi zmdi-menu"></i></span> */}
                </div>
            </nav>
        </>
    )
}

export default Navbar