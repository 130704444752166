import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

function Aktivasi() {
    const { key, code } = useParams()
    const [msg, setMsg] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        getAktivasi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAktivasi = async () => {
        try {
            const response = await axios.put(`/api/pelaku-usaha/aktivasi/${key}/${code}`)
            setMsg(response.data.msg)
            setTimeout(() => {
                redirect()
            }, 5000)
        } catch (err) {
            setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
            setTimeout(() => {
                setMsg('')
            }, 5000)
        }
    }

    const redirect = async () => {
        setTimeout(() => {
            navigate('/login')
        }, 2000)
    }

    return (<>
        <div className="bg-full-page bg-primary back-fixed">
            <div className="mw-500 absolute-center">
                <div className="card color-dark animated zoomInDown animation-delay-5 m-4 br-15">
                    <div className="card-body">
                        <div className="container">
                            <img width="100%" src="https://dpmptsp.bekasikota.go.id/assets/img/dpmptsp.png" alt="" />
                            {msg ? (<div className="alert alert-primary alert-light alert-dismissible br-15 text-center" role="alert">{msg}</div>) : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Aktivasi