import React, { useState, useEffect } from 'react'
import axios from "axios"
import parse from 'html-react-parser'
import { useParams, Link } from "react-router-dom"
import { Add } from './Add'

const View = () => {
    const { key, judul } = useParams()
    const [msg, setMsg] = useState("")
    const [pesan, setPesan] = useState(false)
    const [add, setAdd] = useState(false)
    const [list, setList] = useState([])
    const [title, setTitle] = useState('')
    const [profile, setProfile] = useState('')
    const [description, setDescription] = useState('')
    // const [information, setInformation] = useState('')
    const [url, setUrl] = useState('')

    useEffect(() => {
        getData()
        getList()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (code) => {
        const response = await axios.post("/api/procurement/publish", {
            key: code || key,
            jml: 1
        })
        setProfile(response.data[0].profile)
        setTitle(response.data[0].title)
        setDescription(response.data[0].description)
        // setInformation(response.data[0].information)
        setUrl(response.data[0].filePath)
    }

    const getList = async () => {
        const response = await axios.post("/api/procurement/publish", {
            jml: 5
        })
        setList(response.data)
    }

    const getPesan = () => {
        setPesan((prevState) => !prevState)
    }

    const display = {
        display: `${pesan ? "block" : "none"}`,
    }

    const openAdd = async () => {
        getAdd()
    }

    const closeAdd = () => {
        getAdd()
    }

    const getAdd = () => {
        setAdd((prevState) => !prevState)
    }

    const displayAdd = {
        display: `${add ? "block" : "none"}`
    }

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">PROMOSI DAN KEBUTUHAN PENGADAAN</li>
                        <li className="breadcrumb-item active">
                            {judul.replaceAll('-', ' ').replaceAll('_', '/').toUpperCase()}
                        </li>
                    </ol>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-3">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className='panel-judul'>INFORMASI LAINNYA</h3>
                            </div>
                            <div className="card-body">
                                <div className="ms-media-list">
                                    {list.map((val, index) => (
                                        <div onClick={() => getData(val.code)} key={index} className="media mb-2">
                                            <div className="media-body">
                                                <span className="media-heading color-dark font-bold">{val.title}</span>
                                                <div className="media-footer text-small color-dark">
                                                    <span className="mr-1">
                                                        {parse(val.description)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <h1 className='color-dark font-bold lh-125'>{title}</h1>
                        <h3 className='panel-judul'>{profile}</h3>
                        <iframe width="100%" className="mb-1" src={url} alt="Preview File" title="Preview File"/>
                        {parse(description)}
                        <button onClick={openAdd} className="btn btn-raised btn-warning br-2">Kirim Permohonan <i className="ml-1 no-mr zmdi zmdi-long-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </header>
        {add ? (<>
            <div className={add ? ("modal-open") : ("")}>
                <div style={displayAdd} className={add ? 'modal modal-primary show' : 'modal modal-primary'} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog review">
                        <div className="modal-content modal-md">
                            <div className="modal-header header-modal-primary p-1">
                                <h4 className="modal-title text-center" id="myModalLabel">Permohonan Pengadaan</h4>
                            </div>
                            <div className="modal-body">
                                <Add requestCode={key} closeAdd={closeAdd} getPesan={getPesan} setMsg={setMsg} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>) : ("")}
        <div className={pesan ? "modal-open" : ""}>
            <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog center">
                    <div className="modal-content modal-sm">
                        <div className="modal-header header-modal-primary p-1">
                            <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                                PESAN
                            </h4>
                        </div>
                        <div className="modal-body text-center">
                            <h4 className="font-bold">{msg.toUpperCase()}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {pesan ? <div className="modal-backdrop fade in"></div> : ""}
    </>)
}

export default View;