import React from 'react'

const View = ({handleClick}) => {

    return (<>
        <div className="row no-shadow">
            <div className="col-lg-12">
                <div className="row fail">
                    <div className="col-lg-3 col-md-3 col-sm-2"></div>
                    <div className="col-lg-6 col-md-6 col-sm-10 ">
                        <div className="card-banner animated zoomInUp animation-delay-3">
                            <div className="card-body mt-1 text-center">
                                <h1 className='color-white font-bold lh-125 no-m'>Geografis</h1>
                                <h3 className='text-white lh-125 mb-75 '>Jumlah Penduduk Kota Bekasi saat ini lebih dari 2,2 juta jiwa yang tersebar di 12 kecamatan, yaitu Kecamatan Pondok Gede, Jati Sampurna, Jati Asih, Bantar Gebang, Bekasi Timur, Rawa Lumbu, Bekasi Selatan, Bekasi Barat, Medan Satria, Bekasi Utara, Mustika Jaya, Pondok Melati.</h3>
                                <div className="lh-125">
                                    <button onClick={handleClick} className="btn btn-raised btn-primary br-2">Selanjutnya</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default View;