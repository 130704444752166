import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from "react-router-dom"

const Nav = ({ getKategori }) => {
    const [optLaporan, setOptLaporan] = useState([])

    useEffect(() => {
        getLaporan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLaporan = async (filter) => {
        try {
            const response = await axios.post("/api/laporan/kategori/load", {
                key: filter,
                jml: 1000
            })
            setOptLaporan(response.data)
        } catch (err) {
            setOptLaporan([])
        }
    }

    return (<>
        <ul className="dropdown-menu bg-primary w-300 pb-15">
            <li className="dropdown-header">
                <h4 className='color-warning'>Laporan</h4>
            </li>
            {optLaporan.map((val, i) => (
                <React.Fragment key={i}>
                    <li key={val.namaKategori} role="separator" className="dropdown-divider"></li>
                    <li key={i}>
                        <Link to={`/laporan/${val.namaKategori.toLowerCase().replaceAll(' ', '-')}`} className='dropdown-item' onClick={() => getKategori(val.namaKategori)}>
                            {val.namaKategori.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                        </Link>
                    </li>
                </React.Fragment>
            ))}
        </ul>
    </>)
}

export default Nav;