import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { TanggalHari } from './Utils'

function Footbar() {
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post("/api/publikasi/publish", {
            key: '',
            kategori: '',
            jml: 5
        })
        setData(response.data)
    }

    return (
        <>
            <aside className="ms-footbar mt-0 pt-2 pb-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 ms-footer-col ms-footer-text-left">
                            <div className="ms-footbar-block">
                                <div className="ms-footbar-title">
                                    <h3 className="no-m font-bold">Semua Publikasi</h3>
                                </div>
                                <address className="no-mb">
                                    <ul className='list-unstyled ms-icon-list'>
                                        {data.map((val, index) => (
                                            <li key={index}>
                                                <hr></hr>
                                                <h5 className="no-mb">{val.kategori}</h5>
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <Link to={'/info/' + val.kategori.replaceAll(' ', '-').toLowerCase() + '/' + val.title} className="text-warning wow fadeInUp animation-delay-2">
                                                            <h4 className="mt-1 no-mb font-bold">{val.judul}</h4>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <span className="text-right text-small font-bold"><TanggalHari value={val.tanggal}/></span>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}

export default Footbar