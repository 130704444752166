import React, { useState, useEffect } from 'react'
import axios from "axios"

const View = () => {
    const [data, setData] = useState('')
    const [ext, setExt] = useState('')

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.get("/api/profil/publish", {})
        const file_name_array = response.data.struktur.split(".");
        const file_extension = file_name_array[file_name_array.length - 1];
        setExt(file_extension)
        setData(response.data.struktur)
    }

    return (<>
        <div className="col-md-2"></div>
        <div className="col-md-8">
            <h1 className='color-dark font-bold lh-125'>Struktur Organisasi</h1>
            {data && ext === 'pdf' ? (<iframe className='mb-4' width="100%" height="500" src={data} title="Preview File" alt="Preview File" />) : ("")}
            {data && ext !== 'pdf' ? (<img className='mb-4' width="100%" src={data} title="Preview File" alt="Preview File" />) : ("")}
        </div>
    </>)
}

export default View;