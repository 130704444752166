import React, { useState, useEffect, useMemo } from 'react'
import axios from "axios"
import { Link } from "react-router-dom"
import { TanggalHari } from '../Utils'
import Pagination from '../pages/paginations'

const View = ({keyword}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [PageSize] = useState(10)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post("/api/publikasi/publish", {
            kategori: '',
            keyword: decodeURIComponent(keyword),
            jml: 100
        })
        setData(response.data)
        setCurrentPage(1)
    }

    const tableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return !data ? [] : data.slice(firstPageIndex, lastPageIndex)
    }, [data, currentPage])

    return (<>
        <div className="list-group">
            {tableData.map((val, index) => (
                <Link key={index} to={'/info/'+ val.kategori.replaceAll(' ', '-').toLowerCase() +'/' + val.title} className="list-group-item flex-card">
                    <i className="zmdi zmdi-file-text"></i>
                    <div className="flex-card">
                        <div className="row-2">
                            <p className="justify-desc"><TanggalHari value={val.tanggal} /></p>
                            <h4 className="justify-desc font-bold">{val.judul}</h4>
                        </div>
                    </div>
                    <i className="fa fa-info ml-auto badge-pill"></i>
                </Link>
            ))}
        </div>
        <div className="text-center p-4">
            <Pagination className="pagination-bar" currentPage={currentPage} totalCount={data.length} pageSize={PageSize} onPageChange={page => setCurrentPage(page)} />
        </div>
    </>)
}

export default View;