import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import Captcha from '../Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'

function Register() {
  const [pesan, setPesan] = useState(false)
  const [data, setData] = useState(1)
  const [profil, setProfil] = useState(0)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [nik, setNIK] = useState('')
  const [npwp, setNPWP] = useState('')
  const [tmpt_lahir, setTmptLahir] = useState('')
  const [tgl_lahir, setTglLahir] = useState('')
  const [nm_lengkap, setNmLengkap] = useState('')
  const [no_hp, setNoHp] = useState('')
  const [no_prop, setNoProp] = useState('')
  const [no_kab, setNoKab] = useState('')
  const [no_kec, setNoKec] = useState('')
  const [no_kel, setNoKel] = useState('')
  const [no_rw, setNoRw] = useState('')
  const [no_rt, setNoRt] = useState('')
  const [alamat, setAlamat] = useState('')
  const [kd_pos, setKdPos] = useState('')
  const [no_prop_domisili, setNoPropDomisili] = useState('')
  const [no_kab_domisili, setNoKabDomisili] = useState('')
  const [no_kec_domisili, setNoKecDomisili] = useState('')
  const [no_kel_domisili, setNoKelDomisili] = useState('')
  const [no_rw_domisili, setNoRwDomisili] = useState('')
  const [no_rt_domisili, setNoRtDomisili] = useState('')
  const [alamat_domisili, setAlamatDomisili] = useState('')
  const [kd_pos_domisili, setKdPosDomisili] = useState('')
  const [propinsi, setPropinsi] = useState([])
  const [kabupaten, setKabupaten] = useState([])
  const [kecamatan, setKecamatan] = useState([])
  const [kelurahan, setKelurahan] = useState([])
  const [kabupaten_domisili, setKabupatenDomisili] = useState([])
  const [kecamatan_domisili, setKecamatanDomisili] = useState([])
  const [kelurahan_domisili, setKelurahanDomisili] = useState([])
  const [captcha, setCaptcha] = useState("")
  const [recaptcha, setReCaptcha] = useState("")
  const [type, setType] = useState('password')
  const [msg, setMsg] = useState('')
  const navigate = useNavigate()
  const alert = {
    position: "relative",
    top: "7px",
    backgroundColor: "#e5df7c",
    color: "#333",
  }

  useEffect(() => {
    getPropinsi()
    loadCaptchaEnginge(6)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPropinsi = async (filter) => {
    const response = await axios.post('/api/wilayah/propinsi', {
      nama_prop: filter
    })
    setPropinsi(response.data)
  }

  const getKabupaten = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kabupaten`, {
        no_prop: key
      })
      setKabupaten(response.data)
      setNoKab('')
      setNoProp(key)
    } catch {
      setKabupaten([])
      setNoKab("")
      setNoProp(key)
    }
  }

  const getKecamatan = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kecamatan`, {
        no_prop,
        no_kab: key
      })
      setKecamatan(response.data)
      setNoKec('')
      setNoKab(key)
    } catch {
      setKecamatan([])
      setNoKec("")
      setNoKab(key)
    }
  }

  const getKelurahan = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kelurahan`, {
        no_prop,
        no_kab,
        no_kec: key
      })
      setKelurahan(response.data)
      setNoKel('')
      setNoKec(key)
    } catch {
      setKelurahan([])
      setNoKel("")
      setNoKec(key)
    }
  }

  const getKabupatenDomisili = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kabupaten`, {
        no_prop: key
      })
      setKabupatenDomisili(response.data)
      setNoKabDomisili('')
      setNoPropDomisili(key)
    } catch {
      setKabupatenDomisili([])
      setNoKabDomisili("")
      setNoPropDomisili(key)
    }
  }

  const getKecamatanDomisili = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kecamatan`, {
        no_prop: no_prop_domisili,
        no_kab: key
      })
      setKecamatanDomisili(response.data)
      setNoKecDomisili('')
      setNoKabDomisili(key)
    } catch {
      setKecamatanDomisili([])
      setNoKecDomisili("")
      setNoKabDomisili(key)
    }
  }

  const getKelurahanDomisili = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kelurahan`, {
        no_prop: no_prop_domisili,
        no_kab: no_kab_domisili,
        no_kec: key
      })
      setKelurahanDomisili(response.data)
      setNoKelDomisili('')
      setNoKecDomisili(key)
    } catch {
      setKelurahanDomisili([])
      setNoKelDomisili("")
      setNoKecDomisili(key)
    }
  }

  const typePassword = () => {
    if (type === "password") {
      setType('text')
    } else {
      setType('password')
    }
  }

  const getData = (e) => {
    e.preventDefault()
    setProfil(0)
    setData(1)
  }

  const getProfil = async (e) => {
    e.preventDefault()
    if (nik.length !== 16) {
      getPesan()
      setMsg('NIK 16 harus digit!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (nm_lengkap === '') {
      getPesan()
      setMsg('Harap masukan nama lengkap!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (username === '') {
      getPesan()
      setMsg('Harap masukan username!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (email === '') {
      getPesan()
      setMsg('Harap masukan email!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (password.length < 8) {
      getPesan()
      setMsg('Password min 8 digit!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (no_hp.length < 10) {
      getPesan()
      setMsg('No hp min 10 digit!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (no_hp.length > 17) {
      getPesan()
      setMsg('No hp max 17 digit!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else {
      try {
        await axios.post("/api/pelaku-usaha/validasi", {
          nik,
          username,
          email,
          password,
          no_hp
        })
        setProfil(1)
        setData(0)
      } catch (err) {
        setProfil(0)
        setData(1)
        getPesan()
        setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
        setTimeout(() => {
          getPesan()
          setMsg("")
        }, 2000)
      }
    }
  }

  const getPesan = () => {
    setPesan((prevState) => !prevState)
  }

  const display = {
    display: `${pesan ? "block" : "none"}`,
  }

  const proses = async (e) => {
    e.preventDefault()
    if (validateCaptcha(captcha) === false) {
      setReCaptcha("Captcha tidak sesuai!")
      setTimeout(() => {
        setReCaptcha("")
      }, 2000)
      setCaptcha("")
    } else {
      try {
        const simpan = await axios.post(
          "/api/pelaku-usaha",
          {
            username,
            email,
            password,
            nik,
            nm_lengkap,
            no_hp,
            jenis: 1,
            i_user: username,
            i_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
            u_user: username,
            u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
          }
        )
        perorangan(simpan.data.token)
        // sendEmail(simpan.data.token, simpan.data.code)
        getPesan()
        setMsg(simpan.data.msg)
        setTimeout(() => {
          getPesan()
          setMsg("")
          navigate('/login')
        }, 1000)
      } catch (err) {
        getPesan()
        setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
        setTimeout(() => {
          getPesan()
          setMsg("")
        }, 2000)
      }
    }
  }

  const perorangan = async (token) => {
    await axios.post("/api/perorangan",
      {
        token: token,
        nik, no_npwp: npwp,
        tmpt_lahir, tgl_lahir, nm_lengkap,
        no_prop, no_kab, no_kec, no_kel, no_rw, no_rt, alamat, kd_pos,
        no_prop_domisili, no_kab_domisili, no_kec_domisili, no_kel_domisili, no_rw_domisili, no_rt_domisili, alamat_domisili, kd_pos_domisili,
        no_hp, email,
        i_user: token,
        i_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
        u_user: token,
        u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
      }
    )
  }

  // const sendEmail = async (token, code) => {
  //   await axios.post("/api/pelaku-usaha/email-register",
  //     {
  //       token: token,
  //       code: code,
  //       email
  //     }
  //   )
  // }

  return (
    <>
      <div className="bg-full-page bg-primary back-fixed">
        <div className="mw-800 absolute-center">
          <div className="card color-dark animated zoomInDown animation-delay-5 border-radius-20">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-8 col-6">
                  <h3 className="color-success">Pendaftaran Akun</h3>
                </div>
                <div className="col-md-4 col-6">
                  <img width="100%" src="assets/img/logo-head.png" alt="" />
                </div>
                <div className="col-md-12">
                  <img width="100%" className="line-process" src="assets/img/line.png" alt="" />
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className={(data === 1 | profil === 1) ? 'btn-circle btn-circle-raised btn-circle-success' : 'btn-circle btn-circle-raised btn-circle-default'}>1</button>
                  <br></br>Jenis Usaha
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className={(data === 1 | profil === 1) ? 'btn-circle btn-circle-raised btn-circle-success' : 'btn-circle btn-circle-raised btn-circle-default'}>2</button>
                  <br></br>Data Pengguna Aplikasi
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className={profil === 1 ? 'btn-circle btn-circle-raised btn-circle-success' : 'btn-circle btn-circle-raised btn-circle-default'}>3</button>
                  <br></br>Profil Pelaku Usaha
                </div>
                <div className="col-md-12 col-12 text-center">
                  <span className="badge badge-danger">Pastikan menggunakan email aktif ketika mendaftarkan akun (<b>Link Aktivasi Akun kami kirim melalui email</b>)</span><br></br>
                </div>
              </div>
              <div className={data === 1 ? 'd-block' : 'd-none'}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="nik">NIK</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                      <input type="text" id="nik" className="form-control" value={nik} onChange={(e) => setNIK(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="nm_lengkap">Nama Lengkap</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-address-card"></i></span>
                      <input type="text" id="nm_lengkap" className="form-control" value={nm_lengkap} onChange={(e) => setNmLengkap(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="username-login">Nama Pengguna (Username)</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-account"></i></span>
                      <input type="text" id="username-login" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="email-login">Email</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                      <input type="text" id="email-login" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="password-login">Kata Kunci (Password)</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-lock"></i></span>
                      <input type={type} id="password-login" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                      <span className="input-group-addon">
                        <span onClick={typePassword}><i className="control-label zmdi zmdi-eye"></i></span>
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="nm_lengkap">No. HP</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-phone"></i></span>
                      <input type="text" id="no_hp" className="form-control" value={no_hp} onChange={(e) => setNoHp(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={(profil === 1) ? 'd-block' : 'd-none'}>
                <h5 className="right-line color-success">Data Pelaku Usaha</h5>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="control-label">NIK</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                      <input type="text" className="form-control" value={nik} readOnly />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="npwp">No. NPWP</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                      <input type="text" id="npwp" className="form-control" value={npwp} onChange={(e) => setNPWP(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="control-label">Nama Lengkap</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-id-card"></i></span>
                      <input type="text" className="form-control" value={nm_lengkap} readOnly />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="tmpt_lahir">Tempat Lahir</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="tmpt_lahir" className="form-control" value={tmpt_lahir} onChange={(e) => setTmptLahir(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="tgl_lahir">Tanggal Lahir</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-calendar"></i></span>
                      <input type="date" id="tgl_lahir" className="form-control" value={tgl_lahir} onChange={(e) => setTglLahir(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Email</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                      <input type="text" className="form-control" value={email} readOnly />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">No. HP</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-phone"></i></span>
                      <input type="text" className="form-control" value={no_hp} readOnly />
                    </div>
                  </div>
                </div>
                <h5 className="right-line color-success">Alamat KTP</h5>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="control-label">Propinsi</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      {/* <Select className="form-control" defaultValue={no_prop} onChange={getKabupaten} options={propinsi} /> */}
                      <select className="form-control" value={no_prop} onChange={(e) => getKabupaten(e.target.value)} required>
                        <option value="">==</option>
                        {propinsi.map((val, i) => (
                          <option key={i} value={val.no_prop}>{i + 1 + '. ' + val.nama_prop}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kabupaten</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kab} onChange={(e) => getKecamatan(e.target.value)} required>
                        <option value="">==</option>
                        {kabupaten.map((val, i) => (
                          <option key={i} value={val.no_kab}>{i + 1 + '. ' + val.nama_kab}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kecamatan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kec} onChange={(e) => getKelurahan(e.target.value)} required>
                        <option value="">==</option>
                        {kecamatan.map((val, i) => (
                          <option key={i} value={val.no_kec}>{i + 1 + '. ' + val.nama_kec}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kelurahan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kel} onChange={(e) => setNoKel(e.target.value)} required>
                        <option value="">==</option>
                        {kelurahan.map((val, i) => (
                          <option key={i} value={val.no_kel}>{i + 1 + '. ' + val.nama_kel}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="alamat">Alamat</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="alamat" className="form-control" value={alamat} onChange={(e) => setAlamat(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="no_rw">No. Rw</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="no_rw" className="form-control" value={no_rw} onChange={(e) => setNoRw(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="no_rt">No. Rt</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="no_rt" className="form-control" value={no_rt} onChange={(e) => setNoRt(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="kd_pos">Kode Pos</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="kd_pos" className="form-control" value={kd_pos} onChange={(e) => setKdPos(e.target.value)} />
                    </div>
                  </div>
                </div>
                <h5 className="right-line color-success">Alamat Domisili</h5>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="control-label">Propinsi</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_prop_domisili} onChange={(e) => getKabupatenDomisili(e.target.value)} required>
                        <option value="">==</option>
                        {propinsi.map((val, i) => (
                          <option key={i} value={val.no_prop}>{i + 1 + '. ' + val.nama_prop}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kabupaten</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kab_domisili} onChange={(e) => getKecamatanDomisili(e.target.value)} required>
                        <option value="">==</option>
                        {kabupaten_domisili.map((val, i) => (
                          <option key={i} value={val.no_kab}>{i + 1 + '. ' + val.nama_kab}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kecamatan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kec_domisili} onChange={(e) => getKelurahanDomisili(e.target.value)} required>
                        <option value="">==</option>
                        {kecamatan_domisili.map((val, i) => (
                          <option key={i} value={val.no_kec}>{i + 1 + '. ' + val.nama_kec}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kelurahan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kel_domisili} onChange={(e) => setNoKelDomisili(e.target.value)} required>
                        <option value="">==</option>
                        {kelurahan_domisili.map((val, i) => (
                          <option key={i} value={val.no_kel}>{i + 1 + '. ' + val.nama_kel}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="alamat_domisili">Alamat</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="alamat_domisili" className="form-control" value={alamat_domisili} onChange={(e) => setAlamatDomisili(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="no_rw_domisili">No. Rw</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="no_rw_domisili" className="form-control" value={no_rw_domisili} onChange={(e) => setNoRwDomisili(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="no_rt_domisili">No. Rt</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="no_rt_domisili" className="form-control" value={no_rt_domisili} onChange={(e) => setNoRtDomisili(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="kd_pos_domisili">Kode Pos</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="kd_pos_domisili" className="form-control" value={kd_pos_domisili} onChange={(e) => setKdPosDomisili(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={(profil === 1) ? 'd-block' : 'd-none'}>
                <h5 className="right-line color-success">Captcha</h5>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="text-center">
                      <Captcha />
                    </div>
                    <label className="control-label">Captcha</label>
                    <p style={alert} className="text-center">
                      {recaptcha}
                    </p>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-lock"></i></span>
                      <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className={profil === 0 ? 'col-md-6 col-6 d-block' : 'col-md-6 col-6 d-none'}>
                  <Link to={'/register'} className="btn btn-raised btn-default btn-block p-1 br-2"><i className="zmdi zmdi-long-arrow-left no-ml mr-1"></i>KEMBALI</Link>
                </div>
                <div className={profil === 0 ? 'col-md-6 col-6 d-block' : 'col-md-6 col-6 d-none'}>
                  <button onClick={getProfil} className="btn btn-raised btn-primary btn-block p-1 br-2">SELANJUTNYA<i className="zmdi zmdi-long-arrow-right no-mr ml-1"></i></button>
                </div>
                <div className={profil === 1 ? 'col-md-6 col-6 d-block' : 'col-md-6 col-6 d-none'}>
                  <button onClick={getData} className="btn btn-raised btn-default btn-block p-1 br-2"><i className="zmdi zmdi-long-arrow-left no-ml mr-1"></i>KEMBALI</button>
                </div>
                <div className={(profil === 1) ? 'col-md-6 col-6 d-block' : 'col-md-6 col-6 d-none'}>
                  <button onClick={proses} className="btn btn-raised btn-primary btn-block p-1 br-2">DAFTAR<i className="fa fa-vcard no-mr ml-1"></i></button>
                </div>
                <div className="col-md-12 text-center mt-2">
                  Sudah punya akun? <Link className="color-success" to={'/login'}>Masuk</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={pesan ? "modal-open" : ""}>
        <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog center">
            <div className="modal-content modal-sm">
              <div className="modal-header header-modal-primary p-1">
                <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                  PESAN
                </h4>
              </div>
              <div className="modal-body text-center">
                <h4 className="font-bold">{msg.toUpperCase()}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pesan ? <div className="modal-backdrop fade in"></div> : ""}
    </>
  )
}

export default Register