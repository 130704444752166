import React, { useState, useEffect } from 'react'
import axios from 'axios'

const View = () => {
    const [profil, setProfil] = useState([])

    useEffect(() => {
        getProfil()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProfil = async () => {
        const response = await axios.get('/api/profil/publish')
        setProfil(response.data)
    }

    return (<>
        <div className="row mt-4">
            <div className="col-md-1"></div>
            <div className="col-md-10">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className='color-dark font-bold lh-125 no-m flex-card row'>Kontak & Lokasi</h1>
                    </div>
                    <div className="col-md-7">
                        <div className="animated zoomInUp animation-delay-3">
                            <div className="mb-4">
                                <h3 className='font-bold'>
                                    Alamat Kantor
                                </h3>
                                <hr></hr>
                                <h4 className='font-bold'>Alamat</h4>
                                {profil.alamat}, {profil.kota}, {profil.kd_pos}
                                <hr></hr>
                                <h4 className='font-bold'>Telepon</h4>
                                <ul>
                                    <li>
                                        {profil.telp}
                                    </li>
                                </ul>
                                <hr></hr>
                                <h4 className='font-bold'>Email</h4>
                                <a className="text-success font-bold" href={`mailto: ${profil.email}`}>{profil.email}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="animated zoomInUp animation-delay-3">
                            <div className="mb-4">
                                <h3 className='font-bold'>
                                    Kirim Pesan
                                </h3>
                                <a href={`mailto: ${profil.email}`} className="btn btn-raised btn-primary br-2 mb-2">Pertanyaan Umum</a><br></br>
                                <a href={`https://wa.me/${profil.whatsapp}`} target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-success br-2 mb-2">Admin Whatsapp</a><br></br>
                                <a href={profil.peta} target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-default br-2 mb-2">Lokasi Pada Map</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default View;