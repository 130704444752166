import React, { useState, useEffect } from 'react'
import axios from "axios"
import parse from 'html-react-parser'
import { useParams, Link } from "react-router-dom"
import Nav from './Nav'

const View = () => {
    const { kategori, code } = useParams()
    const [list, setList] = useState([])
    const [name, setName] = useState('')
    const [kecamatan, setKecamatan] = useState('')
    const [kelurahan, setKelurahan] = useState('')
    const [address, setAddress] = useState('')
    const [categoryPath, setCategoryPath] = useState('')
    const [direction, setDirection] = useState('')
    const [procurement, setProcurement] = useState([])
    const [url, setUrl] = useState('')

    useEffect(() => {
        getData(code)
        getPengadaan()
        getList()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async (value) => {
        const category = kategori.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase()
        const response = await axios.post("/api/kegiatan-usaha/filter", {
            kategori: category,
            code: value,
            jml: 1
        })
        setName(response.data[0].name)
        setKecamatan(response.data[0].kecamatan)
        setKelurahan(response.data[0].kelurahan)
        setAddress(response.data[0].address)
        setCategoryPath(response.data[0].categoryPath)
        setUrl(response.data[0].imagePath)
        setDirection(response.data[0].direction)
    }

    const getPengadaan = async () => {
        const response = await axios.post("/api/procurement/publish", {
            potensialCode: code,
            jml: 10
        })
        setProcurement(response.data)
    }

    const getList = async () => {
        const category = kategori.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase()
        const response = await axios.post("/api/kegiatan-usaha/filter", {
            kategori: category,
            jml: 5
        })
        setList(response.data)
    }

    return (<>
        <header className="ms-hero-page-override-home no-mt pb-4">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-slash">
                        <li className="breadcrumb-item">
                            <Link to={'/'} className="btn btn-raised btn-primary br-2 no-m">BERANDA</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">KEGIATAN USAHA</li>
                        <li className="breadcrumb-item active">
                            {kategori.replaceAll('-', ' ').replaceAll('_', '?').toUpperCase()}
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{name.replaceAll('-', ' ').toUpperCase()}</li>
                    </ol>
                    <div className="btn-group w-300">
                        <button type="button" className="btn btn-warning btn-raised dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            KEGIATAN USAHA <i className="zmdi zmdi-chevron-down right only"></i>
                        </button>
                        <Nav />
                    </div>
                </nav>
                <div className="row mt-4">
                    <div className="col-md-3">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className='panel-title'>{kategori.replaceAll('-', ' ').replaceAll('_', '?').toUpperCase()}</h3>
                            </div>
                            <div className="card-body">
                                <div className="ms-media-list">
                                    {list.map((val, index) => (
                                        <div onClick={() => getData(val.potensialCode)} key={index} className="media mb-2">
                                            <div className="media-left media-middle">
                                                <img className='d-flex mr-3 media-object' width="100%" src={val.imagePath !== '-' ? val.imagePath : val.categoryPath} title="Preview File" alt="Preview File" />
                                            </div>
                                            <div className="media-body">
                                                <span className="media-heading color-dark font-bold">{val.name}</span>
                                                <div className="media-footer text-small color-dark">
                                                    <span className="mr-1"><i className="fa fa-map color-dark mr-05 small"></i>
                                                        {val.kelurahan}, {val.kecamatan}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h1 className='color-dark font-bold lh-125'>{name}</h1>
                        <img className='mb-2' width="100%" src={url !== '-' ? url : categoryPath} title="Preview File" alt="Preview File" />
                        {parse(address)}, {kelurahan}, {kecamatan}
                        <br></br>
                        <br></br>
                        <a href={direction} target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-warning br-2">Menuju Lokasi <i className="ml-1 no-mr zmdi zmdi-long-arrow-right"></i></a>
                    </div>
                    <div className="col-md-3">
                        <div className="card card-success">
                            <div className="card-header">
                                <h3 className='panel-title'>KEBUTUHAN PENGADAAN</h3>
                            </div>
                            <div className="card-body">
                                <div className="ms-media-list">
                                    {procurement.length === 0 ? (<h3 className='no-m font-bold'>Belum Tersedia</h3>) : (<>
                                        {procurement.map((val, index) => (
                                            <Link key={index} className="media mb-2" to={`/kebutuhan-pengadaan/${val.code}/${val.title.replaceAll(' ', '-').replaceAll('/', '_').toLowerCase()}`}>
                                                <div className="media-body">
                                                    <span className="media-heading color-dark font-bold">{val.title}</span>
                                                    <div className="media-footer text-small color-dark">
                                                        {parse(val.description)}
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default View;