import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import axios from "axios"

const Nav = ({getTitle}) => {
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        const response = await axios.post("/api/publikasi/prosedur-publish", {
            kategori: 'PENGADUAN',
            jml: 100
        })
        setData(response.data)
    }

    return (<>
        <ul className="dropdown-menu bg-primary w-300 pb-15">
            <li className="dropdown-header">
                <h4 className='color-warning'>Prosedur Pelayanan</h4>
            </li>
            {data.map((val, index) => 
                <React.Fragment key={index}>
                    <li key={val.title} role="separator" className="dropdown-divider"></li>
                    <li key={index}><Link to={''} className="dropdown-item" onClick={() => getTitle(val.title)}>{val.judul}</Link></li>
                </React.Fragment>
            )}
        </ul>
    </>)
}

export default Nav;