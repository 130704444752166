import React, { Component } from 'react'
import { loadCaptchaEnginge, LoadCanvasTemplate } from 'react-simple-captcha'



class Captcha extends Component {

    componentDidMount() {
        loadCaptchaEnginge(6)
    }

    render() {
        return (<div><LoadCanvasTemplate /></div>)
    }
}

export default Captcha